import RackBox from '../../../../components/factoryui/components/RackBox';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import type { NDSDeviceFromAction } from '../../../../types/types';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  data: NDSDeviceFromAction[];
  shelves: number;
  selectedTray?: string;
  selectedSeason?: string;
};

const OverlaySprinklerValves = ({ shelves, data }: OverlayTraySimpleProps) => {
  return (
    <>
      {data.map((row) => {
        const { valve, location_row, location_shelf } = row.properties;

        return (
          <RackBox
            style={{ textAlign: 'left' }}
            key={row.deviceId}
            zIndex={11}
            row={location_row}
            shelf={location_shelf}
            shelves={shelves}
          >
            {valve && (
              <WaterDropIcon
                sx={{ fontSize: '11px', margin: '1px', color: '#4545a5' }}
              />
            )}
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlaySprinklerValves;
