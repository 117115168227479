import { useContext } from 'react';
import usePoller from '../../../data/hooks/usePoller';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import type { CloudActionGetNDSByLocation } from '@phyllome/common';

const useNDSByLocation = ({
  column,
  row,
  shelf,
}: {
  column: number;
  row: number;
  shelf: number;
}) => {
  const { brokerId } = useContext(FarmContext);

  const NDSDeviceState = usePoller<CloudActionGetNDSByLocation>(
    {
      action: 'getNDSByLocation',
      payload: {
        brokerId,
        column,
        row,
        shelf,
      },
    },
    10,
  );

  return NDSDeviceState;
};

export default useNDSByLocation;
