import { Stack } from '@mui/system';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';

import useControlSend from '../../../data/hooks/useControlSend';
import {
  FertigatorDeviceState,
  mqttDefinitionFertigatorSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { InputAdornment } from '@mui/material';

const FormFertigatorSetPeroxide = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionFertigatorSet, {
    peroxide: {
      schedule: {
        volume: latest?.peroxide_schedule_volume || 100,
        period: latest?.peroxide_schedule_period || 60,
        start: {
          hour: latest?.peroxide_schedule_start_hour,
          minute: latest?.peroxide_schedule_start_minute,
        },
      },
      enable: latest?.peroxide_enable || false,
    },
  });

  return (
    <PaperSection
      heading="Peroxide Dosing Schedule Control"
      mb={2}
      subheading={mqttDefinitionFertigatorSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              peroxide: {
                schedule: {
                  volume: data?.peroxide.schedule.volume,
                  period: data?.peroxide.schedule.period,
                  start: {
                    hour: data?.peroxide.schedule.start.hour,
                    minute: data?.peroxide.schedule.start.minute,
                  } },
                enable: data.peroxide.enable,

              },
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set Peroxide dosing to ${data?.peroxide.schedule.volume}mL every ${data?.peroxide.schedule.period}min and start time to ${data?.peroxide.schedule.start.hour}:${data?.peroxide.schedule.start.minute}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          peroxide: yup
            .object({
              enable: yup.boolean().required(),
              schedule: yup.object({
                volume: yup.number().min(10).max(10000).required(),
                period: yup.number().min(2).required(),
                start: yup.object({
                  hour: yup.number().min(0).max(23).required(),
                  minute: yup.number().min(0).max(59).required(),
                })  }) })
            .required(),
        })}
      >
        <Stack spacing={2}>
          <CheckboxElement
            label="Peroxide Dosing Enabled"
            name="peroxide.enable"
          />
          <TextFieldElement
            label="Periodic Dosing Interval"
            name="peroxide.schedule.period"
            InputProps={{ endAdornment: <InputAdornment position="end">min</InputAdornment> }}
          />
          <TextFieldElement
            label="Volume of Peroxide per Dose"
            name="peroxide.schedule.volume"
            InputProps={{ endAdornment: <InputAdornment position="end">mL</InputAdornment> }}
          />
          <Stack direction="row" spacing={2}>
            <TextFieldElement label="Start Hour" name="peroxide.schedule.start.hour" />
            <TextFieldElement
              label="Start Minute"
              name="peroxide.schedule.start.minute"
            />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorSetPeroxide;
