import type {
  DatabaseSeasonsDevices,
  NDSDeviceState,
  ZoneDeviceState,
} from '@phyllome/common';
import usePoller, { type StatusPoller } from '../usePoller';

// type NDSDevice = DatabaseDevices<NDSDeviceState>;
// type ZoneDevice = DatabaseDevices<ZoneDeviceState>;

type ThisType = Pick<
  DatabaseSeasonsDevices,
  'seasonId' | 'desiredState' | 'brokerId'
> & {
  deviceId: string;
  alias: string[];
};

type NDSDeviceType = ThisType & {
  deviceType: 'nds';
  desiredProperties: Partial<NDSDeviceState>;
  properties: NDSDeviceState;
};

type ZoneDeviceType = ThisType & {
  deviceType: 'zone';
  desiredProperties: Partial<ZoneDeviceState>;
  properties: ZoneDeviceState;
};
export type DeviceFromSeason = NDSDeviceType | ZoneDeviceType;

type UsePollerReturn2 = {
  status: StatusPoller;
  msg: DeviceFromSeason[];
};
export const useDevicesInSeason = (seasonId: string) => {
  const data = usePoller<any>(
    {
      action: 'getDevicesBySeason',
      payload: {
        seasonId,
      },
    },
    10,
  );

  return {
    status: data.status,
    msg: data.msg,
  } as UsePollerReturn2;
};
