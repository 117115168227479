import { Stack } from '@mui/system';
import { SelectElement, TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import {
  FertigatorDeviceState,
  mqttDefinitionFertigatorManual,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { FromFirebase } from '../../../types/types';

const FormFertigatorManual = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorManual,
    deviceId,
  );

  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = {
    select: 'nutrientA',
    volume: 0,
  };

  return (
    <PaperSection
      heading="Manual Dosing Control"
      subheading={mqttDefinitionFertigatorManual.topic}
      mb={2}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              [data.select]: data.volume,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Manually dosed ${data.select} to ${data.volume} mL`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          volume: yup.number().min(20).max(1000).required(),
        })}
      >
        <Stack spacing={2}>
          <Typography>
            Specify the fertigator pump and enter in the volume to dose
            manually. Note it is best to disable auto-dosing before manually
            dosing. (between 20mL and 1000mL)
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={4} spacing={2} mr={2}>
              <SelectElement
                fullWidth
                label="Select"
                name="select"
                options={[
                  {
                    id: 'nutrientA',
                    label: 'Nutrient A',
                  },
                  {
                    id: 'nutrientB',
                    label: 'Nutrient B',
                  },
                  {
                    id: 'peroxide',
                    label: 'Peroxide',
                  },
                  {
                    id: 'acid',
                    label: 'Acid',
                  },
                  {
                    id: 'base',
                    label: 'Base',
                  },
                  {
                    id: 'pump',
                    label: 'Pump',
                  },
                ]}
              />
            </Grid>
            <Grid xs={4}>
              <TextFieldElement
                label="Volume" name="volume" InputProps={{
                  endAdornment: <InputAdornment position="end">
                    mL
                  </InputAdornment> }}
              />
            </Grid>
          </Grid>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorManual;
