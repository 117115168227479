import { LightingDeviceState } from '@phyllome/common';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import ChipEnum from '../../components/puregui/ChipEnum/ChipEnum';
import AutoModeSharpIcon from '@mui/icons-material/AutoModeSharp';
import ModeStandbySharpIcon from '@mui/icons-material/ModeStandbySharp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Tooltip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import { ChipEnumData } from '../../components/puregui/ChipEnum/types';
import useNavSide from '../../data/hooks/useNavSide';

const LatestLightingInfo = ({
  latest,
}: {
  latest: FromFirebase<LightingDeviceState>;
}) => {
  const nav = useNavSide();

  const modeStates: ChipEnumData = [
    {
      label: 'Off',
      value: 0,
      color: 'earth3',
      icon: <ModeStandbySharpIcon />,
    },
    { label: 'Auto', value: 1, color: 'primary', icon: <AutoModeSharpIcon /> },
    {
      label: 'Manual',
      value: 2,
      color: 'sunlight',
      icon: <PlayCircleOutlineIcon />,
    },
  ];

  const totalCurrent = latest.current_A + latest.current_B + latest.current_C;
  const heroItems = [
    {
      label: 'Total Current',
      text: (Math.round(totalCurrent * 10) / 10).toString(),
      sup: 'A',
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Lighting Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box mb={2}>
        <ChipEnum
          data={modeStates}
          value={Number(latest.mode)}
          tooltip="State of panel switch and operating mode"
        />
      </Box>
      <Box mb={2}>
        {latest.zones.map((zone, key) => (
          <Tooltip key={key} arrow title={`Zone: ${key + latest.firstZoneID}`}>
            <CircleIcon
              color={zone === true ? 'primary' : 'fog'}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                nav(`zone/${key + latest.firstZoneID}`);
              }}
            />
          </Tooltip>
        ))}
      </Box>
    </PaperSection>
  );
};

export default LatestLightingInfo;
