import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useNavSide from '../../../../data/hooks/useNavSide';
import OverlayTraySimple from '../Overlays/OverlayTraySimple';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useTraySeasonsCurrentByColID from '../../../../data/hooks/collections/useTraySeasonsCurrentByColID';
import { TraysContext } from '../TraysContext';

const OverlayEntryPointTraysCurrent = ({ column }: { column: number }) => {
  const { columnSpec, brokerId } = useContext(FarmContext);
  const traysContext = useContext(TraysContext);
  const trayData = useTraySeasonsCurrentByColID({
    brokerId,
    columnId: column,
    seasonId: traysContext.state.displayedSeason,
  });

  const navSide = useNavSide();
  const location = useLocation();
  const hash = location.hash.substring(1);
  const numberOfShelves = columnSpec[column - 1].shelves;

  useEffect(() => {
    if (hash.length === 0 && traysContext.state.selectedSeason) {
      traysContext.dispatch({ type: 'SET_SELECTED_TRAY', payload: undefined });
      traysContext.dispatch({
        type: 'SET_SELECTED_SEASON',
        payload: undefined,
      });
    }
  }, [hash, traysContext]);
  return (
    <>
      <OverlayTraySimple
        data={trayData}
        shelves={numberOfShelves}
        selectedSeason={
          traysContext.state.displayedSeason ||
          traysContext.state.selectedSeason
        }
        selectedTray={traysContext.state.selectedTray}
        onClick={(trayRowData) => {
          traysContext.dispatch({
            type: 'SET_SELECTED_TRAY',
            payload: trayRowData.id,
          });
          traysContext.dispatch({
            type: 'SET_SELECTED_SEASON',
            payload: trayRowData.seasonId,
          });

          navSide(`tray/${trayRowData.id}`);
        }}
      />
    </>
  );
};

export default OverlayEntryPointTraysCurrent;
