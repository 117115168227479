import { useEffect, useState } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
} from 'firebase/firestore';
import { DatabaseAlert } from '@phyllome/common';
import { FromFirebase } from '../../../../../types/types';

const useAlerts = ({
  brokerId,
  deviceType,
  deviceId,
  count = 100,
}: {
  brokerId: string;
  deviceType?: string;
  deviceId?: string;
  count?: number;
}): FromFirebase<DatabaseAlert>[] => {
  const firestore = getFirestore();
  const [data, setData] = useState<FromFirebase<DatabaseAlert>[]>([]);

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const deviceTypeQuery = deviceType
          ? [where('deviceType', '==', deviceType)]
          : [];
        const deviceIdQuery = deviceId
          ? [where('deviceId', '==', deviceId)]
          : [];

        const documentReference = query(
          collection(firestore, 'alert'),
          where('brokerId', '==', brokerId),
          ...deviceTypeQuery,
          ...deviceIdQuery,
          orderBy('timestamp', 'desc'),
          limit(count),
        );

        const querySnapshot = await getDocs(documentReference);
        const alerts: FromFirebase<DatabaseAlert>[] = querySnapshot.docs.map(
          (doc) => ({
            id: doc.id,
            ...doc.data(),
          }),
        ) as FromFirebase<DatabaseAlert>[];

        setData(alerts);
      } catch (error) {
        console.error('Error fetching alerts:', error);
        setData([]); // Set to empty array in case of an error
      }
    };

    fetchAlerts();
  }, [firestore, brokerId, deviceType, deviceId, count]);
  return data; // Return the fetched alerts or an empty array
};

export default useAlerts;
