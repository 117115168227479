import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';

import * as yup from 'yup';

import {
  FertigatorDeviceState,
  mqttDefinitionFertigatorConfigSet,
} from '@phyllome/common';

import PhormContainer from '../../../forms/PhormContainer';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { InputAdornment, Typography } from '@mui/material';
import { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormFertigatorConfigSetFlowrates = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionFertigatorConfigSet,
    {
      flowrates: {
        eca: latest.flowrates_eca || 0,
        ecb: latest.flowrates_ecb || 0,
        acid: latest.flowrates_acid || 0,
        base: latest.flowrates_base || 0,
        peroxide: latest.flowrates_peroxide || 0,
        pump: latest.flowrates_pump || 0,
      },
    },
  );

  return (
    <PaperSection
      heading="Flowrate configuration"
      mb={2}
      subheading={mqttDefinitionFertigatorConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              flowrates: {
                eca: data.flowrates.eca,
                ecb: data.flowrates.ecb,
                acid: data.flowrates.acid,
                base: data.flowrates.base,
                peroxide: data.flowrates.peroxide,
                pump: data.flowrates.pump,
              },
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add('Updated device flowrate calibration', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          flowrates: yup.object({
            eca: yup.number().min(0).required(),
            ecb: yup.number().min(0).required(),
            acid: yup.number().min(0).required(),
            base: yup.number().min(0).required(),
            peroxide: yup.number().min(0).required(),
            pump: yup.number().min(0).required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant="body1">
            Flowrates are the recorded values in mL per minute
          </Typography>
          <Stack spacing={2} direction="row">
            <TextFieldElement
              label="Flowrates EC A" name="flowrates.eca"
              InputProps={{ endAdornment: <InputAdornment position="end">mL/min</InputAdornment> }}
            />
            <TextFieldElement
              label="Flowrates EC B" name="flowrates.ecb"
              InputProps={{ endAdornment: <InputAdornment position="end">mL/min</InputAdornment> }}
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextFieldElement
              label="Flowrates Acid" name="flowrates.acid"
              InputProps={{ endAdornment: <InputAdornment position="end">mL/min</InputAdornment> }}
            />
            <TextFieldElement
              label="Flowrates Base" name="flowrates.base"
              InputProps={{ endAdornment: <InputAdornment position="end">mL/min</InputAdornment> }}
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextFieldElement
              label="Flowrates Peroxide"
              name="flowrates.peroxide"
              InputProps={{ endAdornment: <InputAdornment position="end">mL/min</InputAdornment> }}
            />
            <TextFieldElement
              label="Flowrates Pump"
              name="flowrates.pump"
              InputProps={{ endAdornment: <InputAdornment position="end">mL/min</InputAdornment> }}
            />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorConfigSetFlowrates;
