import GenericPing from '../Generic/control/GenericPing';
import { FromFirebase } from '../../types/types';
import FormIrrigationSetFillValve from './forms/FormIrrigationSetFillValve';
import LatestIrrigationInfo from './LatestIrrigationInfo';
import { IrrigationDeviceState } from '@phyllome/common';
import FormIrrigationSetModesDischarge from './forms/FormIrrigationSetModesDischarge';
import FormIrrigationSetModesTransfer from './forms/FormIrrigationSetModesTransfer';

const ControlIrrigation = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  return (
    <>
      <LatestIrrigationInfo latest={latest} />
      <div>
        <GenericPing
          deviceId={latest.deviceId}
          brokerId={latest.brokerId}
          deviceType="irrigation"
        />
      </div>
      {latest.brokerId !== 'mcp-nursery' && (
        <>
          <FormIrrigationSetFillValve latest={latest} />
          <FormIrrigationSetModesDischarge latest={latest} />
          <FormIrrigationSetModesTransfer latest={latest} />
        </>
      )}
    </>
  );
};

export default ControlIrrigation;
