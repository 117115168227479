import type { FC } from 'react';
import type { AnyObject, DeviceType } from '@phyllome/common';
import getFieldsByDeviceType from './getFieldsByDeviceType';
import Phorm from '../../../components/Form/Components/Phorm';
import PhormBlock from '../../../components/Form/Components/PhormBlock';
import PhormTextField from '../../../components/Form/Components/PhormTextField';

type FormDeviceStateProps = {
  deviceType: DeviceType;
  defaultValues: AnyObject;
};

const FormDeviceState: FC<FormDeviceStateProps> = ({
  deviceType,
  defaultValues,
}) => {
  const deviceFields = getFieldsByDeviceType(deviceType);

  return (
    <Phorm
      defaultValues={defaultValues}
      onChange={(val) => {
        console.log('CHANGE', val);
      }}
    >
      {deviceFields.map((field) => {
        return (
          <PhormBlock key={field.name}>
            <PhormTextField label={field.name} name={field.name} />
          </PhormBlock>
        );
      })}
    </Phorm>
  );
};

export default FormDeviceState;
