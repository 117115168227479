import { FC } from 'react';
import PaperSection from '../puregui/PaperSection/PaperSection';
import useActivityLog from '../../data/hooks/useActivityLog';
import { Box, Divider } from '@mui/material';
import ActivityLogChild from './ActivityLogChild';
import ActivityLogForm from './ActivityLogForm';

// const actvityLogColorMap: {
//   [key in DatabaseActivityLog["origin"]["type"]]: string;
// } = {
//   USER: colors.budding,
//   SYSTEM: colors.vegetative,
//   OPERATION: colors.fog,
//   PHOTO: colors.sunlight,
// };

type ActivityLogProps = {
  identifier: string;
  heading?: string | false;
  limit?: number;
};

const ActivityLog: FC<ActivityLogProps> = ({
  identifier,
  heading = false,
  limit,
}) => {
  const activityLog = useActivityLog(identifier, limit);

  return (
    <PaperSection heading={heading || `Activity Log for ${identifier}`} mb={2}>
      <Box>
        {activityLog.data?.map((log) => (
          <ActivityLogChild key={log.id} log={log} />
        ))}
      </Box>
      {activityLog.data?.length > 0 && (
        <>
          <Box mb={2}>
            <Divider />
          </Box>
        </>
      )}
      <Box>
        <ActivityLogForm activityLog={activityLog} />
      </Box>
    </PaperSection>
  );
};

export default ActivityLog;
