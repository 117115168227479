import { SentryDeviceState } from '@phyllome/common';
import { FC } from 'react';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';

type IProps = {
  latest: FromFirebase<SentryDeviceState>;
};

const LatestSentryInfo: FC<IProps> = ({ latest }) => {
  const heroItems = [
    {
      label: 'Last scanned tray',
      text: (latest?.tray || '-').toString() || 'N/A',
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Sentry Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
    </PaperSection>
  );
};

export default LatestSentryInfo;
