import { MQTTDefinitionAny } from '@phyllome/common';

type PartialRequired<T> = {
  [K in keyof T]-?: T[K] extends object ? PartialRequired<T[K]> : T[K];
};

type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

// New type to ensure only keys from T are allowed
type StrictObject<T, U> = {
  [K in keyof U]: K extends keyof T ? U[K] : never;
};

function formCreateDefaultValues<
  T extends MQTTDefinitionAny,
  U extends DeepPartial<Omit<T['payload'], 'timestamp'>>,
>(
  mqttDef: T,
  vals: StrictObject<T['payload'], PartialRequired<U>>,
): StrictObject<T['payload'], PartialRequired<U>> {
  return vals;
}
export default formCreateDefaultValues;
