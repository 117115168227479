function opacitySimulateColour(rgbColor: string, fauxOpacity: number): string {
  // Remove the '#' from the RGB color string
  const hex = rgbColor.replace('#', '');

  // Convert the hex color to separate red, green, and blue components
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Ensure fauxOpacity is within the valid range of 0 to 1
  fauxOpacity = Math.max(0, Math.min(1, fauxOpacity));

  // Calculate the new RGB values with fauxOpacity applied on a white background
  const newR = Math.round((255 - r) * fauxOpacity + r);
  const newG = Math.round((255 - g) * fauxOpacity + g);
  const newB = Math.round((255 - b) * fauxOpacity + b);

  // Convert the new RGB values back to a hex string
  const newHex = ((newR << 16) + (newG << 8) + newB)
    .toString(16)
    .padStart(6, '0');

  // Return the new color string with the fauxOpacity applied
  return `#${newHex}`;
}
export default opacitySimulateColour;
