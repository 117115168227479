import { type FC, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import type { DateTimePickerProps } from '@mui/lab';

type IProps = {
  busy?: boolean;
  name: string;

  width?: 'xs' | 's' | 'm' | 'l' | 'full';
  label: string;
  defaultValue?: Date;
} & DateTimePickerProps<any>;

const PhormDateTime: FC<IProps> = ({ name, defaultValue, ...props }) => {
  const { setValue, control } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              {...props}
              onChange={(e) => {
                const newDate = new Date(
                  e?.toISOString() || new Date().toISOString(),
                );

                onChange(newDate);
              }}
              label={props.label || ''}
              value={dayjs(value)}
              format="DD-MM-YYYY hh:mm A"
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default PhormDateTime;
