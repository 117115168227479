import RadarIcon from '@mui/icons-material/Radar';
import { Box } from '@mui/material';
import { DeviceType, pubsubDefinitionPing } from '@phyllome/common';
import { FC } from 'react';
import ButtonWait from '../../../components/puregui/ButtonWait/ButtonWait';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../../components/ReactJson/ReactJson';

import useControlSend from '../../../data/hooks/useControlSend';
import ControlConnect from '../../../components/ControlConnect/ControlConnect';

type IProps = {
  brokerId: string;
  deviceId: string;
  deviceType: DeviceType;
};

const GenericPing: FC<IProps> = ({ brokerId, deviceId, deviceType }) => {
  const pingLifecycle = useControlSend(
    pubsubDefinitionPing,
    `${brokerId}${deviceType}${deviceId}`,
  );

  return (
    <div>
      <ButtonWait
        isBusy={!pingLifecycle.isReady}
        onClick={() => {
          pingLifecycle.run({
            payload: {
              deviceId,
              deviceType,
              timestamp: new Date().toISOString(),
            },
          });
        }}
        startIcon={<RadarIcon />}
      >
        Ping Device&nbsp;<small>({pingLifecycle.rid})</small>
      </ButtonWait>
      <Box>
        <ControlConnect data={pingLifecycle} />
        {pingLifecycle.status === 'done' && (
          <PaperSection
            mt={2}
            mb={2}
            heading={`Ping received for ${deviceId}`}
            subheading={`${pingLifecycle?.runtime}ms`}
          >
            <ReactJson src={pingLifecycle?.payload?.response} />
          </PaperSection>
        )}
      </Box>
    </div>
  );
};

export default GenericPing;
