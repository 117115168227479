import { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';
import { DatabaseSeason } from '@phyllome/common';
import { FromFirebase } from '../../../types/types';
export type SeasonRow = FromFirebase<DatabaseSeason>;

const useSeasons = ({
  brokerId,
  isComplete = false,
  sort = 'desc',
}: {
  brokerId: string;
  isComplete?: boolean;
  sort?: 'asc' | 'desc';
}): FromFirebase<DatabaseSeason>[] => {
  const [seasons, setSeasons] = useState<FromFirebase<DatabaseSeason>[]>([]);

  useEffect(() => {
    const db = getFirestore();
    const seasonQuery = query(
      collection(db, 'season'),
      where('brokerId', '==', brokerId || ''),
      where('state', isComplete ? '==' : '!=', 'completed'),
      orderBy('created', sort),
    );

    const unsubscribe = onSnapshot(seasonQuery, (snapshot) => {
      const seasonData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as FromFirebase<DatabaseSeason>[];

      setSeasons(seasonData);
    });

    return () => {
      unsubscribe();
    };
  }, [brokerId, isComplete, sort]);
  return seasons;
};

export default useSeasons;
