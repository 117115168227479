import type { FC } from 'react';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { PhyconMUI } from '../../../components/puregui/Phycon/Phycon';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import useDeviceById from '../../../data/hooks/collections/useDeviceById';
import FormDeviceState from './FormDeviceState';
type DesiredToMQTTProps = unknown;

const DesiredToMQTT: FC<DesiredToMQTTProps> = () => {
  const stuff = useDeviceById({
    deviceType: 'nds',
    deviceId: '34B472D46624',
  });

  if (!stuff) return <div>no stuff</div>;
  return (
    <ContainerPrimary
      title="Desired State Tester"
      icon={<PhyconMUI icon={MoveDownIcon} />}
    >
      <FormDeviceState deviceType="nds" defaultValues={stuff} />
    </ContainerPrimary>
  );
};

export default DesiredToMQTT;
