import { max, min } from 'lodash';
import { FC, useMemo } from 'react';
import {
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import chartTheme from './chartTheme';
import FlyOut from './FlyOut';
import GraphCursor from './GraphCursor';
import { TimeSeriesData } from './useTimeSeriesPoller';
import { GraphConstraints } from '../Favourites/types';
export type IVictoryChatProps = {
  color: string;
  timeSeries: TimeSeriesData;
  height?: number;
  constraints?: GraphConstraints;
};

const ReChartVictoryLine: FC<IVictoryChatProps> = ({
  color,
  timeSeries,
  height,
  constraints,
}) => {
  const minValue = useMemo(() => {
    const vals =
      constraints?.min !== undefined
        ? [constraints.min]
        : timeSeries.data.map((t) => t.y).filter((v) => v !== null);

    return Math.floor(min(vals) || 0);
  }, [timeSeries, constraints]);

  const maxValue = useMemo(() => {
    const vals =
      constraints?.max !== undefined
        ? [constraints.max]
        : timeSeries.data.map((t) => t.y).filter((v) => v !== null);

    return Math.ceil(max(vals) || 0);
  }, [timeSeries, constraints]);

  return (
    <VictoryChart
      height={height || 170}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={() => ' '}
          labelComponent={
            <VictoryTooltip
              pointerLength={0}
              flyoutComponent={<FlyOut color={color} />}
            />
          }
        />
      }
      theme={chartTheme}
      scale={{ x: 'time', y: 'linear' }}
      minDomain={{ y: minValue || 0 }}
      maxDomain={{ y: maxValue || 1 }}
    >
      <VictoryLine
        style={{
          data: {
            stroke: color,
            strokeWidth: 1.3,
            strokeLinecap: 'round',
            strokeOpacity: 1,
          },
        }}
        data={timeSeries.data}
      />
      <GraphCursor />
    </VictoryChart>
  );
};

export default ReChartVictoryLine;
