import airGraph from './airGraph';
import co2Graph from './co2Graph';
import fertGraph from './fertGraph';
import irrigGraph from './irrigGraph';
import lightingGraph from './lightingGraph';
import { FavouriteGraphDefition, FunctionParams } from './types';
import zoneGraph from './zoneGraph';
import traybotGraph from './traybotGraph';
type GraphDef = (arg: FunctionParams) => FavouriteGraphDefition;
export type CollectionFavourite = {
  [key: string]: GraphDef;
};
export const collectionFavourite = {
  ...airGraph,
  ...co2Graph,
  ...fertGraph,
  ...irrigGraph,
  ...lightingGraph,
  ...zoneGraph,
  ...traybotGraph,
};
export const getFavouriteGraph = (name: FavouriteName) => {
  return collectionFavourite[name];
};
export default collectionFavourite;
export type FavouriteName = keyof typeof collectionFavourite;
