import React, { useContext, useEffect, useReducer } from 'react';

/* *** STATE DECLARATION */

interface IAppState {
  brokerId: string | undefined;
  appWidth: number;
  isSidebarExpanded: boolean;
  sidebarTitle: string;
  isSearchModalOpen: boolean;
}
/*  *** ACTIONS  */
export enum appStateActions {
  SETBROKER,
  SETAPPWIDTH,
  EXPANDSIDEBAR,
  CONTRACTSIDEBAR,
  SETSIDEBARTITLE,
  OPENSEARCHMODAL,
  CLOSESEARCHMODAL,
}

/**** INITIAL STATE */

const initialState: IAppState = {
  isSearchModalOpen: false,
  brokerId: undefined,
  appWidth: 0,
  isSidebarExpanded: false,
  sidebarTitle: '',
};

let _lastIdleTimeUpdated = new Date();

export const getIdleTime = () => {
  return Math.floor(
    (new Date().getTime() - _lastIdleTimeUpdated.getTime()) / 1000,
  );
};

const resetIdleTime = () => {
  _lastIdleTimeUpdated = new Date();
};

/*  *** REDUCER */

const reducer = (state: IAppState, action: Action) => {
  const actionHandlers = {
    [appStateActions.SETBROKER]: () => ({ ...state, brokerId: action.payload }),
    [appStateActions.SETAPPWIDTH]: () => ({
      ...state,
      appWidth: action.payload,
    }),
    [appStateActions.EXPANDSIDEBAR]: () => ({
      ...state,
      isSidebarExpanded: true,
    }),
    [appStateActions.CONTRACTSIDEBAR]: () => ({
      ...state,
      isSidebarExpanded: false,
    }),
    [appStateActions.SETSIDEBARTITLE]: () => ({
      ...state,
      sidebarTitle: action.payload,
    }),
    [appStateActions.OPENSEARCHMODAL]: () => ({
      ...state,
      isSearchModalOpen: true,
    }),
    [appStateActions.CLOSESEARCHMODAL]: () => ({
      ...state,
      isSearchModalOpen: false,
    }),
  };

  const actionHandler = actionHandlers[action.type];

  if (actionHandler) {
    return actionHandler();
  }

  throw new Error(`Unhandled action type: ${action.type}`);
};

//////////////////////////////////////////////////////////////////////////////////////////////
type Action = {
  type: appStateActions;
  payload?: any;
};

interface IAppContext {
  state: IAppState;
  dispatch: React.Dispatch<Action>;
}

const StoreContext = React.createContext<IAppContext>({
  state: initialState,
  dispatch: () => null,
});

export const AppStateProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    document.body.addEventListener('mouseover', resetIdleTime);

    document.body.addEventListener('click', resetIdleTime);
    return () => {
      document.body.removeEventListener('mouseover', resetIdleTime);
      document.body.removeEventListener('click', resetIdleTime);
    };
  }, []);
  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {props.children}
    </StoreContext.Provider>
  );
};
export const useAppState = () => useContext(StoreContext);
