import { Box, Typography } from '@mui/material';

const HeroItem = ({
  label,
  text,
  sup,
  color,
}: {
  label: string;
  text?: string;
  sup?: string;
  color?: string;
}) => {
  const textColor = color !== undefined ? color : 'black';

  return (
    <Box ml={2} mr={2}>
      <Typography variant="body1" color="textSecondary" textAlign="center">
        {label}
      </Typography>
      {text && (
        <Typography
          variant="body1"
          textAlign="center"
          justifyContent="center"
          lineHeight="40px"
          fontSize="40px"
          marginTop="5px"
          color={textColor}
        >
          {text}
          <Sup sup={sup} />
        </Typography>
      )}
    </Box>
  );
};

export default HeroItem;

const Sup = ({ sup }: { sup?: string }) =>
  sup ? (
    <span
      style={{
        verticalAlign: 'super',
        fontSize: '16px',
      }}
    >
      {sup}
    </span>
  ) : null;
