import { FC } from 'react';
import { useUser } from 'reactfire';
import ContainerPrimary from '../../components/containers/ContainerPrimary/ContainerPrimary';
import HomeStyles from './HomeStyles';
import {
  Box,
  ListItemAvatar,
  List,
  Paper,
  Typography,
  Avatar,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import Logo from '../../components/Logo/Logo';
import { useNavigate } from 'react-router-dom';
import useFarms from '../../data/hooks/collections/useFarms';
import { HomeWork } from '@mui/icons-material';
import { Farm } from '@phyllome/common';

const Home: FC<unknown> = () => {
  const navigate = useNavigate();
  const { data: user } = useUser();
  const farmList = useFarms();

  return (
    <ContainerPrimary title="Phyllome Console">
      <HomeStyles>
        <Box
          width="300px"
          mb={2}
        >
          <Logo />
        </Box>
        <Box mb={2}>
          <Paper elevation={0}>
            <Box p={2}>
              You are logged in as {user?.displayName} ({user?.email})
            </Box>
          </Paper>
        </Box>
        <Box mb={2}>
          <Paper elevation={0}>
            <Box p={2}>
              <Typography variant="h5">Farms</Typography>
              <List>
                {farmList.map((item: Farm, key: number) => {
                  return (
                    <ListItemButton
                      key={key}
                      onClick={() => navigate(`/broker/${item.brokerId}`)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <HomeWork />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.name}
                        secondary={item.address}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </Box>
          </Paper>
        </Box>
      </HomeStyles>
    </ContainerPrimary>
  );
};

export default Home;
