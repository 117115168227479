import { useEffect } from 'react';
import { appStateActions, useAppState } from '../../data/AppState/useAppState';
import { Route, Routes, useParams } from 'react-router-dom';
import Devices from './Devices/Devices';
import Racking from './Racking/Racking';
import Messaging from './Messaging/Messaging';
import Sprinklers from './Sprinklers/Sprinklers';
import Alerts from './Logs/Alerts/Alerts';
import Tasks from './Logs/Tasks/Tasks';

import FactoryUI from './factoryui/FactoryUI';
import Seasons from './Seasons/Seasons';
import TraysEntryPoint from './Trays/TraysEntryPoint';
import CustomConsole from './CustomConsole/CustomConsole';
import CustomConsoleEdit from './CustomConsole/CustomConsoleEdit';

import Activity from './Logs/Activity/Activity';
import DesiredToMQTT from './DesiredToMQTT/DesiredToMQTT';
import Search from './Search/Search';
import Processing from './Seasons/Processing/Processing';

const BrokerEntry = () => {
  const { brokerId } = useParams();
  const { dispatch: appDispatch } = useAppState();

  useEffect(() => {
    appDispatch({ type: appStateActions.SETBROKER, payload: brokerId });
  }, [appDispatch, brokerId]);

  if (!brokerId) return null;
  return (
    <Routes>
      <Route
        path="/"
        element={<CustomConsole brokerId={brokerId} />}
      />
      <Route
        path="/dashboard"
        element={<CustomConsole brokerId={brokerId} />}
      />
      <Route
        path="/dashboard/edit"
        element={<CustomConsoleEdit brokerId={brokerId} />}
      />
      <Route
        path="/device"
        element={<Devices brokerId={brokerId} />}
      />
      <Route
        path="/device/:deviceType/:deviceId"
        element={<Devices brokerId={brokerId} />}
      />
      <Route
        path="racking/:rackLocation?"
        element={<Racking />}
      />
      <Route
        path="messaging"
        element={<Messaging />}
      />
      <Route
        path="sprinklers"
        element={<Sprinklers brokerId={brokerId} />}
      />
      <Route
        path="trays"
        element={<TraysEntryPoint />}
      />
      <Route
        path="trays/:seasonId"
        element={<TraysEntryPoint />}
      />
      <Route
        path="factoryui"
        element={<FactoryUI />}
      />
      <Route
        path="seasons/:seasonId?"
        element={<Seasons />}
      />
      <Route
        path="seasons/processing"
        element={<Processing />}
      />
      <Route
        path="sprinklers/:locationString"
        element={<Sprinklers brokerId={brokerId} />}
      />
      <Route
        path="logs/tasks"
        element={<Tasks brokerId={brokerId} />}
      />
      <Route
        path="logs/alerts"
        element={<Alerts brokerId={brokerId} />}
      />
      <Route
        path="logs/activity"
        element={<Activity brokerId={brokerId} />}
      />
      <Route
        path="logs/alerts/:alertId"
        element={<Alerts brokerId={brokerId} />}
      />
      {/* <Route path="mqtt" element={<LocalMQTT brokerId={brokerId} />} />

      <Route path="mqtt/:mqttId" element={<LocalMQTT brokerId={brokerId} />} /> */}
      <Route
        path="desired"
        element={<DesiredToMQTT />}
      />
      <Route
        path="search"
        element={<Search />}
      />
    </Routes>
  );
};

export default BrokerEntry;
