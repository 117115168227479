import { colors } from '@phyllome/common';
import {
  howLongAgoInEnglish,
  howManyDaysAgo,
  howManySecondsAgo,
} from '../../../helpers/FStimeAgo';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import { IDeviceWidget } from './types';
import DeviceTypeToIcon from '../../../components/DeviceTypeToIcon/DeviceTypeToIcon';

import { Box, Stack } from '@mui/material';
import Flash from '../../../components/Flash/Flash';
import FaceIcon from '@mui/icons-material/Face';

const DevicesItem = ({ children }: { children: React.ReactNode }) => {
  const itemStyle = {
    background: '#f0f0f0',
  };

  return <div style={itemStyle}>{children}</div>;
};

const getLastAccess = (lastAccessed: Date) => {
  if (!lastAccessed)
    return {
      col: '#999999',
      longAgo: 'never',
      tooOld: true,
    };

  const tsDiffSeconds = Math.abs(howManySecondsAgo(lastAccessed));
  const tooOld = howManyDaysAgo(lastAccessed) >= 12;
  const col = colors.ripening;

  // if (tsDiffSeconds > 30) col = colors.budding;
  // if (tsDiffSeconds > 120) col = colors.vegetative;
  // if (tsDiffSeconds > 600) col = colors.earth1;
  // if (tsDiffSeconds > 3600) col = colors.earth2; // one hour
  // if (tsDiffSeconds > 172800) col = colors.fog; // two days

  const longAgo = howLongAgoInEnglish(lastAccessed);

  const isOld = tsDiffSeconds > 600;

  return {
    color: col,
    longAgo: longAgo,
    tooOld: tooOld,
    isOld: isOld,
    secs: tsDiffSeconds,
  };
};

const DeviceTemplate = ({
  lastAccessed,
  deviceType,
  deviceId,
  children,
  labelRow,
  onClick,
  isSelected,
  isError,
  aliases,
}: IDeviceWidget) => {
  const lastAccessObj = getLastAccess(lastAccessed);

  if (!labelRow) labelRow = `${deviceType}/${deviceId}`;

  const backgroundColor = isError ? colors.earth3 : lastAccessObj.color;

  return (
    <DevicesItem>
      <Flash
        key={`${deviceType}.${deviceId}`}
        value={String(lastAccessed)}
        style={{ background: 'white' }}
      >
        <Card
          onClick={() => onClick()}
          variant="outlined"
          sx={{
            border: isSelected ? '1px solid #aaa' : '1px solid #ddd',
            height: '68px',
            background: isSelected ? '#e4f2e2' : 'transparent',
            ':hover': {
              background: isSelected ? '#e4f2e2' : '#f0f0f0',
              cursor: 'pointer',
              transition: 'background 0.3s',
            },
          }}
        >
          <Box
            sx={{
              padding: '0px',
            }}
          >
            <div
              style={{
                fontSize: '90%',
                position: 'relative',
                right: 0,
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  height: '18px',
                  left: 0,
                  padding: '3px',
                  lineHeight: '13px',
                  background: 'rgba(0,0,0, 0.2)',
                  fontSize: '10px',
                  fontWeight: 500,
                  width: '65px',
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  top: '48px',
                  color: '#fff',
                }}
              >
                {lastAccessObj.longAgo}
              </div>
            </div>
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingLeft: '20px',
                  fontSize: '50px',

                  background: lastAccessObj.isOld
                    ? backgroundColor + '25'
                    : backgroundColor,
                  color: lastAccessObj.isOld ? backgroundColor : '#fff',
                }}
              >
                <div style={{ position: 'relative', marginTop: '-10px' }}>
                  <DeviceTypeToIcon deviceType={deviceType} />
                </div>
              </Box>
              <Box sx={{ width: '100%' }}>
                <div
                  style={{
                    fontSize: '15px',
                    lineHeight: '15px',
                    paddingTop: '8px',
                  }}
                >
                  {aliases &&
                    aliases?.length > 0 &&
                    aliases[0] !== deviceId && (
                    <Box
                      sx={{
                        position: 'relative',
                      }}
                    >
                      <Chip
                        sx={{
                          position: 'absolute',
                          top: '-2px',
                          right: '6px',
                          background: 'white',
                        }}
                        color="primary"
                        variant="outlined"
                        size="small"
                        icon={<FaceIcon />}
                        label={`${aliases}`}
                      />
                    </Box>
                  )}
                  {labelRow}{' '}
                </div>
                {children && children}
              </Box>
            </Stack>
          </Box>
        </Card>
      </Flash>
    </DevicesItem>
  );
};

export default DeviceTemplate;
