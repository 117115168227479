import RackBox from '../../../../components/factoryui/components/RackBox';

import { useContext } from 'react';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useLatestTraybotByColumn from '../../../../devicespecific/Traybot/hooks/useLatestTraybotByColumn';
import { colors } from '@phyllome/common';

type OverlayTrayBotProps = {
  columnId: number;
};

const OverlayTrayBot = ({ columnId }: OverlayTrayBotProps) => {
  const { brokerId, columnSpec } = useContext(FarmContext);

  const shelves = columnSpec[columnId - 1].shelves;
  const traybot = useLatestTraybotByColumn(brokerId, columnId);

  if (!traybot) return null;

  const location = traybot?.location_row || 0;
  const destination = traybot?.destination_row || 0;
  const isOutOfPlace = location !== destination;

  return (
    <>
      <RackBox
        zIndex={90}
        row={traybot.location_row}
        shelf={traybot.location_shelf}
        shelves={shelves}
        style={{
          //opacity: selectedSeason ? 0.2 : 1,
          borderBottomWidth: '4px',
          borderBottomColor: colors.ocean,
          borderBottomStyle: isOutOfPlace ? 'dotted' : 'solid',
          transition: 'background 0.5s linear',
          cursor: 'pointer',
          textAlign: 'center',
          pointerEvents: 'none',
        }}
      />
      {isOutOfPlace && (
        <RackBox
          zIndex={90}
          row={traybot.destination_row}
          shelf={traybot.location_shelf}
          shelves={shelves}
          style={{
            //opacity: selectedSeason ? 0.2 : 1,

            opacity: 0.5,
            borderBottomWidth: '4px',
            borderBottomColor: colors.river,
            borderBottomStyle: 'solid',
            transition: 'background 0.5s linear',
            cursor: 'pointer',
            textAlign: 'center',
            pointerEvents: 'none',
          }}
        />
      )}
    </>
  );
};

export default OverlayTrayBot;
