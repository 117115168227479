import { FC, useEffect, useState } from 'react';
import { useAppDrawer } from './useAppDrawer';

import useDeviceType from '../../data/useDeviceType';

import { Box, Drawer, IconButton } from '@mui/material';

import { Close } from '@mui/icons-material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { appStateActions, useAppState } from '../../data/AppState/useAppState';
import SideBar from '../SideBar';
import { colors } from '@phyllome/common';
import { useLocation } from 'react-router-dom';
import Sus from '../../components/Sus/Sus';

interface IProps {
  onClose: () => void;
}

const AppDrawer: FC<IProps> = ({ onClose }) => {
  const { state } = useAppDrawer();
  const appState = useAppState();
  const deviceType = useDeviceType();
  const location = useLocation();
  const hash = location.hash.substring(1);
  const [key, setKey] = useState('123123');

  useEffect(() => {
    setKey(String(Math.random()));
  }, [hash]);
  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={state.isOpen}
      PaperProps={{
        sx: {
          width: deviceType.isDesktop
            ? appState.state.isSidebarExpanded
              ? '100%'
              : '800px'
            : '100%',
          background: colors.fog,
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: colors.ocean,
          padding: '4px',
          position: 'sticky',
          top: 0,
          zIndex: 100,
        }}
      >
        <Box sx={{ display: 'flex', lineHeight: '34px', height: '34px' }}>
          <Box>
            <IconButton
              sx={{ color: 'white' }}
              aria-label="close"
              size="small"
              onClick={() => {
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              color: 'white',
              textAlign: 'center',
            }}
          >
            {appState.state.sidebarTitle}
          </Box>
          {deviceType.isDesktop && (
            <Box sx={{ marginLeft: 'auto' }}>
              <IconButton
                sx={{ color: 'white' }}
                aria-label="adjust size"
                size="small"
                onClick={() => {
                  appState.dispatch({
                    type: appState.state.isSidebarExpanded
                      ? appStateActions.CONTRACTSIDEBAR
                      : appStateActions.EXPANDSIDEBAR,
                    payload: null,
                  });
                }}
              >
                {appState.state.isSidebarExpanded ? (
                  <ArrowForwardIcon />
                ) : (
                  <ArrowBackIcon />
                )}
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <div key={key}>
        {state.isOpen && (
          <Sus>
            <SideBar />
          </Sus>
        )}
      </div>
    </Drawer>
  );
};

export default AppDrawer;
