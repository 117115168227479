import React, { FC } from 'react';
import ShelfStyles from './ShelfStyles';

import { IGridItem, ILocation } from '../../../../../types/types';

import { useNavigate, useParams } from 'react-router-dom';
import LocationToString from '../../../../../helpers/LocationToString';

import Gradient from 'javascript-color-gradient';
import { colors } from '@phyllome/common';

const stateColours = {
  NORMAL: colors.fog,
  TRAY: colors.ripening,
};

const shelfColours = new Gradient()
  .setColorGradient(colors.seedling, colors.ripening)
  .setMidpoint(21)
  .getColors();

interface IProps {
  data: IGridItem;
  location: ILocation;
}

/*
const Icon: FC<{ iconObject: IconProp; className: string }> = ({
  iconObject,
  className,
}) => {
  return (
    <span style={{ margin: "2px" }}>
      <FontAwesomeIcon icon={iconObject} className={className} />
    </span>
  );
};
*/

const Shelf: FC<IProps> = ({ data, location }) => {
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const colorOf: string =
    data.state === 'NORMAL' ? stateColours.NORMAL : shelfColours[data.age];

  const isClickable = data.state !== 'NORMAL';

  return (
    <ShelfStyles
      color={colorOf || colors.ripening}
      clickable={isClickable}
      onClick={() => {
        navigate(`/broker/${brokerId}/racking/${LocationToString(location)}`);
      }}
    ></ShelfStyles>
  );
};

/*
      <Icon iconObject={faLightbulb} className="power" />
      <Icon iconObject={faTint} className="droplet" />
 */
export default Shelf;
