import { Stack } from '@mui/system';
import {  SelectElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import {
  FertigatorDeviceState,
  mqttDefinitionFertigatorCalibrate,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';
import { Typography } from '@mui/material';
import ChipBool from '../../../components/puregui/ChipBool/ChipBool';

const FormFertigatorCalibrateEC = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorCalibrate,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = {
    select: '2' };

  return (
    <PaperSection
      heading="Calibrate EC Probe"
      mb={2}
      subheading={mqttDefinitionFertigatorCalibrate.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              do: data?.select,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Calibrated EC probe at ${data.select}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          ec: yup
            .object({
              do: yup.number().required(),
            })
            .required(),
        })}
      >
        <Stack spacing={2}>
          <Stack spacing={2} direction="row">
            <ChipBool data={latest.calibration_ec_dry} label="Dry" />
            <ChipBool data={latest.calibration_ec_single} label="Single" />
            <ChipBool data={latest.calibration_ec_low} label="Low" />
            <ChipBool data={latest.calibration_ec_high} label="High" />
          </Stack>
          <Typography variant="body1">Need to calibrate the LOW and EITHER one of the HIGH values</Typography>
          <SelectElement
            fullWidth
            label="Select"
            name="select"
            options={[
              {
                id: '2',
                label: '84us (LOW)',
              },
              {
                id: '3',
                label: '1413us (HIGH)',
              },
              {
                id: '4',
                label: '2770us (HIGH)',
              },
            ]}
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorCalibrateEC;
