import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import React, { useContext, useState } from 'react';
import { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import TabPanel from '../../components/TabPanel/TabPanel';
import FarmContext from '../../components/FarmProvider/FarmContext';
import useLatestCo2 from './hooks/useLatestCo2';
import LatestCo2 from './LatestCo2';
import ControlCo2 from './ControlCo2';
import GraphCo2 from './GraphCo2';
import ConfigCo2 from './ConfigCo2';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import LatestCo2Info from './LatestCo2Info';
import LatestCo2Levels from './ControlLevelsCo2';
import ActivityLog from '../../components/ActivityLog/ActivityLog';

type IProps = {
  deviceId: string;
};

const SideBarEntryCo2: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const [value, setValue] = useState<number>(0);
  const latestDeviceInfo = useLatestCo2(brokerId, deviceId);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  if (!latestDeviceInfo)
    return (
      <Box p={2}>
        <Alert severity="info">No Latest Data!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={`co2/${latestDeviceInfo.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor="inherit"
        variant="scrollable"
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label="Latest" value={0} />
        <Tab label="Graphs" value={1} />
        <Tab label="Control" value={2} />
        <Tab label="Config" value={3} />
        <Tab label="Levels" value={4} />
        <Tab label="Activity" value={5} />
        <Tab label="Alerts" value={6} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <LatestCo2Info latest={latestDeviceInfo} />
        <LatestCo2 latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GraphCo2 latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ControlCo2 latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ConfigCo2 latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <LatestCo2Levels latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ActivityLog
          identifier={`device/co2/${deviceId}/notes`}
          heading="Activity Log"
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType="co2"
        />
      </TabPanel>
    </>
  );
};

export default SideBarEntryCo2;
