import { HarvesterDeviceState } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestHarvester = (brokerId: string, deviceId: string) => {
  return useDeviceState<HarvesterDeviceState>({
    brokerId,
    deviceId,
    deviceType: 'harvester',
  });
};

export default useLatestHarvester;
