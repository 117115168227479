import { GuardDeviceState } from '@phyllome/common';
import { FromFirebase } from '../../types/types';

import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';

import Stacker from '../../components/Stacker/Stacker';

import TimeSlider from '../../components/TimeSlider/TimeSlider';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

const GraphGuard = ({ latest }: { latest: FromFirebase<GuardDeviceState> }) => {
  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphGuard;
