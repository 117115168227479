import { colors } from '@phyllome/common';
import Gradient from 'javascript-color-gradient';
export const getColorByAge = (age: number): string => {
  const coloursByAge = new Gradient()
    .setColorGradient(colors.seedling, colors.ripening)
    .setMidpoint(21)
    .getColors();

  coloursByAge[0] = colors.seedling;
  return coloursByAge[age - 1] || colors.ripening;
};
export default getColorByAge;
