import { doc } from 'firebase/firestore';
import {
  useFirestore,
  useFirestoreDocData,
  useFunctions,
  useUser,
} from 'reactfire';

import { FirestoreTimestamp } from '../../types/types';
import { useEffect, useState } from 'react';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { AnyObject } from '@phyllome/common';
export enum IMessageStages {
  NOTINIT = 'NOTINIT',
  INIT = 'INIT',
  RUN = 'RUN',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

interface IMessage {
  async: boolean;
  topic: string;
  params: AnyObject;
}
export interface ICollectionMessaging {
  docId: string;
  brokerId: string;
  params: AnyObject;
  id: string;
  topic: string;
  response: AnyObject;
  isProcessed: boolean;
  stage: number;
  timestampOrigin: FirestoreTimestamp;
  timestampCommenced: FirestoreTimestamp;
  timestampCompleted: FirestoreTimestamp;
}

interface returnDoc {
  stage: IMessageStages;
  response: AnyObject;
  timestampCommenced: FirestoreTimestamp | null;
  timestampCompleted: FirestoreTimestamp | null;
}

const defaultMsg = {
  stage: IMessageStages.NOTINIT,
  response: {},
  timestampCommenced: null,
  timestampCompleted: null,
};

interface IMsgCloudFunctionReturn {
  error: AnyObject;
  id: string;
}
export interface IUseMessagingReturn {
  isRunning: boolean;
  msgSent: IMessage;
  send: (p: IMessage) => {};
  docId: string | undefined;
  stage: IMessageStages;
  response: AnyObject;
  timestampCommenced: FirestoreTimestamp | null;
  timestampCompleted: FirestoreTimestamp | null;
}

const useMessaging = (brokerId: string): IUseMessagingReturn => {
  const functions = useFunctions();

  functions.region = 'australia-southeast1';
  const sendUserMessage = httpsCallable(functions, 'userMessage');
  const { data: user } = useUser();
  const firestore = useFirestore();
  const [docId, setDocId] = useState<string>();
  const [docData, setDocData] = useState<returnDoc>(defaultMsg);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [msgSent, setMsgSent] = useState<IMessage>({
    async: true,
    topic: '',
    params: {},
  });
  const _docId = docId || 'undefined';
  const ref = doc(firestore, 'messaging', _docId);
  const fsDD = useFirestoreDocData(ref);

  useEffect(() => {
    if (docId && fsDD.data) {
      setDocData({
        stage: fsDD.data.stage,
        response: fsDD.data.response,
        timestampCommenced: fsDD.data.timestampCommenced,
        timestampCompleted: fsDD.data.timestampCompleted,
      });
      if (
        fsDD.data.stage === IMessageStages.COMPLETE ||
        fsDD.data.stage === IMessageStages.FAILED
      ) {
        setIsRunning(false);
      }
    }
  }, [docId, fsDD]);

  const send = async (message: IMessage): Promise<string> => {
    setDocId('');
    setDocData(defaultMsg);
    setMsgSent(message);
    setIsRunning(true);

    const payload = {
      params: message.params,
      topic: message.topic,
      async: message.async,
      brokerId,
      email: user?.email,
    };

    const messageFunc = (await sendUserMessage(
      payload,
    )) as HttpsCallableResult<IMsgCloudFunctionReturn>;

    setDocId(messageFunc.data.id);
    return messageFunc.data.id;
  };

  return {
    isRunning,
    msgSent,
    send,
    docId,
    stage: docData.stage,
    response: docData.response,
    timestampCommenced: docData.timestampCommenced,
    timestampCompleted: docData.timestampCompleted,
  };
};

export default useMessaging;
