import { type CloudAction, hashObject } from '@phyllome/common';
import { useCallback, useEffect, useRef } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { usePollerCache } from './PollerCacheProvider';
import { getIdleTime } from '../AppState/useAppState';
export type StatusPoller = 'PENDING' | 'IDLE' | 'RUNNING' | 'DONE' | 'ERROR';
export type UsePollerReturn<T extends CloudAction> = {
  status: StatusPoller;
  msg: T['returnMsg'];
};

/**
 * Custom hook to periodically fetch data using the action API.
 *
 * @template T - The type of the CloudAction.
 *
 * Usage:
 * ```tsx
 * const deviceList = usePoller<CloudActionGetDevices>(
 *   {
 *     action: "getDevices",
 *     payload: { brokerId: brokerId },
 *   },
 *   10,
 * );
 * ```
 *
 * @param {T} args - The action and payload to be sent to the action API.
 * @param {number} refreshTime - The interval time in seconds for periodic polling.
 * @returns {UsePollerReturn<T>} - An object containing the status and message of the poller.
 */

const usePoller = <T extends CloudAction>(
  args: Omit<T, 'returnMsg'>,
  refreshTime: number,
  defaultValue: any = undefined,
  seed: string = '',
  isPaused: boolean = false,
): UsePollerReturn<T> => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const hash = useDeepCompareMemo(() => hashObject({ ...args }), [args]);
  const { cache, setCache } = usePollerCache();

  const run = useCallback(() => {
    if (isPaused) return;
    if (getIdleTime() > 60 || isPaused) return;

    setCache((prev) => ({
      ...prev,
      [hash]: {
        lastUpdated: prev[hash]?.lastUpdated || new Date(),
        msg: prev[hash]?.msg || defaultValue,
        args: args,
        isFetching: false,
        status: 'PENDING',
      },
    }));
  }, [args, defaultValue, hash, setCache, isPaused]);

  const startPeriodicRun = useCallback(
    (args: Omit<T, 'returnMsg'>, refreshTime: number) => {
      const initialRunPromise = run();

      if (refreshTime > 0) {
        if (intervalRef.current) clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
          run(); // Subsequent runs are not awaited here
        }, refreshTime * 1000);
      }
      return initialRunPromise; // Return the promise from the initial run
    },
    [run],
  );

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    startPeriodicRun(args, refreshTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seed]);
  return {
    status: cache[hash]?.status || 'IDLE',
    msg: cache[hash]?.msg || defaultValue,
  };
};

export default usePoller;
