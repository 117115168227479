import { FC, useMemo } from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import FlyOut from './FlyOut';
import GraphCursor from './GraphCursor';

import chartThemeEnum from './chartThemeEnum';
import { TimeSeriesData } from './useTimeSeriesPoller';
import { removeDuplicates } from './helpers';
export type IVictoryChatProps = {
  color: string;
  timeSeries: TimeSeriesData;
  beginDate: Date;
  endDate: Date;
};

const ReChartVictoryBool: FC<IVictoryChatProps> = ({
  color,
  timeSeries,
  beginDate,
  endDate,
}) => {
  const normalisedData = useMemo(() => {
    if (!timeSeries.data || timeSeries.data.length === 0)
      return [
        { x: beginDate, y: false },
        { x: endDate, y: false },
      ];
    const noDups = removeDuplicates(timeSeries.data);
    const firstElement = noDups[0];
    const lastElement = noDups[noDups.length - 1];

    const addedStuff = [
      {
        x: beginDate,
        y: firstElement.y,
      },
      ...timeSeries.data,
      {
        x: endDate,
        y: lastElement.y,
      },
    ];

    const collect: { x: Date; y: number }[] = [];
    let current: number | null = null;

    addedStuff.forEach((item) => {
      if (item.y !== current && current !== null) {
        collect.push({
          x: item.x,
          y: current,
        });
      }
      current = item.y;
      collect.push(item);
    });
    return collect;
  }, [beginDate, endDate, timeSeries.data]);

  return (
    <VictoryChart
      height={60}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={() => ' '}
          labelComponent={
            <VictoryTooltip
              pointerLength={0}
              flyoutComponent={<FlyOut color={color} />}
            />
          }
        />
      }
      theme={chartThemeEnum}
      scale={{ x: 'time', y: 'linear' }}
      minDomain={{ y: 0 }}
      maxDomain={{ y: 2 }}
    >
      <VictoryAxis />
      <VictoryArea
        style={{
          data: {
            fill: color,
            strokeWidth: 0,
          },
        }}
        data={normalisedData}
      />
      <GraphCursor />
    </VictoryChart>
  );
};

export default ReChartVictoryBool;
