import { FC, useEffect, useState } from 'react';

import Phorm from '../../../../components/Form/Components/Phorm';
import PhormSubmit from '../../../../components/Form/PhormSubmit/PhormSubmit';
import PhormDateTime from '../../../../components/Form/Components/PhormDateTime';
import { DatabaseSeason, DatabaseTraySeason } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';
import PhormBlock from '../../../../components/Form/Components/PhormBlock';

import useAction from '../../../../data/hooks/useAction';
import PhormErrors from '../../../../components/Form/Components/PhormErrors';
import useActivityLog from '../../../../data/hooks/useActivityLog';

interface IProps {
  seasonData: FromFirebase<DatabaseSeason>;
  fieldNameSeason: keyof FromFirebase<DatabaseSeason>;
  fieldNameTraySeason: keyof FromFirebase<DatabaseTraySeason>;
  label: string;
}

const FormSetTimeBased: FC<IProps> = ({
  seasonData,
  fieldNameSeason,
  fieldNameTraySeason,
  label,
}) => {
  const [defaultValues, setDefaultValues] = useState({});
  const [sendNull, setSendNull] = useState(false);

  const updateSeasonTrays = useAction('traySeasonUpdateAllTraysInSeason');
  const activityLog = useActivityLog(`season/${seasonData.id}/notes`);

  const Submit = (
    <PhormSubmit
      height="55px"
      sx={{ marginLeft: '10px' }}
      busy={updateSeasonTrays.status === 'RUNNING'}
      inline
    >
      Apply
    </PhormSubmit>
  );

  const SubmitNull = (
    <PhormSubmit
      height="55px"
      sx={{ marginLeft: '10px' }}
      busy={updateSeasonTrays.status === 'RUNNING'}
      inline
      variant="outlined"
      onClick={() => {
        setSendNull(true);
      }}
    >
      Clear
    </PhormSubmit>
  );

  useEffect(() => {
    const defaultSeasonValue = seasonData[fieldNameSeason] as any;

    setDefaultValues({
      [fieldNameTraySeason]: defaultSeasonValue?.toDate() || new Date(),
    });
  }, [seasonData, fieldNameSeason, fieldNameTraySeason]);
  return (
    <Phorm
      defaultValues={defaultValues}
      onSubmit={(values: Partial<DatabaseTraySeason>) => {
        // this assumes that the payload is an object, with
        // a single key-value pair, where the value is a Date object.

        const key = Object.keys(values)[0] as keyof DatabaseTraySeason;
        const val = values[key] as Date;

        if (sendNull === false) {
          updateSeasonTrays.run({
            seasonId: seasonData.id,
            brokerId: seasonData.brokerId,
            update: {
              [key]: val.toISOString(),
            },
          });

          activityLog.add(`Updated growth timeline for ${key}`, 'OPERATION');
        } else {
          updateSeasonTrays.run({
            seasonId: seasonData.id,
            brokerId: seasonData.brokerId,
            update: {
              [key]: null,
            },
          });
          activityLog.add(`Cleared growth timeline for ${key}`, 'OPERATION');
        }
        setSendNull(false);
      }}
      busy={false}
    >
      <PhormBlock>
        <PhormDateTime name={fieldNameTraySeason} label={label} />
        {Submit}
        {SubmitNull}
      </PhormBlock>
      <PhormErrors />
    </Phorm>
  );
};

export default FormSetTimeBased;
