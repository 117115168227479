import { PackagerDeviceState } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestPackager = (brokerId: string, deviceId: string) => {
  return useDeviceState<PackagerDeviceState>({
    brokerId,
    deviceId,
    deviceType: 'packager',
  });
};

export default useLatestPackager;
