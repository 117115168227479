import GenericPing from '../Generic/control/GenericPing';
import { FromFirebase } from '../../types/types';
import { AirDeviceState } from '@phyllome/common';

const ControlAir = ({ latest }: { latest: FromFirebase<AirDeviceState> }) => {
  return (
    <div>
      <GenericPing
        deviceId={latest.deviceId}
        brokerId={latest.brokerId}
        deviceType="air"
      />
    </div>
  );
};

export default ControlAir;
