import { DatabaseTraySeason } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';

import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../../../components/ReactJson/ReactJson';

const SideBarTraySeasonDebug = ({
  traySeasonData,
}: {
  traySeasonData: FromFirebase<DatabaseTraySeason>;
}) => {
  return (
    <>
      <PaperSection heading="Debug (trayseason data)" mt={2}>
        <ReactJson src={traySeasonData} />
      </PaperSection>
    </>
  );
};

export default SideBarTraySeasonDebug;
