import RackBoxFlash from '../../../../components/factoryui/components/RackBoxFlash';
import type { NDSDeviceFromAction } from '../../../../types/types';
import getUIInfoBasedOnAge from '../helpers/getUIInfoBasedOnAge';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  data: NDSDeviceFromAction[];
  shelves: number;
  onClick?: (NDSLatestRow: NDSDeviceFromAction) => void;
};

const OverlaySprinklerSelect = ({
  shelves,
  data,
  onClick = () => null,
}: OverlayTraySimpleProps) => {
  return (
    <>
      {data.map((row) => {
        if (!row?.properties?.timestamp) {
          console.log(row?.properties);
        }
        const { timestamp, location_row, location_shelf } = row.properties;
        const { age } = getUIInfoBasedOnAge(new Date(), new Date(timestamp));

        return (
          <RackBoxFlash
            title={`updated ${age} minutes ago`}
            track={String(age)}
            onClick={() => onClick(row)}
            key={row.deviceId}
            zIndex={50}
            row={location_row}
            shelf={location_shelf}
            shelves={shelves}
            onHoverStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
            style={{
              cursor: 'pointer',
            }}
          />
        );
      })}
    </>
  );
};

export default OverlaySprinklerSelect;
