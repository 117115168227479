import type { ZoneDeviceState } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useZoneState = (brokerId: string, deviceId: string) => {
  return useDeviceState<ZoneDeviceState>({
    brokerId,
    deviceId: deviceId,
    deviceType: 'zone',
  });
};

export default useZoneState;
