import React, { FC, useContext } from 'react';

import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';

import DeviceTypeToIcon from '../../../components/DeviceTypeToIcon/DeviceTypeToIcon';

import { SprinklerProvider } from './SprinkerContext';

import FarmContext from '../../../components/FarmProvider/FarmContext';
import ScaffoldRack from '../../../components/factoryui/ScaffoldRack/ScaffoldRack';

import OverLaySprinklerEntry from './overlays/OverLaySprinklerEntry';

import Filters from './Filters';
import { ColumnSpec } from '@phyllome/common';

const Sprinklers: FC<{ brokerId: string }> = () => {
  const { columnSpec, brokerId } = useContext(FarmContext);

  return (
    <ContainerPrimary
      title="Sprinklers"
      icon={<DeviceTypeToIcon deviceType="nds" />}
    >
      <SprinklerProvider>
        <Filters />
        <Scaffolds columnSpec={columnSpec} brokerId={brokerId} />
      </SprinklerProvider>
    </ContainerPrimary>
  );
};

const Scaffolds = ({
  columnSpec,
  brokerId,
}: {
  columnSpec: ColumnSpec[];
  brokerId: string;
}) => {
  return columnSpec.map((column, index) => {
    return (
      <div style={{ paddingTop: '8px' }} key={index}>
        <ScaffoldRack column={index + 1}>
          <OverLaySprinklerEntry
            brokerId={brokerId}
            column={index + 1}
            shelves={column.shelves}
          />
        </ScaffoldRack>
      </div>
    );
  });
};

export default Sprinklers;
