import { useEffect, useState } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  limit,
  getDocs,
} from 'firebase/firestore';
import { ZoneDeviceState, convertArrayToHashTable } from '@phyllome/common';
import { FromFirebase } from '../../../types/types';

const useZones = (brokerId: string) => {
  const firestore = getFirestore();
  const [zones, setZones] = useState<{
    [key: string]: FromFirebase<ZoneDeviceState>;
  }>({});
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchZones = async () => {
      if (!brokerId) {
        setZones({}); // Clear state if no brokerId is provided
        return;
      }

      const documentReference = query(
        collection(firestore, 'zoneLatest'),
        where('brokerId', '==', brokerId),
        limit(2000),
      );

      try {
        const querySnapshot = await getDocs(documentReference);
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as FromFirebase<ZoneDeviceState>[];

        const hash = convertArrayToHashTable(data, 'deviceId');

        setZones(hash);
      } catch (err) {
        console.error(err);
        setError(err instanceof Error ? err.message : 'Error fetching zones');
      }
    };

    fetchZones();
  }, [firestore, brokerId]);

  if (error) {
    console.error(error);
  }
  return zones; // Return the hash table of zones
};

export default useZones;
