import { DatabaseSeason } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import { Box, Typography } from '@mui/material';

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const SideBarSeasonTasksProgress = ({
  seasonData,
}: {
  seasonData: FromFirebase<DatabaseSeason>;
  brokerId: string;
}) => {
  const numberOfDevices = seasonData.totalDevices;
  const numberOfPendingDevices =
    numberOfDevices - seasonData.totalDevicesSatisfied;

  if (seasonData?.state === 'completed') return null;

  const percentageOfDevicesComplete =
    ((numberOfDevices - numberOfPendingDevices) / numberOfDevices) * 100;

  if (!numberOfPendingDevices) {
    return null;
  }
  return (
    <Box textAlign="center" ml={10} mr={10} mb={4}>
      <Typography variant="h6" gutterBottom>
        Updating Devices
      </Typography>
      <LinearProgressWithLabel value={percentageOfDevicesComplete} />
    </Box>
  );
};

export default SideBarSeasonTasksProgress;
