import type { NDSDeviceFromAction } from '../../../types/types';
import useNDSLatestByColumnPoller from './useNDSLatestByColumnPoller';
export type UseNDSLatestLookupReturn = { [key: string]: NDSDeviceFromAction };

/*
  Instead of returning an array, it returns an object
*/

const useNDSLatestWithLookup = (
  brokerId: string,
  column: number,
): UseNDSLatestLookupReturn => {
  const data = useNDSLatestByColumnPoller(brokerId, column);

  if (!data?.msg) return {};

  const collect: { [key: string]: NDSDeviceFromAction } = {};

  // biome-ignore lint/complexity/noForEach: <explanation>
  data.msg.forEach((value) => {
    const { location_row, location_shelf } = value.properties;
    const key = `${location_row}${location_shelf}.${location_row}`;

    collect[key] = value;
  });
  return collect;
};

export default useNDSLatestWithLookup;
