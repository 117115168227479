import type { AnyObject, DatabaseSeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';

import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';

import { CircularProgress, Stack } from '@mui/material';
import { useDevicesInSeason } from '../../../../data/hooks/collections/useDevicesInSeason';
import { isArray } from 'lodash';
import DeviceTaskIconNew from '../../../../components/DeviceTaskIcon/DeviceTaskIconNew';
import useAction from '../../../../data/hooks/useAction';

const hasDesiredState = (data: AnyObject) => {
  return Object.keys(data || {}).length !== 0;
};

const SideBarSeasonDevices = ({
  seasonData,
}: {
  seasonData: FromFirebase<DatabaseSeason>;
}) => {
  const devicesInSeason = useDevicesInSeason(seasonData.id);
  const pAll = useAction('seasonProcess');

  if (seasonData?.state === 'completed') return null;

  if (!devicesInSeason.msg || !isArray(devicesInSeason.msg))
    return (
      <PaperSection heading="Attached Devices (loading...)" mb={2}>
        <CircularProgress size="1.2em" />
      </PaperSection>
    );

  const numberOfDissatifiedDevices = devicesInSeason.msg.filter((item) =>
    hasDesiredState(item?.desiredProperties),
  ).length;

  const numberOfDevices = devicesInSeason.msg.length;

  return (
    <PaperSection
      heading={`Attached Devices (${numberOfDevices - numberOfDissatifiedDevices} / ${numberOfDevices})`}
      mb={2}
      buttonText="Force Update"
      buttonClickEvent={() => {
        pAll.run({
          seasonId: seasonData.id,
        });
      }}
    >
      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={0.5}>
        {devicesInSeason.msg.map((device) => {
          return <DeviceTaskIconNew data={device} key={device.deviceId} />;
        })}
      </Stack>
    </PaperSection>
  );
};

export default SideBarSeasonDevices;
