import { ActionReturn, CloudAction } from '@phyllome/common';
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';

import { useFunctions } from 'reactfire';
import { httpsCallableFromURL } from 'firebase/functions';
import { useDebouncedCallback } from 'use-debounce';

type PollerCache = {
  [key: string]: {
    msg: any;
    args: Omit<CloudAction, 'returnMsg'>;
    status: 'PENDING' | 'IDLE' | 'RUNNING' | 'DONE' | 'ERROR';
    lastUpdated: Date;
    isFetching: boolean;
  };
};

const createEndpoint = (name: string) => {
  return process.env.NODE_ENV === 'production'
    ? `https://${name}-hbtwsngtia-ts.a.run.app`
    : `http://127.0.0.1:5001/phyllome-core/us-central1/${name}`;
};

const PollerCacheContext = createContext<
  | {
    cache: PollerCache;
    setCache: React.Dispatch<React.SetStateAction<PollerCache>>;
  }
  | undefined
>(undefined);

export const PollerCacheProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [cache, setCache] = useState<PollerCache>({});
  const functions = useFunctions();
  const func = useMemo(
    () =>
      httpsCallableFromURL<any, ActionReturn>(
        functions,
        createEndpoint('action'),
      ),
    [functions],
  );

  const processMe = useDebouncedCallback(() => {
    if (Object.keys(cache).length === 0) return;

    Object.keys(cache).forEach(async (key) => {
      const { args, status } = cache[key];

      if (status === 'PENDING') {
        setCache((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            status: 'RUNNING',
            isFetching: true,
            lastUpdated: new Date(),
          },
        }));
        // console.log(
        //   'REQUEST',
        //   JSON.stringify({ action: args.action, payload: args.payload }),
        // );
        const request = await func({
          action: args.action,
          payload: args.payload,
        });

        setCache((prev) => ({
          ...prev,
          [key]: {
            ...prev[key],
            msg: request.data.msg,
            status: 'DONE',
            isFetching: true,
            lastUpdated: new Date(),
          },
        }));
      }
    });
  }, 500);

  useEffect(() => {
    processMe();
  }, [cache, processMe]);

  const value = useMemo(() => ({ cache, setCache }), [cache]);

  return (
    <PollerCacheContext.Provider value={value}>
      {children}
    </PollerCacheContext.Provider>
  );
};
export const usePollerCache = () => {
  const context = useContext(PollerCacheContext);

  if (!context) {
    throw new Error('usePollerCache must be used within a PollerCacheProvider');
  }
  return context;
};
