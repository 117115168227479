import {
  IrrigationDeviceState,
  mqttDefinitionIrrigationConfigGet,
} from '@phyllome/common';
import { FromFirebase } from '../../types/types';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';

import FormIrrigationConfigFill from './forms/FormIrrigationConfigFill';
import FormIrrigationConfigOzone from './forms/FormIrrigationConfigOzone';
import FormIrrigationConfigTransfer from './forms/FormIrrigationConfigTransfer';
import FormIrrigationConfigPressure from './forms/FormIrrigationConfigPressure';
import FormIrrigationConfigSetNurseryTank from './forms/FormIrrigationConfigSetNurseryTank';
import FormIrrigationConfigSetNurseryPressures from './forms/FormIrrigationConfigSetNurseryPressures';
import FormIrrigationConfigSetNurserySump from './forms/FormIrrigationConfigSetNurserySump';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

const ConfigIrrigation = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  return (
    <>
      <PaperSection heading="Irrigation Controller Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">TBA</LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      {latest.brokerId !== 'mcp-nursery' && (
        <>
          <FormIrrigationConfigFill latest={latest} />
          <FormIrrigationConfigOzone latest={latest} />
          <FormIrrigationConfigTransfer latest={latest} />
          <FormIrrigationConfigPressure latest={latest} />
          <FormAnyDeviceConfigGet
            deviceId={latest.deviceId}
            definition={mqttDefinitionIrrigationConfigGet}
          />
        </>
      )}
      {latest.brokerId === 'mcp-nursery' && (
        <>
          <FormIrrigationConfigSetNurseryTank latest={latest} />
          <FormIrrigationConfigSetNurseryPressures latest={latest} />
          <FormIrrigationConfigSetNurserySump latest={latest} />
          <FormAnyDeviceConfigGet
            deviceId={latest.deviceId}
            definition={mqttDefinitionIrrigationConfigGet}
          />
        </>
      )}
    </>
  );
};

export default ConfigIrrigation;
