import { useUser } from 'reactfire';

const useIsDeveloper = ():boolean => {

  const user = useUser();

  const email = user.data?.email;

  return !!(email?.includes('mick') || email?.includes('seb'));

};

export default useIsDeveloper;