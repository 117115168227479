import { DatabaseSeed, DatabaseTraySeason } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';

import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import co2LitresToKilos from '../../../../helpers/co2LitresToKilos';
import ConsumablesTable, {
  ConsumableDataType,
} from '../../../../components/ConsumablesTable/ConsumablesTable';
import ConsumableDefaultCosts from '../../../../components/ConsumablesTable/ConsumableDefaultCosts';

const SideBarTraySeasonConsumables = ({
  traySeasonData,
  seedData,
}: {
  traySeasonData: FromFirebase<DatabaseTraySeason>;
  seedData: FromFirebase<DatabaseSeed>;
}) => {

  const seedCost =
    (seedData?.pricePerMillion || ConsumableDefaultCosts.seeds) / 1000000;

  const ConsumableData: ConsumableDataType = {
    energy: Number(traySeasonData?.consumedEnergy) / 1000, // convert to kW
    water: Number(traySeasonData?.consumedWater),
    nutrientA: Number(traySeasonData?.consumedNutrientA),
    nutrientB: Number(traySeasonData?.consumedNutrientB),
    nutrientPeroxide: Number(traySeasonData?.consumedPeroxide),
    nutrientAcid: Number(traySeasonData?.consumedAcid),
    nutrientBase: Number(traySeasonData?.consumedBase),
    co2: co2LitresToKilos(Number(traySeasonData?.consumedCO2) / 1000),
    seeds: Number(
      traySeasonData?.consumedSeedQuantity || traySeasonData?.seedDensity,
    ),
  };

  const ConsumableCosts: ConsumableDataType = {
    nutrientA: ConsumableDefaultCosts.nutrientA,
    nutrientB: ConsumableDefaultCosts.nutrientB,
    nutrientPeroxide: ConsumableDefaultCosts.nutrientPeroxide,
    nutrientAcid: ConsumableDefaultCosts.nutrientAcid,
    nutrientBase: ConsumableDefaultCosts.nutrientBase,
    energy: ConsumableDefaultCosts.energy,
    water: ConsumableDefaultCosts.water,
    co2: ConsumableDefaultCosts.co2,
    seeds: seedCost,
  };

  const productiveTrays = 1;

  return (
    <PaperSection heading="Consumables" mb={2}>
      <ConsumablesTable
        consumableCosts={ConsumableCosts}
        consumableData={ConsumableData}
        totalTrays={productiveTrays}
      />
    </PaperSection>
  );
};

export default SideBarTraySeasonConsumables;
