import React, { Dispatch, FC, Reducer } from 'react';

type TraysContextType = {
  layers: {
    primaryLayer: 'TRAYS' | 'LIGHTING' | 'IRRIGSCHEDULE' | 'CURRENTDAY';
    secondaryLayer: string[];
  };
  selectedTray?: string;
  selectedZone?: string;
  selectedSeason?: string;
  displayedSeason?: string;
};

const initialValues: TraysContextType = {
  layers: {
    primaryLayer: 'TRAYS',
    secondaryLayer: [],
  },
  selectedTray: undefined,
  selectedZone: undefined,
  selectedSeason: undefined,
  displayedSeason: undefined,
};

type TraysContextState = {
  state: TraysContextType;
  dispatch: Dispatch<TraysContextAction>;
};

type TraysContextAction =
  | {
    type: 'SET_PRIMARY_LAYER';
    payload: 'TRAYS' | 'LIGHTING' | 'IRRIGSCHEDULE';
  }
  | {
    type: 'SET_SECONDARY_LAYER';
    payload: string[];
  }
  | {
    type: 'SET_SELECTED_TRAY';
    payload: string | undefined;
  }
  | {
    type: 'SET_SELECTED_ZONE';
    payload: string | undefined;
  }
  | {
    type: 'SET_SELECTED_SEASON';
    payload: string | undefined;
  }
  | {
    type: 'SET_DISPLAYED_SEASON';
    payload: string | undefined;
  };

const traysContextReducer: Reducer<TraysContextType, TraysContextAction> = (
  _state,
  action,
) => {
  switch (action.type) {
    case 'SET_PRIMARY_LAYER': {
      return {
        ..._state,
        layers: {
          ..._state.layers,
          primaryLayer: action.payload,
        },
      };
    }

    case 'SET_SECONDARY_LAYER': {
      return {
        ..._state,
        layers: {
          ..._state.layers,
          secondaryLayer: action.payload,
        },
      };
    }

    case 'SET_SELECTED_TRAY': {
      return {
        ..._state,
        selectedTray: action.payload,
      };
    }

    case 'SET_SELECTED_ZONE': {
      return {
        ..._state,
        selectedZone: action.payload,
      };
    }

    case 'SET_SELECTED_SEASON': {
      return {
        ..._state,
        selectedSeason: action.payload,
      };
    }

    case 'SET_DISPLAYED_SEASON': {
      return {
        ..._state,
        displayedSeason: action.payload,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
};

export const TraysContext = React.createContext<TraysContextState>({
  state: initialValues,
  dispatch: () => null,
});
export const TraysProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(
    traysContextReducer,
    initialValues,
  );

  return (
    <TraysContext.Provider value={{ state, dispatch }}>
      {children}
    </TraysContext.Provider>
  );
};
