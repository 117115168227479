import { LightingDeviceState } from '@phyllome/common';
import GenericPing from '../Generic/control/GenericPing';
import { FromFirebase } from '../../types/types';
import LatestLightingInfo from './LatestLightingInfo';

const ControlLighting = ({
  latest,
}: {
  latest: FromFirebase<LightingDeviceState>;
}) => {
  return (
    <>
      <LatestLightingInfo latest={latest} />
      <div>
        <GenericPing
          deviceId={latest.deviceId}
          brokerId={latest.brokerId}
          deviceType="lighting"
        />
      </div>
    </>
  );
};

export default ControlLighting;
