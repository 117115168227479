import { ReportProblem } from '@mui/icons-material';
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Badge,
} from '@mui/material';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContainerPrimary from '../../../../components/containers/ContainerPrimary/ContainerPrimary';
import AlertLevel from '../../../../components/puregui/AlertLevel/AlertLevel';
import { PhyconMUI } from '../../../../components/puregui/Phycon/Phycon';
import { howLongAgoInEnglish } from '../../../../helpers/FStimeAgo';
import Logs from '../Logs';

import useAlerts from './hooks/useAlerts';
import useNavSide from '../../../../data/hooks/useNavSide';
import StyledTableCell from '../../../../components/Tables/StyledTableCell';
import { colors, DatabaseAlert } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';

const Alerts: FC<{ brokerId: string }> = ({ brokerId }) => {
  const alerts = useAlerts({ brokerId, count: 400 });
  const singleAlerts = removeDuplicateAlerts(alerts);
  const [selectedRow, setSelectedRow] = useState({});
  const { alertId } = useParams();

  const navSide = useNavSide();

  return (
    <ContainerPrimary
      title="Logs"
      icon={<PhyconMUI icon={ReportProblem} size={120} />}
    >
      <Logs brokerId={brokerId} selected={0}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="alerts" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Level</StyledTableCell>
                <StyledTableCell>Timestamp</StyledTableCell>
                <StyledTableCell>Device Path</StyledTableCell>
                <StyledTableCell>Message</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {singleAlerts.map((alert) => (
                <TableRow
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: alertId === alert.id ? '#dedede' : 'none',
                  }}
                  hover
                  key={alert.id}
                  onClick={() => {
                    navSide(`alert/${alert.id}`);
                    setSelectedRow(alert.id);
                  }}
                  selected={selectedRow === alert.id}
                >
                  <TableCell
                    sx={{
                      cursor: 'pointer',
                      boxSizing: 'border-box',
                      boxShadow:
                        selectedRow === alert.id
                          ? `inset 3px 0px 0px 0px ${colors.budding}`
                          : 'inset 3px 0px 0px 0px transparent',
                    }}
                  >
                    {alert.count > 1 ? (
                      <Badge badgeContent={alert.count} color="fog">
                        <AlertLevel level={alert.level} />
                      </Badge>
                    ) : (
                      <AlertLevel level={alert.level} />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {howLongAgoInEnglish(alert.timestamp)} ago
                  </TableCell>
                  <TableCell>
                    {alert.deviceType !== 'unknown' && (
                      <>
                        {alert.deviceType}/{alert.deviceId}
                      </>
                    )}
                  </TableCell>
                  <TableCell>{alert.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Logs>
    </ContainerPrimary>
  );
};

export default Alerts;

const removeDuplicateAlerts = (alerts: FromFirebase<DatabaseAlert>[]): (FromFirebase<DatabaseAlert> & { count: number })[] => {
  const alertMap = new Map<string, { alert: FromFirebase<DatabaseAlert>, count: number }>();

  alerts.forEach(alert => {
    const key = `${alert.brokerId}-${alert.deviceId}-${alert.deviceType}-${alert.message}`;

    if (alertMap.has(key)) {
      alertMap.get(key)!.count += 1;
    } else {
      alertMap.set(key, { alert, count: 1 });
    }
  });
  return Array.from(alertMap.values()).map(({ alert, count }) => ({ ...alert, count }));
};
