import LayoutCenter from '../../../components/puregui/LayoutCenter/LayoutCenter';
import Logo from '../../../components/Logo/Logo';
import LoginStyles from './LoginStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { useAuth } from 'reactfire';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Box, Button } from '@mui/material';

const provider = new GoogleAuthProvider();
const Login = () => {
  const authHook = useAuth();

  const signIn = () => {
    signInWithPopup(authHook, provider).then(function (result) {
      console.log('successfully signed in', result);
    });
  };

  return (
    <LayoutCenter>
      <LoginStyles>
        <Logo />
        <Box mx="auto" paddingTop={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faGoogle} />}
            onClick={signIn}
          >
            Sign In With Google
          </Button>
        </Box>
      </LoginStyles>
    </LayoutCenter>
  );
};

export default Login;
