import { useContext, useEffect } from 'react';

import DeviceTypeToIcon from '../../../components/DeviceTypeToIcon/DeviceTypeToIcon';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import ScaffoldRack from '../../../components/factoryui/ScaffoldRack/ScaffoldRack';

import { Box } from '@mui/material';

import { ColumnSpec } from '@phyllome/common';
import { TraysContext } from './TraysContext';
import TraysFilters from './TraysFilters';
import OverlayEntryPointTraysCurrent from './OverylayEntryPoints/OverlayEntryPointTraysCurrent';
import OverlayEntryPointLighting from './OverylayEntryPoints/OverlayEntryPointLighting';
import OverlayEntryPointValves from './OverylayEntryPoints/OverlayEntryPointValves';
import useSeason from '../Seasons/useSeason';
import OverlayEntryPointIrrigationSchedules from './OverylayEntryPoints/OverlayEntryPointIrrigationSchedules';
import OverlayLift from './Overlays/OverlayLift';

import OverlayTrayBot from './Overlays/OverlayTrayBot';
import OverlayEntryPointCurrentDay from './OverylayEntryPoints/OverlayEntryPointCurrentDay';

const Trays = ({
  columnSpec,

  children = null,
  seasonId = undefined,
}: {
  columnSpec: ColumnSpec[];
  children?: React.ReactNode;
  seasonId?: string;
}) => {
  const trayFilterContext = useContext(TraysContext);
  const seasonData = useSeason(seasonId);

  useEffect(() => {
    if (seasonId && !trayFilterContext.state.displayedSeason) {
      trayFilterContext.dispatch({
        type: 'SET_DISPLAYED_SEASON',
        payload: seasonId,
      });
    }

    if (!seasonId && trayFilterContext.state.displayedSeason) {
      trayFilterContext.dispatch({
        type: 'SET_DISPLAYED_SEASON',
        payload: undefined,
      });
    }
  }, [seasonId, trayFilterContext]);

  const title = seasonData?.name ? `Trays ${seasonData?.name}` : 'Trays';

  return (
    <ContainerPrimary
      title={title}
      icon={<DeviceTypeToIcon deviceType="tray" />}
    >
      {children}
      <Box sx={{ marginBottom: '16px' }}>
        <TraysFilters />
      </Box>
      {columnSpec.map((column, index) => {
        return (
          <Box sx={{ pt: 1 }} key={index}>
            <ScaffoldRack column={index + 1}>
              {trayFilterContext.state.layers.primaryLayer === 'TRAYS' && (
                <OverlayEntryPointTraysCurrent column={index + 1} />
              )}
              {trayFilterContext.state.layers.primaryLayer ===
                'IRRIGSCHEDULE' && (
                <OverlayEntryPointIrrigationSchedules column={index + 1} />
              )}
              {trayFilterContext.state.layers.primaryLayer === 'CURRENTDAY' && (
                <OverlayEntryPointCurrentDay column={index + 1} />
              )}
              {trayFilterContext.state.layers.secondaryLayer.includes(
                'LIGHTING',
              ) && <OverlayEntryPointLighting column={index + 1} />}
              {trayFilterContext.state.layers.secondaryLayer.includes(
                'SPRINKLERS',
              ) && <OverlayEntryPointValves column={index + 1} />}
              <OverlayLift columnId={index + 1} />
              <OverlayTrayBot columnId={index + 1} />
            </ScaffoldRack>
          </Box>
        );
      })}
    </ContainerPrimary>
  );
};

export default Trays;
