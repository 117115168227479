import { Stack } from '@mui/system';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import {
  mqttDefinitionPackagerReset,
  PackagerDeviceState,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormPackagerReset = ({
  latest,
}: {
  latest: FromFirebase<PackagerDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionPackagerReset,
    deviceId,
  );
  const activityLog = useActivityLog(`device/packager/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionPackagerReset, {
    rid: 1234, // This shouldn't work, but it does
  });

  return (
    <PaperSection
      heading="Packager Reset"
      mb={2}
      subheading={mqttDefinitionPackagerReset.topic}
    >
      <PhormContainer
        defaultValues={{ defaultValues }}
        onSubmit={() => {
          controlLifecycle.run({
            payload: {},
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add('Reset the bag count to zero', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({})}
      >
        <Stack spacing={2}>
          <p>Reset the bag count to zero</p>
          <SubmitControl controlHook={controlLifecycle}>
            Reset Packager Count
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormPackagerReset;
