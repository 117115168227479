import { ReportProblem } from '@mui/icons-material';

import { FC, useState } from 'react';
import ContainerPrimary from '../../../../components/containers/ContainerPrimary/ContainerPrimary';
import { PhyconMUI } from '../../../../components/puregui/Phycon/Phycon';

import useActivityLogs from './hooks/useActivityLogs';
import ActivityLogChild from '../../../../components/ActivityLog/ActivityLogChild';
import Logs from '../Logs';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import { Alert, AlertTitle, Box, TablePagination } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

const DEFAULT_ROWS_PER_PAGE = 50;

const Activity: FC<{ brokerId: string }> = ({ brokerId }) => {
  const activityLog = useActivityLogs(brokerId);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!activityLog) return null;
  return (
    <ContainerPrimary
      title="Logs"
      icon={<PhyconMUI icon={ReportProblem} size={120} />}
    >
      <Logs brokerId={brokerId} selected={2}>
        <PaperSection heading="Activity Log" mb={2}>
          {(rowsPerPage > 0
            ? activityLog?.data?.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage,
            )
            : activityLog?.data
          )?.map((log) => (
            <ActivityLogChild key={log.id} log={log} showOpenParent={true} />
          ))}
          {activityLog.data?.length == 0 && (
            <Alert severity="info">
              <AlertTitle>No activities</AlertTitle>There have been no recent
              activity logs recorded
            </Alert>
          )}
          <Box>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100, { label: 'All', value: -1 }]}
              colSpan={3}
              count={activityLog?.data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Box>
        </PaperSection>
      </Logs>
    </ContainerPrimary>
  );
};

export default Activity;
