import { SeederDeviceState } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestSeeder = (brokerId: string, deviceId: string) => {
  return useDeviceState<SeederDeviceState>({
    brokerId,
    deviceId,
    deviceType: 'seeder',
  });
};

export default useLatestSeeder;
