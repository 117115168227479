import { colors } from '@phyllome/common';
import { FavouriteDefinition } from './types';

const lightingGraph: FavouriteDefinition = {
  lightingCurrentA: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lighting',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[LIGHT] ${deviceId}/current_A`,
    color: colors.earth1,
    field: 'current_A',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  lightingCurrentB: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lighting',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[LIGHT] ${deviceId}/current_B`,
    color: colors.earth1,
    field: 'current_B',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  lightingCurrentC: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lighting',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[LIGHT] ${deviceId}/current_C`,
    color: colors.earth1,
    field: 'current_C',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
    },
  }),
  lightingMode: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'lighting',
    subType: 'enum',
    showHeading: ({ deviceId }) => `[LIGHT] ${deviceId}/mode`,
    color: colors.earth1,
    field: 'mode',
    brokerId,
    deviceId,
    mapping: {
      '0': 'Off',
      '1': 'Auto',
      '2': 'Manual',
    },
  }),
};

export default lightingGraph;
