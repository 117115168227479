import type { DeviceType } from '@phyllome/common';

import DeviceTypeToIcon from '../DeviceTypeToIcon/DeviceTypeToIcon';
import { Tooltip } from '@mui/material';
import useNavSide from '../../data/hooks/useNavSide';
import type { FC } from 'react';
import Pulse from '../Pulse/Pulse';
import type { DeviceFromSeason } from '../../data/hooks/collections/useDevicesInSeason';
import hasDesiredState from './helpers/hasDesiredState';
import deviceTypeActiveColor from './helpers/deviceTypeActiveColor';
import DeviceIconToolTipText from './components/DeviceIconToolTipText';

type IDeviceTaskIconProps = {
  data: DeviceFromSeason;
};

//`${data.deviceType}/${data.deviceId}`
const DeviceTaskIconNew: FC<IDeviceTaskIconProps> = ({ data }) => {
  const navSide = useNavSide();

  const uri = data.deviceType === 'nds' ? 'sprinklers' : 'zone';
  const deviceId = data?.alias ? data.alias[0] : data.deviceId;
  const color = deviceTypeActiveColor(
    data.deviceType as DeviceType,
    data.properties,
  );
  const enabled =
    (data.deviceType === 'nds' && data.properties?.enable) ||
    (data.deviceType === 'zone' && data.properties?.enable);

  return (
    <Tooltip title={<DeviceIconToolTipText data={data} />} arrow>
      <div
        style={{
          cursor: 'pointer',
          fontSize: '18px',
        }}
        onClick={() => navSide(`${uri}/${deviceId}`)}
      >
        <Pulse disabled={!hasDesiredState(data?.desiredProperties)}>
          <DeviceTypeToIcon
            opacity={enabled ? 1 : 0.5}
            deviceType={data.deviceType as DeviceType}
            noMargin
            color={color}
          />
        </Pulse>
      </div>
    </Tooltip>
  );
};

export default DeviceTaskIconNew;
