import React, { FC } from 'react';
import styled from 'styled-components';

const LoggedInAsStyles = styled.div`
  display: none;
  position: fixed;
  bottom: 10px;
  left: 80px;
`;

interface IProps {
  brokerId: string | undefined;
  visible: boolean;
}

const LoggedInAs: FC<IProps> = ({ brokerId, visible }) => {
  return (
    <LoggedInAsStyles>
      <div className="text">
        {visible ? `Using ${brokerId}` : `Using ${brokerId}`}
      </div>
    </LoggedInAsStyles>
  );
};

export default LoggedInAs;
