import { colors } from '@phyllome/common';
import { FC } from 'react';

type HighlightedTextProps = {
  text: string;
  words?: string[];
};
export const HighlightedActionText: FC<HighlightedTextProps> = ({
  text,
  words = positiveWords.concat(negativeWords),
}) => {
  const highlightedText = text.split(/(\s+)/).map((word, index) => {
    if (words.includes(word)) {
      const higlightStyle = {
        color: positiveWords.includes(word) ? colors.budding : colors.earth3,
        fontWeight: '400',
      };

      return (
        <span style={higlightStyle} key={`${index}`}>
          {word}
        </span>
      );
    } else {
      if (/^\d+\/\d+$/.test(word)) {
        return <b key={index}>{word}</b>;
      } else if (!isNaN(Number(word))) {
        return <b key={index}>{word}</b>;
      } else {
        return <span key={index}>{word}</span>;
      }
      return word;
    }
  });

  return <>{highlightedText}</>;
};
export default HighlightedActionText;

const positiveWords = [
  'unrejected',
  'Unflagged',
  'Unrejected',
  'unflag',
  'unreject',
  'open',
  'true',
  'enabled',
  'Enabled',
  'started',
  'Started',
  'unpaused',
  'harvested',
  'seeded',
  'Resumed',
  'Updated',
  'updated',
  'executed',
  'ran',
];

const negativeWords = [
  'flagged',
  'rejected',
  'Rejected',
  'Reset',
  'flag',
  'reject',
  'closed',
  'Closed',
  'false',
  'disabled',
  'Disabled',
  'Stopped',
  'stopped',
  'paused',
  'Paused',
  'Cleared',
  'cleared',
];
