import { useEffect, useState, useCallback } from 'react';
import {
  getFirestore,
  doc,
  collection,
  onSnapshot,
  Firestore,
} from 'firebase/firestore';
import { DatabaseSeed } from '@phyllome/common';
import { FromFirebase } from '../../../types/types';
//import isEqual from 'lodash/isEqual';

// Custom hook for deep comparison effect
// const useDeepCompareEffect = (
//   callback: () => void | (() => void),
//   dependencies: any[],
// ) => {
//   const previousRef = useRef<any[]>();

//   useEffect(() => {
//     if (!isEqual(previousRef.current, dependencies)) {
//       previousRef.current = dependencies;
//     }
//   });

//   useEffect(callback, [callback]);
// };

// Memoize Firestore instance
const useFirestoreInstance = () => {
  const [firestore] = useState(() => getFirestore());

  return firestore;
};

const useSeed = (seedId?: string | null) => {
  const firestore = useFirestoreInstance();
  const [data, setData] = useState<FromFirebase<DatabaseSeed> | null>(null);

  const subscribeToSeed = useCallback((seedId: string, firestore: Firestore) => {
    const documentReference = doc(collection(firestore, 'seed'), seedId);

    return onSnapshot(
      documentReference,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const seedData = {
            id: docSnapshot.id,
            ...docSnapshot.data(),
          } as FromFirebase<DatabaseSeed>;

          if (!seedData?.durationDays) seedData.durationDays = 0;
          setData(seedData);
        } else {
          setData(null);
        }
      },
      (error) => {
        console.error('Error listening to document:', error);
        setData(null);
      },
    );
  }, []);

  useEffect(() => {
    if (!seedId) {
      setData(null);
      return;
    }

    const unsubscribe = subscribeToSeed(seedId, firestore);

    return () => unsubscribe();
  }, [firestore, seedId, subscribeToSeed]);
  return data;
};

export default useSeed;