import { ILocation } from '../types/types';

const p = (n: number) => {
  return n.toString().padStart(3, '0');
};

const LocationToString = (location: ILocation) => {
  return `${p(location.column)}${p(location.shelf)}${p(location.row)}`;
};

export default LocationToString;
