import {  FertigatorDeviceState } from '@phyllome/common';
import { FromFirebase } from '../../types/types';
import useAction from '../../data/hooks/useAction';
import Markdown from 'react-markdown';
import Phorm from '../../components/Form/Components/Phorm';
import PhormTextField from '../../components/Form/Components/PhormTextField';
import PhormBlock from '../../components/Form/Components/PhormBlock';
import PhormSubmit from '../../components/Form/PhormSubmit/PhormSubmit';
import { Alert, AlertTitle, Box } from '@mui/material';

const AnalysisFertigator = ({ latest }: { latest: FromFirebase<FertigatorDeviceState> }) => {
  const getAction = useAction('deviceAnalyse');

  if (getAction.status === 'ERROR') {
    return <Alert severity="error"><AlertTitle>Error</AlertTitle>There was a problem analysing this using AI</Alert>;
  }

  const prompt = `This is a fertigation unit measuring the pH, EC, temperature, ORP and dissolved oxygen (DO) of a hydroponic system.
  Analyse the timeseries data from this sensor. 

  - Look for any potential events.
  - Talk about ph, ec, DO, ORP, with headings for each.
  - Talk about any correlations.
  - Talk about how we could improve the yield of crops planted in this environment
  - Convert any times from GMT to local time (Australia/Sydney)
  `;

  return (
    <>
      <Box>
        <Phorm
          onSubmit={(values) => {
            getAction.run({
              brokerId: latest.brokerId,
              deviceId: latest.deviceId,
              deviceType: 'fertigator',
              text: values.prompt,
            });
          }}
          defaultValues={{
            prompt: prompt,
          }}
        >
          <PhormBlock>
            <PhormTextField name="prompt" label="Prompt" multiline />
          </PhormBlock>
          <PhormBlock>
            <PhormSubmit>Fetch Analysis Using AI</PhormSubmit>
          </PhormBlock>
        </Phorm>
        {getAction.status === 'RUNNING' && <div>Loading...</div>}
        {getAction.status === 'DONE' && (
          <div>
            <Markdown>{String(getAction.msg)}</Markdown>
          </div>
        )}
      </Box></>
  );
};

export default AnalysisFertigator;
