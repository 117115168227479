import { Co2DeviceState, mqttDefinitionCo2ConfigGet } from '@phyllome/common';
import { FromFirebase } from '../../types/types';

import FormCo2ConfigSetDosing from './forms/FormCo2ConfigSetDosing';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import FormCo2ConfigSetCutOff from './forms/FormCo2ConfigSetCutOff';
import { formatBytes } from '../../helpers/formatBytes';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';
import { formatSeconds } from '../../helpers/formatSeconds';

const ConfigCo2 = ({ latest }: { latest: FromFirebase<Co2DeviceState> }) => {
  return (
    <>
      <PaperSection heading="CO2 Controller Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.build}</LabelValue>
        <LabelValue label="Network IP">{latest.network_ip}</LabelValue>
        <LabelValue label="Free memory">
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormCo2ConfigSetCutOff latest={latest} />
      <FormCo2ConfigSetDosing latest={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionCo2ConfigGet}
      />
    </>
  );
};

export default ConfigCo2;
