export function formatSeconds(seconds: number) {
  if (seconds === 0) return '0 seconds';
  const s = 1;
  const m = s * 60;
  const h = m * 60;
  const d = h * 24;
  const days = Math.floor(seconds / d);
  const hours = Math.floor((seconds % d) / h);
  const minutes = Math.floor((seconds % h) / m);
  const secs = Math.floor((seconds % m) / s);
  const parts = [];

  if (days > 0) parts.push(days + ' days');
  if (hours > 0) parts.push(hours + ' hours');
  if (minutes > 0) parts.push(minutes + ' minutes');
  if (secs > 0) parts.push(secs + ' seconds');
  return parts.join(', ');
}
