import {
  differenceInDays,
  sub,
  differenceInSeconds,
  differenceInMilliseconds,
  formatDistance,
} from 'date-fns';
import { Timestamp } from 'firebase/firestore';
export const getNowAsFS = (): Timestamp => {
  const newDate: Date = new Date();

  return Timestamp.fromDate(newDate);
};
export const getTimewindowAsFS = (end: Date, minutes: number) => {
  const start = sub(end, { minutes });

  return { start: Timestamp.fromDate(start), end: Timestamp.fromDate(end) };
};
export const getDaysAgoAsFS = (days = 1, timestamp?: Timestamp): Timestamp => {
  const date = timestamp ? timestamp.toDate() : new Date();
  const oneDayAgo: number = date.getTime() - 1000 * 60 * 60 * 24 * days;

  const newDate: Date = new Date(oneDayAgo);

  return Timestamp.fromDate(newDate);
};
export const hoursAgoAsFS = (hours = 1, timestamp?: Timestamp): Timestamp => {
  const date = timestamp ? timestamp.toDate() : new Date();
  const oneHourAgo: number =
    date.getTime() - 1000 * 60 * 60 * 24 * (hours / 24);

  const newDate: Date = new Date(oneHourAgo);

  return Timestamp.fromDate(newDate);
};
export const hoursAgoAsFSRounded = (hours: number) => {
  const d = new Date();

  d.setMinutes(d.getMinutes(), 0, 0);

  const result = sub(d, {
    hours,
  });

  return Timestamp.fromDate(result);
};
export const howManySecondsAgo = (timestamp: Timestamp | Date): number => {
  const current =
    timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;
  const now = new Date();

  return differenceInSeconds(now, current);
};
export const howManyDaysAgo = (
  timestamp: Timestamp | Date | undefined | null,
): number => {
  if (!timestamp) return 0;

  const current =
    timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;

  const now = new Date();

  return differenceInDays(now, current);
};
export const diffInMilliseconds = (t1: Timestamp, t2: Timestamp): number => {
  return differenceInMilliseconds(t1.toDate(), t2.toDate());
};
export const howLongAgoInEnglish = (
  timestamp: Timestamp | undefined | null | Date,
): string => {
  if (!timestamp) return 'Undefined';
  const current =
    timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;

  const secondsAgo = howManySecondsAgo(current);

  if (secondsAgo < 30) return 'now';

  // remove the word

  const now = new Date();
  const distance = formatDistance(now, current);

  // remove the word "about" from distance
  return distance.replace('about ', '');
};
