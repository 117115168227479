import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import ModeStandbySharpIcon from '@mui/icons-material/ModeStandbySharp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AutoModeSharpIcon from '@mui/icons-material/AutoModeSharp';
import LoopIcon from '@mui/icons-material/Loop';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';

import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import ChipEnum from '../../components/puregui/ChipEnum/ChipEnum';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import { ChipEnumData } from '../../components/puregui/ChipEnum/types';

import TankLevel from '../../components/TankLevel/TankLevel';
import { IrrigationDeviceState } from '@phyllome/common';

const modeStates: ChipEnumData = [
  {
    label: 'Off',
    value: 0,
    color: 'earth3',
    icon: <ModeStandbySharpIcon />,
  },
  { label: 'Auto', value: 1, color: 'primary', icon: <AutoModeSharpIcon /> },
  {
    label: 'Manual',
    value: 2,
    color: 'sunlight',
    icon: <PlayCircleOutlineIcon />,
  },
];

const irrigationStates: ChipEnumData = [
  {
    label: 'Irrigation Pump',
    value: 0,
    color: 'earth3',
    icon: <ReportSharpIcon />,
  },
  {
    label: 'Irrigation',
    value: 1,
    color: 'primary',
    icon: <LoopIcon />,
  },
];

const LatestIrrigationInfo = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  if (latest.brokerId == 'mcp-nursery') {
    const heroItems = [
      {
        label: 'Supply Level',
        text: latest?.levels_supply?.toString(),
        sup: '%',
      },
      {
        label: 'Supply Pressure',
        text: latest?.pressures_supply?.toString(),
        sup: 'kpa',
      },
      {
        label: 'Pump Pressure',
        text: latest?.pressures_pump?.toString(),
        sup: 'kpa',
      },
    ];

    return (
      <PaperSection
        mb={2}
        heading="Latest Irrigation Info"
        sx={{ textAlign: 'center' }}
        subheading={`Last Updated: ${dateToString(
          latest.timestamp,
          dateFormatTypes.TRUNCATED,
        )}`}
      >
        <Box mb={4} sx={{ textAlign: 'center' }}>
          <Stack direction="row" spacing={8} justifyContent="center">
            <TankLevel
              level={latest?.levels_supply || 0}
              label="Supply"
              lowerLimit={latest?.tankMinimumLevel}
              upperLimit={latest?.tankMaximumLevel}
            />
          </Stack>
        </Box>
        <Box mb={2}>
          <HeroGroup items={heroItems} />
        </Box>
        <Box mb={2}>
          <ChipEnum data={modeStates} value={Number(latest.mode)} />
          <ChipBool
            data={latest.pumps_supply}
            label="Irrigation Pump"
            color="primary"
            icon={<LoopIcon />}
            tooltip="Shows false if the irrigation pump has a fault"
          />
          <ChipBool
            data={latest.valves_return}
            label="Return Valve"
            icon={<PlayForWorkIcon />}
          />
          <ChipBool
            data={latest.valves_supply}
            label="Supply Valve"
            icon={<PlayForWorkIcon />}
          />
          <ChipBool
            data={Number(latest.levels_sump) > 0}
            label="Sump Level"
            color="earth3"
            icon={<ReportSharpIcon />}
          />
          <ChipBool
            data={latest.pumps_sump}
            label="Sump Pump"
            color="primary"
            icon={<LoopIcon />}
          />
        </Box>
      </PaperSection>
    );
  } else {
    const heroItems = [
      {
        label: 'Supply Level',
        text: latest?.levels_supply?.toString(),
        sup: '%',
        color:
          Number(latest?.levels_supply) <= latest?.supply_min
            ? 'error'
            : 'black',
      },
      {
        label: 'Return Level',
        text: latest?.levels_return?.toString(),
        sup: '%',
      },
      {
        label: 'Return Pressure',
        text: latest?.pressures_return?.toString(),
        sup: 'kpa',
        color:
          Number(latest?.pressures_return) >= latest?.pressure_max
            ? 'error'
            : 'black',
      },
    ];

    return (
      <PaperSection
        mb={2}
        heading="Latest Irrigation Info"
        sx={{ textAlign: 'center' }}
        subheading={`Last Updated: ${dateToString(
          latest.timestamp,
          dateFormatTypes.TRUNCATED,
        )}`}
      >
        <Box mb={4} sx={{ textAlign: 'center' }}>
          <Stack direction="row" spacing={8} justifyContent="center">
            <TankLevel
              level={latest?.levels_supply || 0}
              lowerLimit={latest?.supply_min}
              upperLimit={latest?.supply_max}
              label="Supply"
            />
            <TankLevel
              level={latest?.levels_return || 0}
              label="Return"
              lowerLimit={latest?.return_min}
              upperLimit={latest?.return_max}
            />
          </Stack>
        </Box>
        <HeroGroup items={heroItems} />
        <Box mb={2}>
          <ChipEnum data={modeStates} value={Number(latest.mode)} />
          <ChipBool
            data={Number(latest.pressures_return) >= latest.pressure_max}
            label="Pressure"
            color="earth3"
            icon={<ReportSharpIcon />}
            tooltip={`Shows if the pressure is below ${latest.pressure_max}`}
          />
          <ChipBool
            data={Number(latest.levels_supply) <= latest.supply_min}
            label="Supply"
            color="earth3"
            icon={<ReportSharpIcon />}
            tooltip={`Shows if the supplytank level is below ${latest.supply_min}%`}
          />
          <ChipBool
            data={
              latest.ozone_generator &&
              latest.ozone_valve &&
              latest.pumps_transfer
            }
            label="Ozone Santisation"
            color="primary"
            icon={<BubbleChartIcon />}
            tooltip="Shows true if ozone generator AND ozone valve AND transfer pump are on"
          />
        </Box>
        <Box mb={2}>
          <ChipEnum
            data={irrigationStates}
            value={Number(latest.pumps_irrigation)}
            tooltip="Shows true if theres no fault with the irrigation pump controller"
          />
          <ChipBool
            data={latest.pumps_fertigation}
            label="Fertigation"
            color="primary"
            icon={<LoopIcon />}
            tooltip="State of fertigation pump"
          />
          <ChipBool
            data={latest.pumps_transfer}
            label="Transfer Pump"
            color="primary"
            icon={<LoopIcon />}
            tooltip="State of transfer pump"
          />
          <ChipBool
            data={latest.valves_ozone}
            label="Ozone"
            color="primary"
            icon={<PlayForWorkIcon />}
            tooltip="State of ozone valve and ozone generator"
          />
          <ChipBool
            data={latest.valves_transfer}
            label="Transfer"
            color="primary"
            icon={<PlayForWorkIcon />}
            tooltip="state of transfer valve"
          />
          <ChipBool
            data={latest.valves_mains}
            label="Mains"
            color="primary"
            icon={<PlayForWorkIcon />}
            tooltip="state of mains water valve"
          />
          <ChipBool
            data={latest.valves_discharge}
            label="Discharge"
            color="earth3"
            icon={<PlayForWorkIcon />}
            tooltip="state of discharge valve, true means the discharge valve is open"
          />
        </Box>
      </PaperSection>
    );
  }
};

export default LatestIrrigationInfo;
