import { NDSDeviceFromAction } from '../../types/types';
import FormNDSEnable from './forms/FormNDSEnable';

const ControlNDS = ({ deviceState }: { deviceState: NDSDeviceFromAction }) => {
  return (
    <>
      <FormNDSEnable deviceState={deviceState} />
    </>
  );
};

export default ControlNDS;
