import { FertigatorDeviceState } from '@phyllome/common';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { FromFirebase } from '../../types/types';
import { Box, Grid } from '@mui/material';
// import TankLevelWithControl from '../../components/TankLevel/TankLevelWithControl';
import tankDefinition from '../../components/TankLevel/TankDefinition';
import calculatePercentageDifference from '../../helpers/calculatePercentageDifference';
import { Constants } from '../../types/constants';
// import useActivityLog from '../../data/hooks/useActivityLog';
import FormFertigatorConfigSetTanks from './forms/FormFertigatorConfigSetTanks';
import TankLevel from '../../components/TankLevel/TankLevel';

const ControlLevelsFertigator = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  // const activityLog = useActivityLog(
  //   `device/fertigator/${latest.deviceId}/notes`,
  // );

  // const addActivityLog = (text: string) => {
  //   activityLog.add(text, 'OPERATION');
  // };

  const tanks: Array<tankDefinition> = [
    {
      label: 'Acid',
      consumed: latest?.tanks_acid || 0,
      payload: { acid: true },
    },
    {
      label: 'Nutrient A',
      consumed: latest?.tanks_nutrientA || 0,
      payload: { nutrientA: true },
    },
    {
      label: 'Nutrient B',
      consumed: latest?.tanks_nutrientB || 0,
      payload: { nutrientB: true },
    },
    {
      label: 'Peroxide',
      consumed: latest?.tanks_peroxide || 0,
      payload: { peroxide: true },
    },
    {
      label: 'Pump',
      consumed: latest?.tanks_pump || 0,
      payload: { pump: true },
    },
  ];

  return (
    <>
      <PaperSection
        mb={2}
        heading="Fertigator Tank Levels"
        //    subheading={mqttDefinitionFertigatorClear.topic}
      >
        <Box mb={4} sx={{ textAlign: 'center' }}>
          <Grid container spacing={8} justifyContent="center">
            {tanks.map((tank, index) => (
              <Grid item key={index}>
                <TankLevel
                  label={tank.label}
                  level={calculatePercentageDifference(
                    Constants.ALEXANDRIA_FERTIGATOR_TANK_CAPACITY,
                    tank.consumed,
                  )}
                  // subLabel={`${(tank.consumed / 1000).toLocaleString(
                  //   undefined,
                  //   {
                  //     maximumFractionDigits: 0,
                  //   },
                  // )} L`}
                  //payload={tank.payload}
                  //deviceId={latest.deviceId}
                  // mqttDefinitionDeviceClear={mqttDefinitionFertigatorClear}
                  //addActivityLog={addActivityLog}
                  key={index}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </PaperSection>
      <FormFertigatorConfigSetTanks latest={latest} />
    </>
  );
};

export default ControlLevelsFertigator;
