import { DatabaseSeason, DatabaseTraySeason } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';

import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';

import FlagIcon from '@mui/icons-material/Flag';
import { LoadingButton } from '@mui/lab';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import useAction from '../../../../data/hooks/useAction';
import { useSnackbar } from 'notistack';
import useActivityLog from '../../../../data/hooks/useActivityLog';

const SideBarTraySeasonEdit = ({
  traySeasonData,
  seasonData,
}: {
  traySeasonData: FromFirebase<DatabaseTraySeason>;
  seasonData: FromFirebase<DatabaseSeason>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const traySeasonUpdateTray = useAction('traySeasonUpdateTray');
  const activityLog = useActivityLog(`traySeason/${traySeasonData?.id}/notes`);

  const position = `${traySeasonData.locationColumn}.${traySeasonData.locationShelf}.${traySeasonData.locationRow}`;

  return (
    <PaperSection
      heading="Edit"
      mb={2}
      hidden={seasonData?.state === 'completed'}
    >
      <LoadingButton
        loading={traySeasonUpdateTray.status === 'RUNNING'}
        disabled={traySeasonData?.isRejected}
        loadingPosition="start"
        startIcon={<FlagIcon />}
        variant={traySeasonData.isFlagged ? 'outlined' : 'contained'}
        color="sunlight"
        style={{ marginRight: 10 }}
        onClick={() => {
          traySeasonUpdateTray
            .run({
              update: {
                brokerId: traySeasonData.brokerId,
                seasonId: traySeasonData.seasonId,
                id: traySeasonData.id,
                isFlagged: !traySeasonData?.isFlagged,
              },
            })
            .then(() => {
              const text = traySeasonData.isFlagged
                ? `Tray at ${position} is no longer flagged.`
                : `Tray at ${position} flagged`;

              enqueueSnackbar(text, { variant: 'success' });

              activityLog.add(
                traySeasonData.isFlagged ? 'Removed flag' : 'Flagged tray',
                'OPERATION',
              );
            });
        }}
      >
        {traySeasonData.isFlagged ? 'Remove Flag' : 'Flag Tray'}
      </LoadingButton>
      <LoadingButton
        loading={traySeasonUpdateTray.status === 'RUNNING'}
        loadingPosition="start"
        startIcon={<ThumbDownIcon />}
        variant={traySeasonData.isRejected ? 'outlined' : 'contained'}
        color="earth3"
        style={{ marginRight: 10 }}
        onClick={() => {
          traySeasonUpdateTray
            .run({
              update: {
                brokerId: traySeasonData.brokerId,
                seasonId: traySeasonData.seasonId,
                id: traySeasonData.id,
                isRejected: !traySeasonData?.isRejected,
              },
            })
            .then(() => {
              const text = traySeasonData.isRejected
                ? `Tray at ${position} is no longer rejected.`
                : `Tray at ${position} is rejected`;

              enqueueSnackbar(text, { variant: 'success' });

              activityLog.add(
                traySeasonData.isRejected ? 'Unrejected tray' : 'Rejected tray',
                'OPERATION',
              );
            });
        }}
      >
        {traySeasonData.isRejected ? 'Unreject Tray' : 'Reject Tray'}
      </LoadingButton>
    </PaperSection>
  );
};

export default SideBarTraySeasonEdit;
