import { useEffect, useState } from 'react';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import { FromFirebase } from '../../../../../types/types';
import { DatabaseAlert } from '@phyllome/common';

// Gets the singular alert
const useAlert = (id: string) => {
  const firestore = getFirestore();
  const [data, setData] = useState<FromFirebase<DatabaseAlert> | null>(null);

  useEffect(() => {
    const fetchAlert = async () => {
      try {
        const documentReference = doc(collection(firestore, 'alert'), id);
        const docSnapshot = await getDoc(documentReference);

        if (docSnapshot.exists()) {
          setData({
            id: docSnapshot.id,
            ...docSnapshot.data(),
          } as FromFirebase<DatabaseAlert>);
        } else {
          setData(null); // Document does not exist
        }
      } catch (error) {
        console.error('Error fetching alert:', error);
        setData(null); // In case of an error, set data to null
      }
    };

    fetchAlert();
  }, [firestore, id]);
  return data; // Return the fetched data or null
};

export default useAlert;
