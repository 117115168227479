import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import React, { useContext } from 'react';
import type { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import TabPanel from '../../components/TabPanel/TabPanel';

import useLatestGuard from './hooks/useLatestGuard';
import FarmContext from '../../components/FarmProvider/FarmContext';
import ControlGuard from './ControlGuard';
import GraphGuard from './GraphGuard';
import LatestGuard from './LatestGuard';
import LatestGuardInfo from './LatestGuardInfo';
import ConfigGuard from './ConfigGuard';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import ActivityLog from '../../components/ActivityLog/ActivityLog';

type IProps = {
  deviceId: string;
};

const SideBarEntryGuard: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);
  const [value, setValue] = React.useState<any>(0);
  const latestDeviceInfo = useLatestGuard(brokerId, deviceId);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  if (!latestDeviceInfo?.timestamp)
    return (
      <Box p={2}>
        <Alert severity="info">No Latest Data for guard/{deviceId}!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={`guard/${latestDeviceInfo.deviceId}`} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor="inherit"
        variant="scrollable"
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label="Latest" value={0} />
        <Tab label="Graphs" value={1} />
        <Tab label="Control" value={2} />
        <Tab label="Config" value={3} />
        <Tab label="Activity" value={4} />
        <Tab label="Alerts" value={5} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <LatestGuardInfo latest={latestDeviceInfo} />
        <LatestGuard latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GraphGuard latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ControlGuard latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ConfigGuard latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/guard/${deviceId}/notes`}
          heading="Activity Log"
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType="guard"
        />
      </TabPanel>
    </>
  );
};

export default SideBarEntryGuard;
