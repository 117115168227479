import { Box, Alert, AlertTitle, Card } from '@mui/material';
import React from 'react';
import { FC } from 'react';
import AppDrawerTitle from '../../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../../../components/ReactJson/ReactJson';
import DeviceButton from '../../../../components/DeviceButton/DeviceButton';

import dateToString, {
  dateFormatTypes,
} from '../../../../helpers/dateToString';
import useAlert from './hooks/useAlert';

const AlertLabels = ['Critical', 'Error', 'Warning', 'Notice'];

type ISideBarEntryAlertProps = {
  alertId: string;
};

const SideBarEntryAlert: FC<ISideBarEntryAlertProps> = ({ alertId }) => {
  const alertData = useAlert(alertId);

  if (!alertData) return null;
  const { ...displayData } = alertData;

  return (
    <>
      <AppDrawerTitle title={alertData.id} />
      <Box p={2}>
        <Card>
          <Alert
            severity={
              Number(alertData.level) === 0
                ? 'error'
                : Number(alertData.level) < 3
                  ? 'warning'
                  : 'info'
            }
          >
            <AlertTitle>{AlertLabels[Number(alertData.level)]}</AlertTitle>
            {alertData.message}
          </Alert>
        </Card>
      </Box>
      <Box p={2}>
        <PaperSection
          heading="Alert Details"
          subheading={`Last Updated: ${dateToString(
            alertData.timestamp,
            dateFormatTypes.TRUNCATED,
          )}`}
        >
          <ReactJson src={displayData} expandAll={true} />
        </PaperSection>
      </Box>
      <Box p={2}>
        <DeviceButton
          brokerId={alertData.brokerId}
          deviceType={alertData.deviceType}
          deviceId={alertData.deviceId}
        />
      </Box>
    </>
  );
};

export default SideBarEntryAlert;
