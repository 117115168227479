import type { DatabaseSeason } from '@phyllome/common';
import type { FromFirebase } from '../../../../types/types';

import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import useAction, {
  type UseActionReturn,
} from '../../../../data/hooks/useAction';
import { Alert, AlertTitle, Box, Button } from '@mui/material';
import ReactJson from '../../../../components/ReactJson/ReactJson';
import useActivityLog from '../../../../data/hooks/useActivityLog';

const DebugReturn = ({ data }: { data: UseActionReturn<any> }) => {
  if (!data?.msg) return null;
  const msg = data.msg as any;
  const { count, needsUpdating } = msg.devices;
  const { deleting, adding } = msg.queue;

  return (
    <div>
      <ul>
        <li>
          {needsUpdating} out of {count} devices need updating
        </li>
        <li>{deleting} queue items deleted</li>
        <li>{adding} queue items added</li>
      </ul>
      <h3>Season Stats</h3>
      <ReactJson src={msg.seasonState} />
    </div>
  );
};

const SideBarSeasonHardwareChanges = ({
  seasonData,
}: {
  seasonData?: FromFirebase<DatabaseSeason>;
}) => {
  const processSeasonAction = useAction('seasonProcess');
  const activityLog = useActivityLog(`season/${seasonData?.id}/notes`);

  if (!seasonData) return null;
  return (
    <PaperSection heading="Hardware Changes" mt={2}>
      <Box mb={2}>
        <Alert severity="info">
          <AlertTitle>Manually run queue task generator</AlertTitle>Manually
          execute the hardware device states to push pending states to the
          queue. Do this when you have made changes to the hardware devices and
          they haven&apos;t been pushed into the queue automatically
        </Alert>
      </Box>
      <Box pt={1}>
        <Button
          variant="contained"
          onClick={() => {
            processSeasonAction.run({
              seasonId: seasonData.id,
            });

            activityLog.add('Manually executed task generator', 'OPERATION');
          }}
        >
          Manually run task generator
        </Button>
        <DebugReturn data={processSeasonAction} />
      </Box>
    </PaperSection>
  );
};

export default SideBarSeasonHardwareChanges;
