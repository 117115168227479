import { ReportProblem } from '@mui/icons-material';
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  tableCellClasses,
  styled,
  Tooltip,
  ButtonGroup,
  IconButton,
  Button,
  Box,
  Stack,
  Alert,
  AlertTitle,
} from '@mui/material';
import { colors } from '@phyllome/common';
import { FC, useState } from 'react';
import ContainerPrimary from '../../../../components/containers/ContainerPrimary/ContainerPrimary';
import { PhyconMUI } from '../../../../components/puregui/Phycon/Phycon';
import { howLongAgoInEnglish } from '../../../../helpers/FStimeAgo';
import Logs from '../Logs';
import useAction from '../../../../data/hooks/useAction';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import useNavSide from '../../../../data/hooks/useNavSide';
import { useNavigate, useParams } from 'react-router-dom';
import useControlQueue from './hooks/useControlQueue';
import ControlQueueStatus from './components/ControlQueueStatus';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colors.night,
    color: colors.fog, //
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Tasks: FC<{ brokerId: string }> = ({ brokerId }) => {
  const controlQueue = useControlQueue(brokerId);
  const controlDeleteAll = useAction('queueDeleteAll');
  const controlProcess = useAction('queueRun');
  const controlRestart = useAction('queueRestart');
  const controlDelete = useAction('queueDelete');
  const navigate = useNavigate();
  const navSide = useNavSide();
  const [selectedRow, setSelectedRow] = useState({});
  const { changeId } = useParams();

  return (
    <ContainerPrimary
      title="Logs"
      icon={<PhyconMUI icon={ReportProblem} size={120} />}
    >
      <Logs brokerId={brokerId} selected={1}>
        <Box
          sx={{
            right: '20px',
            bottom: '20px',
            position: 'fixed',
          }}
        >
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              disabled={controlProcess.status === 'RUNNING'}
              onClick={() => {
                controlDeleteAll.run({ brokerId });
              }}
            >
              Remove All
            </Button>
            <Button
              variant="contained"
              disabled={controlProcess.status === 'RUNNING'}
              onClick={() => {
                controlProcess.run({
                  brokerId,
                });
              }}
            >
              Process Queue
            </Button>
            <Button
              variant="contained"
              disabled={controlProcess.status === 'RUNNING'}
              onClick={() => {
                navigate(`/broker/${brokerId}/seasons/processing`);
              }}
            >
              Process All Seasons
            </Button>
          </Stack>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="alerts" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Timestamp</StyledTableCell>
                <StyledTableCell>Last Update</StyledTableCell>
                <StyledTableCell>Device&nbsp;Path</StyledTableCell>
                <StyledTableCell>Request</StyledTableCell>
                <StyledTableCell>Retries</StyledTableCell>
                <StyledTableCell>RID</StyledTableCell>
                <StyledTableCell>Tasks</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {controlQueue.map((task) => (
                <TableRow
                  hover
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: changeId === task.id ? '#dedede' : 'none',
                  }}
                  key={task.id}
                  onClick={() => {
                    navSide(`task/${task.id}`);
                    setSelectedRow(task.id);
                  }}
                  selected={selectedRow === task.id}
                >
                  <TableCell
                    sx={{
                      cursor: 'pointer',
                      boxSizing: 'border-box',
                      boxShadow:
                        selectedRow === task.id
                          ? `inset 3px 0px 0px 0px ${colors.budding}`
                          : 'inset 3px 0px 0px 0px transparent',
                    }}
                  >
                    <ControlQueueStatus status={task.status} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {task.timestamp && howLongAgoInEnglish(task.timestamp)} ago
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {task.lastUpdated && howLongAgoInEnglish(task.lastUpdated)}{' '}
                    ago
                  </TableCell>
                  <TableCell>
                    {task.devicePath.replace(`${task.brokerId}/`, '')}
                  </TableCell>
                  <TableCell>{task.topicId}</TableCell>
                  <TableCell>{task.retryCount}</TableCell>
                  <TableCell>{task.rid}</TableCell>
                  <TableCell>
                    <ButtonGroup variant="text" size="small">
                      {(task.status === 'queued' ||
                        task.status === 'timeout' ||
                        task.status === 'waiting') && (
                        <Tooltip title="Remove task from the queue" arrow>
                          <IconButton
                            value="remove"
                            color="earth3"
                            onClick={() => {
                              controlDelete.run({
                                id: task.id,
                              });
                            }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {task.status === 'timeout' && (
                        <Tooltip title="Retry task" arrow>
                          <IconButton
                            value="retry"
                            onClick={() => {
                              controlRestart.run({
                                id: task.id,
                              });
                            }}
                          >
                            <ReplayIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
              {controlQueue.length === 0 && (
                <TableCell colSpan={9}>
                  <Box margin={2}>
                    <Alert severity="success">
                      <AlertTitle>All done!</AlertTitle>
                      No pending hardware tasks
                    </Alert>
                  </Box>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Logs>
    </ContainerPrimary>
  );
};

export default Tasks;
