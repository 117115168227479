import { useEffect, useReducer } from 'react';
import { throttle } from 'lodash';
export type Coordinate = { x: number; y: number };

interface State {
  isMouseDown: boolean;
  hoveredRow: number;
  hoveredShelf: number;
  startRow: number;
  endRow: number;
  startShelf: number;
  endShelf: number;
  selection: Coordinate[];
  maxRows: number;
  maxShelves: number;
  count: number;
}

type Action =
  | { type: 'mouseDown'; rowX: number; shelfY: number }
  | { type: 'mouseUp'; rowX: number; shelfY: number }
  | { type: 'mouseMove'; rowX: number; shelfY: number };

const calcShelf = (shelves: number, shelfId: number) => {
  const alg = shelves - Math.floor(shelfId / 16);

  return alg;
};

const calcRow = (rowId: number) => {
  return Math.floor((rowId - 56) / 56) + 1;
};

function getCoordinatesInRange(
  coord1: Coordinate,
  coord2: Coordinate,
): Coordinate[] {
  const xStart = Math.min(coord1.x, coord2.x);
  const xEnd = Math.max(coord1.x, coord2.x);
  const yStart = Math.min(coord1.y, coord2.y);
  const yEnd = Math.max(coord1.y, coord2.y);

  const result: Coordinate[] = [];

  if (xEnd - xStart > 100) {
    throw new Error('Too many rows selected');
  }

  if (yEnd - yStart > 100) {
    throw new Error('Too many shelves selected');
  }

  for (let x = xStart; x <= xEnd; x++) {
    for (let y = yStart; y <= yEnd; y++) {
      if (x > 0 && y > 0) {
        result.push({ x, y });
      }
    }
  }
  return result;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'mouseDown': {
      const isMouseDown = state.isMouseDown === false ? true : true;
      const startRow =
        state.isMouseDown === false ? calcRow(action.rowX) : state.startRow;
      const startShelf =
        state.isMouseDown === false
          ? calcShelf(state.maxShelves, action.shelfY)
          : state.startShelf;
      const endRow = state.isMouseDown === false ? 0 : state.endRow;
      const endShelf = state.isMouseDown === false ? 0 : state.endShelf;

      return {
        ...state,
        isMouseDown,
        startRow,
        startShelf,
        endRow,
        endShelf,
      };
    }
    case 'mouseUp': {
      const count = state.isMouseDown === true ? state.count + 1 : state.count;
      const isMouseDown = state.isMouseDown === true ? false : false;
      const endRow =
        state.isMouseDown === true
          ? calcRow(action.rowX)
          : calcRow(action.rowX);
      const endShelf =
        state.isMouseDown === true
          ? calcShelf(state.maxShelves, action.shelfY)
          : calcShelf(state.maxShelves, action.shelfY);
      const selection =
        state.isMouseDown === true
          ? getCoordinatesInRange(
            { x: state.startRow, y: state.startShelf },
            { x: endRow, y: endShelf },
          )
          : getCoordinatesInRange(
            { x: state.startRow, y: state.startShelf },
            {
              x: calcRow(action.rowX),
              y: calcShelf(state.maxShelves, action.shelfY),
            },
          );

      return {
        ...state,
        isMouseDown,
        endRow,
        endShelf,
        selection,
        count,
      };
    }
    case 'mouseMove': {
      if (!state.isMouseDown) {
        return state;
      }
      const hoveredRow = calcRow(action.rowX);
      const hoveredShelf = calcShelf(state.maxShelves, action.shelfY);
      const selection = getCoordinatesInRange(
        { x: state.startRow, y: state.startShelf },
        { x: hoveredRow, y: hoveredShelf },
      );

      return {
        ...state,
        hoveredRow,
        hoveredShelf,
        selection,
      };
    }
    default:
      return state;
  }
};

const useRackSelect = (
  ref: React.MutableRefObject<any>,
  shelves: number,
  rows: number,
  disabled: boolean,
  blackList: Record<string, boolean> = {},
) => {
  const [state, dispatch] = useReducer(reducer, {
    isMouseDown: false,
    hoveredRow: 0,
    hoveredShelf: 0,
    startRow: 0,
    endRow: 0,
    startShelf: 0,
    endShelf: 0,
    selection: [],
    maxRows: rows,
    maxShelves: shelves,
    count: 0,
  });

  const bb = ref.current && ref.current.getBoundingClientRect();
  const top = (bb && bb.top) || 0;
  const left = (bb && bb.left) || 0;

  const handleMouseDown = throttle((event: MouseEvent) => {
    if (disabled) return;
    dispatch({
      type: 'mouseDown',
      rowX: event.clientX - left,
      shelfY: event.clientY - top,
    });
  }, 100);

  const handleTouchStart = throttle((event: TouchEvent) => {
    if (disabled) return;
    const { clientX: touchX, clientY: touchY } = event.touches[0];

    dispatch({ type: 'mouseDown', rowX: touchX - left, shelfY: touchY - top });
  }, 100);

  const handleTouchEnd = throttle((event: TouchEvent) => {
    if (disabled) return;
    const { clientX: touchX, clientY: touchY } = event.changedTouches[0];

    dispatch({ type: 'mouseUp', rowX: touchX - left, shelfY: touchY - top });
  }, 100);

  const handleMouseUp = throttle((event: MouseEvent) => {
    if (disabled) return;
    dispatch({
      type: 'mouseUp',
      rowX: event.clientX - left,
      shelfY: event.clientY - top,
    });
  }, 100);

  const handleTouchMove = throttle((event: TouchEvent) => {
    if (disabled) return;
    const { clientX: touchX, clientY: touchY } = event.touches[0];

    dispatch({ type: 'mouseMove', rowX: touchX - left, shelfY: touchY - top });
  }, 100);

  const handleMouseMove = throttle((event: MouseEvent) => {
    if (disabled) return;
    dispatch({
      type: 'mouseMove',
      rowX: event.clientX - left,
      shelfY: event.clientY - top,
    });
  }, 100);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('mousedown', handleMouseDown);
      node.addEventListener('touchstart', handleTouchStart);
      node.addEventListener('mouseup', handleMouseUp);
      node.addEventListener('touchend', handleTouchEnd);
      node.addEventListener('touchmove', handleTouchMove);
      node.addEventListener('mousemove', handleMouseMove);
      return () => {
        node.removeEventListener('mousedown', handleMouseDown);
        node.removeEventListener('mouseup', handleMouseUp);
        node.removeEventListener('mousemove', handleMouseMove);
        node.removeEventListener('touchmove', handleMouseMove);
        node.removeEventListener('touchstart', handleTouchStart);
        node.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [
    ref,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    handleTouchMove,
    handleTouchStart,
    handleTouchEnd,
  ]);

  const selection = state.selection.filter((item) => {
    return !blackList[`${item.y}.${item.x}`];
  });

  return { selection, count: state.count };
};

export default useRackSelect;
