import { FC } from 'react';
import { SeederDeviceState } from '@phyllome/common';
import { FromFirebase } from '../../types/types';
import FormSeederSet from './forms/FormSeederSet';
import FormSeederSetCount from './forms/FormSeederSetCount';
import LatestSeederInfo from './LatestSeederInfo';

type IProps = {
  latest: FromFirebase<SeederDeviceState>;
};

const ControlSeeder: FC<IProps> = ({ latest }) => {
  return (
    <>
      <LatestSeederInfo latest={latest} />
      <FormSeederSet latest={latest} />
      <FormSeederSetCount latest={latest} />
    </>
  );
};

export default ControlSeeder;
