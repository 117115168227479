import {
  mqttDefinitionPackagerConfigGet,
  PackagerDeviceState,
} from '@phyllome/common';
import { FromFirebase } from '../../types/types';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

const ConfigPackager = ({
  latest,
}: {
  latest: FromFirebase<PackagerDeviceState>;
}) => {
  return (
    <>
      <PaperSection heading="Packager Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">TBD</LabelValue>
        <LabelValue label="Network">
          {latest.wifi_ip} ({latest.wifi_rssi}dB)
        </LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionPackagerConfigGet}
      />
    </>
  );
};

export default ConfigPackager;
