import { Stack } from '@mui/system';
import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import { mqttDefinitionZoneEnable, ZoneDeviceState } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import { FromFirebase } from '../../../types/types';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Alert } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormZoneEnable = ({
  latest,
}: {
  latest: FromFirebase<ZoneDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionZoneEnable, deviceId);
  const activityLog = useActivityLog(`device/zone/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionZoneEnable, {
    enable: latest?.enable || false,
  });

  return (
    <PaperSection
      heading="Zone control"
      mb={2}
      subheading={mqttDefinitionZoneEnable.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              enable: data.enable,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });
          activityLog.add(
            `Set zone ${deviceId} to ${data.enable ? 'enable' : 'disable'}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({})}
      >
        <Stack spacing={2}>
          <Alert severity="info">
            Normal operating procedure is to control zone enable state via
            season control
          </Alert>
          <SwitchElement label="Enable Zone" name="enable" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormZoneEnable;
