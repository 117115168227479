import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import { Button } from '@mui/material';
import { FC, ReactNode, useContext } from 'react';

import useNavSide from '../../../data/hooks/useNavSide';

import { Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useEndpointSeasonDelete } from '../../../data/endpointHooks/endpointHooks';
import FarmContext from '../../../components/FarmProvider/FarmContext';

interface WarningBoxProps {
  children: ReactNode;
}

const WarningBox: FC<WarningBoxProps> = ({ children }) => {
  return (
    <Box p={2} margin={2} sx={{ backgroundColor: '#642222', color: 'white' }}>
      <WarningIcon
        sx={{ marginRight: '0.5rem', color: 'yellow', fontSize: '50px' }}
      />
      {children}
    </Box>
  );
};

const SeasonsSidebarDelete = ({ id }: { id: string }) => {
  const deleteSeason = useEndpointSeasonDelete();
  const { brokerId } = useContext(FarmContext);

  const navSide = useNavSide();

  return (
    <>
      <AppDrawerTitle title="Action Required" />
      <WarningBox>
        <Box>
          Are you sure you want to delete this season? You are going to lose all
          tray-data as well.
        </Box>
        <Box>This cannot be undone</Box>
        <Box pt={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              deleteSeason.run({ brokerId, id }).then(() => navSide());
            }}
          >
            {deleteSeason.status === 'RUNNING' && 'Destroying'}
            {deleteSeason.status !== 'RUNNING' && 'Delete Season'}
          </Button>{' '}
          <Button
            variant="contained"
            onClick={() => {
              navSide();
            }}
          >
            Cancel
          </Button>
        </Box>
      </WarningBox>
    </>
  );
};

export default SeasonsSidebarDelete;
