import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import { AirDeviceState, mqttDefinitionAirConfigSet } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormAirConfigSetOffset = ({
  state,
}: {
  state: FromFirebase<AirDeviceState>;
}) => {
  const { deviceId } = state;

  const controlLifecycle = useControlSend(
    mqttDefinitionAirConfigSet,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/air/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionAirConfigSet, {
    temperatureOffset: state?.temperatureOffset || 0,
  });

  return (
    <PaperSection
      mb={2}
      heading="Temperature Calibration Offset"
      subheading={mqttDefinitionAirConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              temperatureOffset: data.temperatureOffset,
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Set temperature offset config to ${data.temperatureOffset}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          temperatureOffset: yup.number(),
        })}
      >
        <Stack spacing={2}>
          <Typography variant="body1">
            Set the temperature offset against a calibrated measured reading. A
            value of -1.2 will reduce each sample by 1.2 degrees.
          </Typography>
          <TextFieldElement
            label="Temperature Offset"
            name="temperatureOffset"
            type="text"
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormAirConfigSetOffset;
