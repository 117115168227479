import { FC, useMemo } from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import FlyOut from './FlyOut';
import GraphCursor from './GraphCursor';

import { TimeSeriesData } from './useTimeSeriesPoller';
import chartTheme from './chartTheme';
import { removeDuplicates } from './helpers';
import { colors } from '@phyllome/common';
export type IVictoryChatProps = {
  color: string;
  timeSeries: TimeSeriesData;
  mapping: { [key: string]: string };
  beginDate: Date;
  endDate: Date;
};

function StateItem(props: any) {
  const { x, y } = props; // VictoryScatter supplies x, y and datum

  return (
    <rect
      x={x + 1} // Adjust x to center the rectangle
      y={y - 5} // Adjust y to center the rectangle
      width={0.5} // Width of the rectangle
      height={10} // Height of the rectangle
      fill={colors.earth1} // Set the fill color
      stroke="none" // No border
    />
  );
}

const RechartVictoryEnum: FC<IVictoryChatProps> = ({
  beginDate,
  endDate,
  color,
  timeSeries,
  mapping,
}) => {
  const mappings = useMemo(() => {
    return [' ', ...Object.values(mapping), '  '];
  }, [mapping]);

  const transformedData = useMemo(() => {
    // console.log(timeSeries.data);
    const noDups = removeDuplicates(timeSeries.data);
    const allData = timeSeries.data.map((row) => {
      return {
        x: row.x,
        y: mapping[row.y] || row.y, //
      };
    });
    // const beginDateMinus10Minutes = new Date(beginDate.getTime() - 10 * 60000);
    // const endDatePlus10Minutes = new Date(endDate.getTime() + 10 * 60000);

    if (allData.length === 0) return [];

    const firstElement = allData[0];
    const lastElement = allData[allData.length - 1];

    const addedStuff = [
      {
        x: beginDate,
        y: firstElement.y,
      },
      ...allData,
      {
        x: endDate,
        y: lastElement.y,
      },
    ];

    return addedStuff;
  }, [beginDate, endDate, mapping, timeSeries.data]);

  const height = useMemo(() => {
    return (mappings.length - 1) * 20;
  }, [mappings]);

  if (transformedData.length === 0) return null;
  return (
    <VictoryChart
      height={height || 170}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={() => ' '}
          labelComponent={
            <VictoryTooltip
              pointerLength={0}
              flyoutComponent={<FlyOut color={color} />}
            />
          }
        />
      }
      theme={chartTheme}
      scale={{ x: 'time', y: 'linear' }}
    >
      {/* Y-Axis for values */}
      <VictoryAxis />
      <VictoryChart />
      {/* spaces and double spaces are a hack
        leave this there.
      */}
      {/* <VictoryLine
        categories={{ y: mappings }}
        style={{
          data: {
            stroke: "blue",
            strokeWidth: 4.3,
            strokeOpacity: 1,
          },
        }}
        data={two}
      /> */}

      <VictoryScatter
        dataComponent={<StateItem />}
        size={(x) => {
          if (x.index === 0) return 0;
          return x.index === 0 || x.index === x.data.length - 1 ? 0 : 5;
        }}
        style={{ data: { fill: color, color } }}
        categories={{ y: mappings }}
        data={transformedData}
      />
      <GraphCursor />
    </VictoryChart>
  );
};

export default RechartVictoryEnum;
