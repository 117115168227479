import { colors } from '@phyllome/common';
import styled from 'styled-components';

interface ShelfProps {
  color: string;
  clickable: boolean;
}
export default styled.div<ShelfProps>`
  width: 100%;
  font-size: 11px;
  color: ${colors.night};
  //padding: 2px;
  height: 16px;
  margin: 0 0 6px 0;
  border-radius: 0 0 5px 5px;
  background-color: ${(props: ShelfProps) => props.color};

  cursor: ${(props: ShelfProps) => (props.clickable ? 'pointer' : 'inherit')};
  &:hover {
    opacity: ${(props: ShelfProps) => (props.clickable ? '0.7' : '1')};
  }
  .traybot {
    background: ${colors.earth2};
    height: 4px;
    position: relative;
    top: 17px;
  }

  .droplet {
    color: ${colors.river};
  }

  .power {
    color: ${colors.sunlight};
  }
`;
