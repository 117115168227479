import { useFirestore, useFirestoreCollectionData } from 'reactfire';

import { collection, orderBy, query, where } from 'firebase/firestore';
import { DeviceType } from '@phyllome/common';
import { FavouriteName } from './collectionFavourites';
export interface ICollectionCustomConsole {
  brokerId: string;
  deviceId: string;
  deviceType: DeviceType;
  heading?: string;
  name: FavouriteName;
  field: string;
  graphType: 'line' | 'state' | 'bool';
  location: 'general';
  uid: string;
  id: string;
}

const useCustomConsole = ({
  brokerId,
  uid,
  location = 'general',
}: {
  brokerId: string;
  uid: string;
  location?: string;
}): Array<ICollectionCustomConsole> => {
  const documentReference = query(
    collection(useFirestore(), 'customConsole'),
    where('brokerId', '==', brokerId),
    where('uid', '==', uid),
    where('location', '==', location),
    orderBy('order', 'asc'),
  );

  const data = useFirestoreCollectionData(documentReference, {
    idField: 'id',
    initialData: [],
  });

  if (data?.error) {
    console.error(data?.error?.toString());
  }

  const retData = data.data as Array<ICollectionCustomConsole>;

  return retData;
};

export default useCustomConsole;
