import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import {
  mqttDefinitionTransferConfigSet,
  TransferDeviceState,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { Typography } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormTransferConfigSetSpeeds = ({
  state,
}: {
  state: FromFirebase<TransferDeviceState>;
}) => {
  const { deviceId } = state;

  const controlLifecycle = useControlSend(
    mqttDefinitionTransferConfigSet,
    `${deviceId}`,
  );

  const activityLog = useActivityLog(`device/air/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTransferConfigSet,
    {
      speeds: {
        infeed: state?.speeds_infeed || 0,
        outfeed: state?.speeds_outfeed || 0,
      },
    },
  );

  return (
    <PaperSection
      mb={2}
      heading="Transfer Conveyor Speeds"
      subheading={mqttDefinitionTransferConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              speeds: {
                infeed: data?.speeds?.infeed,
                outfeed: data?.speeds?.outfeed,
              },
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Set transfer speeds to infeed: ${data.speeds.infeed}% and outfeed: ${data.speeds.outfeed}%`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          speeds: yup.object({
            infeed: yup.number().min(0).max(100).required(),
            outfeed: yup.number().min(0).max(100).required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant="body1">
            Set the default conveyor speeds for the transfer system. Infeed is
            from the hatch, outfeed is to the harvester. Speeds are in
            percentage of maximum speed.
          </Typography>
          <TextFieldElement
            label="Infeed Conveyor Speed"
            name="speeds.infeed"
            type="text"
          />
          <TextFieldElement
            label="Outfeed Conveyor Speed"
            name="speeds.outfeed"
            type="text"
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTransferConfigSetSpeeds;
