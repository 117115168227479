import { type FC, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  type TextFieldProps,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';

import { getErrorProps } from '../helpers';
import type { PhormDropDownData } from '../types';

type IProps = TextFieldProps & {
  busy?: boolean;
  label?: string;
  variant?: 'standard' | 'outlined' | 'filled' | undefined;
  onModify?: (e: unknown) => void;
  padRight?: boolean;
  options: PhormDropDownData[];
};

const PhormSelect: FC<IProps> = ({
  name = 'undefined',
  label = '',
  onModify = () => null,

  size = 'small',

  options,
  variant = 'outlined',
}) => {
  const { setValue, watch, control, formState } = useFormContext();
  const errors = formState.errors;
  const valueWatcher = watch(name);

  useEffect(() => {
    setValue(name, valueWatcher);
  }, [valueWatcher, setValue, name]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <>
            <FormControl variant={variant} fullWidth size={size}>
              <InputLabel>{label}</InputLabel>
              <Select
                value={value}
                onChange={(e) => {
                  onChange(e);
                  onModify(e.target.value);
                }}
                label={label}
                {...getErrorProps(name, errors)}
              >
                {options.map((opt) => {
                  return (
                    <MenuItem key={opt.value} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
        );
      }}
    />
  );
};

export default PhormSelect;
