import { FromFirebase } from '../../../types/types';

import { DatabaseDevices, DeviceType } from '@phyllome/common';
import useDevicesByType from './useDevicesByType';
export const includedDeviceTypes: DeviceType[] = [
  'air',
  'irrigation',
  'co2',
  'camera',
  'fertigator',
  'traybot',
  'lift',
  'lighting',
  'zone',
  'harvester',
  'sentry',
  'seeder',
  'packager',
  'guard',
  'transfer',
];

const useDevices = (brokerId: string): FromFirebase<DatabaseDevices>[] => {
  const air = useDevicesByType(brokerId, 'air');
  const irrigation = useDevicesByType(brokerId, 'irrigation');
  const co2 = useDevicesByType(brokerId, 'co2');
  const camera = useDevicesByType(brokerId, 'camera');
  const fertigator = useDevicesByType(brokerId, 'fertigator');
  const traybot = useDevicesByType(brokerId, 'traybot');
  const lift = useDevicesByType(brokerId, 'lift');
  const lighting = useDevicesByType(brokerId, 'lighting');
  const zone = useDevicesByType(brokerId, 'zone' );
  const harvester = useDevicesByType(brokerId, 'harvester');
  const sentry = useDevicesByType(brokerId, 'sentry');
  const seeder = useDevicesByType(brokerId, 'seeder');
  const packager = useDevicesByType(brokerId, 'packager');
  const guard = useDevicesByType(brokerId, 'guard');
  const transfer = useDevicesByType(brokerId, 'transfer');

  return [
    ...air,
    ...irrigation,
    ...co2,
    ...camera,
    ...fertigator,
    ...traybot,
    ...lift,
    ...lighting,
    ...zone,
    ...harvester,
    ...sentry,
    ...seeder,
    ...packager,
    ...guard,
    ...transfer,
  ];
};

export default useDevices;
