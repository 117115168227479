import {
  mqttDefinitionTraybotConfigGet,
  TraybotDeviceState,
} from '@phyllome/common';
import { FromFirebase } from '../../types/types';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../components/LabelValue/LabelValue';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormTraybotConfigSetLift from './forms/FormTraybotConfigSetLift';
import FormTraybotConfigSetDrive from './forms/FormTraybotConfigSetDrive';
import FormTraybotConfigSetOperating from './forms/FormTraybotConfigSetOperating';
import FormTraybotConfigSetPositions from './forms/FormTraybotConfigSetPositions';
import FormTraybotConfigSetSpeed from './forms/FormTraybotConfigSetSpeed';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

const ConfigTraybot = ({
  latest,
}: {
  latest: FromFirebase<TraybotDeviceState>;
}) => {
  return (
    <>
      <PaperSection heading="Traybot Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">
          {latest.network_ip} ({latest.network_rssi}dB)
        </LabelValue>
        <LabelValue label="Free memory">
          {/* {formatBytes(latest.freememory)} */}TBD
        </LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormTraybotConfigSetLift latest={latest} />
      <FormTraybotConfigSetOperating latest={latest} />
      <FormTraybotConfigSetSpeed latest={latest} />
      <FormTraybotConfigSetPositions latest={latest} />
      <FormTraybotConfigSetDrive latest={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionTraybotConfigGet}
      />
    </>
  );
};

export default ConfigTraybot;
