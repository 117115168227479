import FormPackagerReset from './forms/FormPackagerReset';
import { FromFirebase } from '../../types/types';
import LatestPackagerInfo from './LatestPackagerInfo';
import { PackagerDeviceState } from '@phyllome/common';
import GenericPing from '../Generic/control/GenericPing';

const ControlPackager = ({
  latest,
}: {
  latest: FromFirebase<PackagerDeviceState>;
}) => {
  return (
    <>
      <LatestPackagerInfo latest={latest} />
      <FormPackagerReset latest={latest} />
      <div>
        <GenericPing
          deviceId={latest.deviceId}
          brokerId={latest.brokerId}
          deviceType="packager"
        />
      </div>
    </>
  );
};

export default ControlPackager;
