import React from 'react';
import { Grid, Typography } from '@mui/material';

interface LabelValueProps {
  leftColWidth?: number;
  label: string;
  children: React.ReactNode;
  hide?: boolean;
}

const LabelValue: React.FC<LabelValueProps> = ({
  label,
  children,
  hide = false,
}) => {
  if (hide) return null;
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} paddingBottom={0.5}>
        <Typography color="textSecondary">{label}</Typography>
      </Grid>
      <Grid item xs={8} paddingBottom={0.5}>
        {children}
      </Grid>
    </Grid>
  );
};

export default LabelValue;
