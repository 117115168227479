import React from 'react';
import * as yup from 'yup';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';
import Phorm from '../../../components/Form/Components/Phorm';
import PhormSelect from '../../../components/Form/Components/PhormSelect';
import PhormSubmit from '../../../components/Form/PhormSubmit/PhormSubmit';
import PhormBlock from '../../../components/Form/Components/PhormBlock';
import PhormErrors from '../../../components/Form/Components/PhormErrors';
import useMessaging, { IMessageStages } from '../../../data/hooks/useMessaging';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';

import dateToString from '../../../helpers/dateToString';
import { Box, LinearProgress } from '@mui/material';
import Sus from '../../../components/Sus/Sus';
import { Email } from '@mui/icons-material';
import ReactJson from '../../../components/ReactJson/ReactJson';

const dd = [
  { label: 'ping', value: 'ping' },
  { label: 'trayLogAdd', value: 'trayLogAdd' },
];

const schema = yup.object().shape({
  instruction: yup.string().required(),
});

const Messaging = () => {
  const messaging = useMessaging('null');

  return (
    <ContainerPrimary title="Messaging" icon={<Email />}>
      <Sus>
        <Phorm
          defaultValues={{
            instruction: '',
          }}
          schema={schema}
          onSubmit={async () => {
            return messaging.send({
              async: true,
              topic: 'ping',
              params: {
                deviceId: '123456',
                timestampOrigin: new Date(),
                sessionId: 1234,
              },
            });
          }}
        >
          <PhormBlock>
            <PhormSelect
              name="instruction"
              label="Select Instruction"
              options={dd}
            />
          </PhormBlock>
          <PhormBlock>
            <PhormSubmit>Send</PhormSubmit>
          </PhormBlock>
          <PhormErrors />
        </Phorm>
        {messaging.stage === IMessageStages.NOTINIT && messaging.isRunning && (
          <LinearProgress />
        )}
        {messaging.stage === IMessageStages.INIT && <div>Cloud Commenced</div>}
        {messaging.stage === IMessageStages.RUN && (
          <div>Running External Process</div>
        )}
        {(messaging.stage === IMessageStages.COMPLETE ||
          messaging.stage === IMessageStages.FAILED) && (
          <PaperSection heading="Response">
            <ReactJson src={messaging.response} />
            <Box mt={2}>
              <QuickFBField
                label="Commenced"
                data={messaging.timestampCommenced}
              />
              <QuickFBField
                label="Completed"
                data={messaging.timestampCompleted}
              />
            </Box>
          </PaperSection>
        )}
      </Sus>
    </ContainerPrimary>
  );
};

const QuickFBField = (props: any) => {
  const { data, label } = props;
  const t = data && dateToString(data);

  if (!t) return null;
  return (
    <div>
      <strong>{label}: </strong>
      {t}
    </div>
  );
};

export default Messaging;
