import {
  ObservableStatus,
  useFirestore,
  useFirestoreCollectionData,
} from 'reactfire';

import { collection, where, query } from 'firebase/firestore';
import { LiftDeviceState } from '@phyllome/common';
import { FromFirebase } from '../../../types/types';

const useLatestLiftByAlias = (brokerId: string, alias: number) => {
  // get first collection where brokerId=brokerId and alias contains alias in the alias collection

  const documentReference = query(
    collection(useFirestore(), 'liftLatest'),
    where('brokerId', '==', brokerId),
    where('alias', 'array-contains', alias),
  );

  const data = useFirestoreCollectionData(documentReference, {
    initialData: [],
    idField: 'id',
  }) as ObservableStatus<FromFirebase<LiftDeviceState>[]>;

  return data?.data[0];
};

export default useLatestLiftByAlias;
