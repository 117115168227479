import Phorm from '../../../components/Form/Components/Phorm';
import PhormErrors from '../../../components/Form/Components/PhormErrors';

import PhormSubmit from '../../../components/Form/PhormSubmit/PhormSubmit';

import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';
import { Box, Alert, Stack, Divider, InputAdornment } from '@mui/material';

import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import PhormTextField from '../../../components/Form/Components/PhormTextField';

import * as yup from 'yup';
import { string, number } from 'yup';
import { useEndpointSeedInsert } from '../../../data/endpointHooks/endpointHooks';
import { useSnackbar } from 'notistack';
import NDSScheduleEdit from '../../../components/NDSScheduleEdit/NDSScheduleEdit';
import { useState } from 'react';
import { NDSSchedule } from '@phyllome/common';

const SeedsSidebar = () => {
  const insertSeed = useEndpointSeedInsert();
  const { enqueueSnackbar } = useSnackbar();

  const [irrigationSchedule, setIrrigationSchedule] = useState<NDSSchedule[]>(
    [],
  );

  return (
    <>
      <AppDrawerTitle title="Create a Seed Type" />
      <Phorm
        schema={yup.object().shape({
          name: string().required(),
          variety: string().required(),
          defaultSeedAmountPerTray: number().required(),
          pricePerMillion: number().required(),
          notes: string(),
        })}
        defaultValues={{
          timeBegin: new Date(),
        }}
        onSubmit={(values: any) => {
          insertSeed
            .run({
              defaultFertigationTargetEC: 0,
              defaultFertigationTargetPH: 0,
              defaultIrrigationSchedule: irrigationSchedule || [],
              defaultLightingSchedule: [],
              defaultSeedAmountPerTray: values.defaultSeedAmountPerTray,
              name: values.name,
              notes: values.notes || '',
              pricePerMillion: values.pricePerMillion,
              variety: values.variety,
              durationDays: values.durationDays,
            })
            .then(() => {
              enqueueSnackbar('Seed profile created', {
                variant: 'success',
              });
            })
            .catch((e) => {
              enqueueSnackbar('There was a problem saving, ' + e, {
                variant: 'error',
              });
            });
        }}
        busy={false}
      >
        <Box p={2}>
          <PaperSection heading="Seed Details">
            <Box mt={2} p={2}>
              <Stack spacing={2} direction="row" mb={2}>
                <PhormTextField name="name" label="Seed Name" size="medium" />
                <PhormTextField
                  name="variety"
                  label="Seed Variety"
                  size="medium"
                />
              </Stack>
              <Divider />
              <Stack spacing={2} mt={2} mb={2}>
                <PhormTextField
                  name="defaultSeedAmountPerTray"
                  label="Sowing Density"
                  size="medium"
                  InputProps={{ endAdornment: <InputAdornment position="end">seeds per tray</InputAdornment> }}

                />
                <PhormTextField
                  name="durationDays"
                  label="Season duration"
                  size="medium"
                  InputProps={{ endAdornment: <InputAdornment position="end">days</InputAdornment> }}

                />
                <PhormTextField
                  name="pricePerMillion"
                  label="Price per million seeds"
                  size="medium"
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}

                />
                <Alert severity="info">Note price excludes GST</Alert>
              </Stack>
              <Divider />
              <Stack spacing={2} mt={2}>
                <PhormTextField
                  fullWidth={true}
                  multiline={true}
                  minRows={3}
                  maxRows={10}
                  name="notes"
                  label="Notes"
                />
              </Stack>
            </Box>
          </PaperSection>
          <PaperSection heading="Irrigation Schedule" mt={2}>
            <NDSScheduleEdit
              scheduleDataDB={irrigationSchedule}
              onUpdate={(data) => {
                setIrrigationSchedule(data);
              }}
            />
          </PaperSection>
          <PaperSection mt={2}>
            <Stack spacing={2}>
              <PhormErrors />
              <PhormSubmit
                busy={insertSeed.status === 'RUNNING'}
                // disabled={irrigationSchedule.length === 0}
              >
                Add Seed
              </PhormSubmit>
            </Stack>
            {insertSeed.status === 'ERROR' && (
              <Box mt={2}>
                <Alert severity="error">Something went wrong</Alert>
              </Box>
            )}
          </PaperSection>
        </Box>
      </Phorm>
    </>
  );
};

export default SeedsSidebar;
