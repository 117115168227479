import useNavSide from '../../../../data/hooks/useNavSide';
import useSeed from '../../../../data/hooks/collections/useSeed';
import useZones from '../../../../data/hooks/collections/useZones';

import type { DatabaseSeason, DatabaseTraySeason } from '@phyllome/common';
import type {
  FromFirebase,
  NDSDeviceFromAction,
} from '../../../../types/types';

import {
  Button,
  Box,
  Divider,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import LabelValue from '../../../../components/LabelValue/LabelValue';
import ChipBool from '../../../../components/puregui/ChipBool/ChipBool';
import FlagIcon from '@mui/icons-material/Flag';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { colors } from '@phyllome/common';

import Date from '../../../../components/puregui/Date/Date';
import showNumber from '../../../../helpers/showNumber';
import { round, formatThousands } from '../../../../helpers/showNumber';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import TimelineView from '../../../../components/TimelineView/TimelineView';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import HeroGroup from '../../../../components/puregui/HeroItem/HeroGroup';
import ChipEnum from '../../../../components/puregui/ChipEnum/ChipEnum';
import type { ChipEnumData } from '../../../../components/puregui/ChipEnum/types';
import useTraySeasonsCurrentByColID from '../../../../data/hooks/collections/useTraySeasonsCurrentByColID';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useNDSByLocation from '../../../../devicespecific/NDS/hooks/useNDSByLocation';

const SideBarTraySeasonInfo = ({
  traySeasonData,
  seasonData,
}: {
  traySeasonData: FromFirebase<DatabaseTraySeason>;
  seasonData: FromFirebase<DatabaseSeason>;
}) => {
  const seedData = useSeed(traySeasonData?.seedId);
  const zones = useZones(traySeasonData?.brokerId);

  const NDSSingle = useNDSByLocation({
    column: traySeasonData.locationColumn,
    shelf: traySeasonData.locationShelf,
    row: traySeasonData.locationRow,
  });

  const sprinklerData = NDSSingle?.msg;

  const zoneData = zones[traySeasonData?.lightingZone];

  const navSide = useNavSide();

  const color = traySeasonData?.isComplete
    ? 'green'
    : traySeasonData?.isFlagged
      ? colors.sunlight
      : traySeasonData?.isRejected
        ? colors.earth3
        : traySeasonData?.timestampGrowthEnd
          ? colors.earth2
          : colors.night;

  const heroItems = [
    {
      label: 'Column',
      text: traySeasonData?.locationColumn.toString(),
    },
    {
      label: 'Shelf',
      text: traySeasonData?.locationShelf.toString(),
    },
    {
      label: 'Row',
      text: traySeasonData?.locationRow.toString(),
    },
  ];

  const { next, prev } = useAdjacentSeasonTrayIds({
    brokerId: traySeasonData.brokerId,
    seasonId: traySeasonData.seasonId,
    column: traySeasonData.locationColumn,
    row: traySeasonData.locationRow,
  });

  return (
    <PaperSection
      mb={2}
      heading="Info"
      headerSx={{
        background: color,
      }}
    >
      <Box mb={2} textAlign="center">
        <Grid container>
          <Grid xs>
            <Tooltip title="Previous tray">
              <IconButton
                aria-label="previous"
                disabled={!prev}
                onClick={() => {
                  if (prev) {
                    navSide(`tray/${prev}`);
                  }
                }}
              >
                <NavigateBeforeIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid xs="auto">
            <strong>{seasonData?.name || 'unknown'}</strong>
            {seasonData?.state === 'completed'
              ? ' (complete)'
              : ' (incomplete)'}
            <Button
              onClick={() => {
                navSide(`season/${seasonData?.id}`);
                //navigate(`/broker/${brokerId}/seasons/${seasonData?.id}`)
              }}
            >
              View
            </Button>
          </Grid>
          <Grid xs>
            <Tooltip title="Next tray">
              <IconButton
                disabled={!next}
                onClick={() => {
                  if (next) {
                    navSide(`tray/${next}`);
                  }
                }}
              >
                <NavigateNextIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      <Box mb={2}>
        <HeroGroup items={heroItems} />
      </Box>
      <Box mb={2}>
        <TimelineView
          duration={seasonData.durationDays}
          sowed={seasonData.traySeedEarliest}
          started={seasonData.growthStarted}
          harvested={seasonData.trayHarvestEarliest}
          ended={seasonData.growthEnded}
          paused={seasonData.state === 'paused'}
        />
      </Box>
      <Divider />
      <Box mt={2}>
        <LabelValue label="Variety">
          {seedData?.name || 'unknown'} / {seedData?.variety || 'unknown'}
          &nbsp; (seeded on&nbsp;
          <Date date={traySeasonData?.timestampSeeded} />)
        </LabelValue>
        <LabelValue label="Yield">
          {showNumber(
            traySeasonData?.yield,
            (x) => Number(x) / 1000,
            round(3),
            formatThousands,
          )}{' '}
          kg
        </LabelValue>
        <LabelValue label="Temp">
          {traySeasonData?.temperatureSurface &&
            `${traySeasonData?.temperatureSurface.toString()}°C`}
          {traySeasonData?.temperatureAmbient &&
            ` (${traySeasonData?.temperatureAmbient.toString()}°C)`}
        </LabelValue>
        <LabelValue label="Devices">
          <ChipBool
            data={zoneData?.enable && !traySeasonData?.isComplete}
            label={`Zone ${traySeasonData?.lightingZone}`}
            color="primary"
            icon={<LightbulbIcon />}
          />
          <SprinklerChipEnum sprinklerData={sprinklerData} />
        </LabelValue>
        <LabelValue label="State">
          <ChipBool
            data={traySeasonData?.isComplete}
            label="Completed"
            color="primary"
          />
          <ChipBool
            data={traySeasonData?.isEnabled && !traySeasonData?.isComplete}
            label="Enabled"
            color="primary"
          />
          <ChipBool
            data={traySeasonData?.isFlagged}
            label="Flagged"
            color="sunlight"
            icon={<FlagIcon />}
          />
          <ChipBool
            data={traySeasonData?.isRejected}
            label="Rejected"
            color="earth3"
            icon={<ThumbDownIcon />}
          />
        </LabelValue>
      </Box>
    </PaperSection>
  );
};

export default SideBarTraySeasonInfo;

const SprinklerChipEnum = ({
  sprinklerData,
}: {
  sprinklerData?: NDSDeviceFromAction | undefined;
}) => {
  const locationString = `NDS ${sprinklerData?.properties?.location_column}/${sprinklerData?.properties?.location_shelf}/${sprinklerData?.properties?.location_row}`;

  const sProps = sprinklerData?.properties;

  const showChip = sprinklerData !== undefined;
  const isIrrigating = sProps?.valve === true;

  const data: ChipEnumData = [
    {
      value: 0,
      label: 'Loading',
      icon: <CircularProgress size={18} />,
    },
    {
      value: 1,
      label: locationString,
    },
    {
      value: 2,
      label: locationString,
      color: 'primary',
      icon: <WaterDropIcon />,
    },
    {
      value: 3,
      label: locationString,
      color: 'secondary',
      icon: <WaterDropIcon />,
    },
  ];

  const value = showChip
    ? isIrrigating
      ? 3
      : sprinklerData.properties?.enable
        ? 2
        : 1
    : 0;

  return <ChipEnum data={data} value={value} />;
};

const useAdjacentSeasonTrayIds = ({
  brokerId,
  seasonId,
  column,
  row,
}: {
  brokerId: string;
  seasonId: string;
  column: number;
  row: number;
}) => {
  const seasonTrays = useTraySeasonsCurrentByColID({
    brokerId: brokerId,
    seasonId: seasonId,
    columnId: column,
  });

  const nextTrayId = seasonTrays.find(
    (tray) => tray.locationRow === row + 1,
  )?.id;
  const prevTrayId = seasonTrays.find(
    (tray) => tray.locationRow === row - 1,
  )?.id;

  const adjacentTrays = { next: nextTrayId, prev: prevTrayId };

  return adjacentTrays;
};
