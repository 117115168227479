import { Grid, Divider } from '@mui/material';
import { FC } from 'react';
import HeroItem from './HeroItem';
export type HeroItemType = {
  label: string;
  text?: string;
  sup?: string;
  color?: string;
};

type IHeroGroupProps = {
  items: Array<HeroItemType>;
};
const HeroGroup: FC<IHeroGroupProps> = ({ items }) => {
  return (
    <Grid container spacing={2} mb={2} justifyContent="center">
      {items.map((item, index) => (
        <Grid item key={index}>
          <HeroItem
            key={index}
            label={item.label}
            text={item.text}
            sup={item.sup}
            color={item.color}
          />
          {items.length > 0 && index < items.length - 1 && (
            <Divider orientation="vertical" flexItem />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default HeroGroup;
