import GenericPing from '../Generic/control/GenericPing';
import { FromFirebase } from '../../types/types';
import LatestLiftInfo from './LatestLiftInfo';
import { LiftDeviceState } from '@phyllome/common';

const ControlLift = ({ latest }: { latest: FromFirebase<LiftDeviceState> }) => {
  return (
    <>
      <LatestLiftInfo latest={latest} />
      <div>
        <GenericPing
          deviceId={latest.deviceId}
          brokerId={latest.brokerId}
          deviceType="lift"
        />
      </div>
    </>
  );
};

export default ControlLift;
