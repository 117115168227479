import { SentryDeviceState } from '@phyllome/common';
import { FromFirebase } from '../../types/types';
import GenericPing from '../Generic/control/GenericPing';
import LatestSentryInfo from './LatestSentryInfo';

const ControlSentry = ({
  latest,
}: {
  latest: FromFirebase<SentryDeviceState>;
}) => {
  return (
    <>
      <LatestSentryInfo latest={latest} />
      <div>
        <GenericPing
          deviceId={latest.deviceId}
          brokerId={latest.brokerId}
          deviceType="sentry"
        />
      </div>
    </>
  );
};

export default ControlSentry;
