function unflatten<T>(obj: Record<string, any>): T {
  const result: Record<string, any> = {};

  Object.keys(obj).forEach((key) => {
    const keys = key.split('_'); // Split the key by underscore
    let current = result; // Start at the root of the result object

    // Iterate over the keys to build nested structure
    keys.forEach((part, index) => {
      if (index === keys.length - 1) {
        // If it's the last key, assign the value
        current[part] = obj[key];
      } else {
        // Otherwise, create an empty object if it doesn't exist
        if (!current[part]) {
          current[part] = {};
        }
        // Move to the next level in the object
        current = current[part];
      }
    });
  });
  return result as T;
}
export default unflatten;
