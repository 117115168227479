import RackBox from '../../../../components/factoryui/components/RackBox';

import { useContext, useEffect, useState } from 'react';
import { SprinklerContext } from '../SprinkerContext';
import generateUniqueColors from '../../../../helpers/generateUniqueColours';
import type { NDSDeviceFromAction } from '../../../../types/types';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  data: NDSDeviceFromAction[];
  shelves: number;
};

const OverlaySpinklerWaterRate = ({
  shelves,
  data,
}: OverlayTraySimpleProps) => {
  const sprinklerContext = useContext(SprinklerContext);
  const [colourLookup, setColourLookup] = useState<Record<string, string>>({});

  useEffect(() => {
    const ww = sprinklerContext.state.waterRates;
    const cols = generateUniqueColors(ww.length, 80);
    const x = ww.reduce((acc: Record<string, any>, f, i) => {
      acc[f] = cols[i];
      return acc;
    }, {});

    setColourLookup(x);
  }, [sprinklerContext.state.waterRates]);
  return (
    <>
      {data.map((row) => {
        const { waterRate, location_row, location_shelf } = row.properties;
        const color = colourLookup[waterRate];

        return (
          <RackBox
            key={row.deviceId}
            zIndex={10}
            row={location_row}
            shelf={location_shelf}
            shelves={shelves}
            style={{
              textAlign: 'center',
              backgroundColor: color,
              color: '#555',
            }}
          >
            {waterRate}
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlaySpinklerWaterRate;
