import { colors } from '@phyllome/common';
import React, { useContext, useEffect } from 'react';
import dateToString, { dateFormatTypes } from '../../helpers/dateToString';
import { GraphCursorContext } from './ChartContext/GraphCursorContext';

const FlyOut = ({ x, y, datum, dx, dy, color }: any) => {
  const { dispatch } = useContext(GraphCursorContext);

  useEffect(() => {
    if (x === undefined) return;
    dispatch({ type: 'SET_CURSOR_POSITION', payload: x });
  }, [dispatch, x]);

  if (y === undefined || x === undefined) return null;
  const rootX = -5;
  const rootY = 0 - y - 10;

  // console.log(
  //   "DEBUG",
  //   `${dateToString(datum.x, dateFormatTypes.TRUNCATEDNOSECONDS)}`,
  //   `${datum.y}`,
  //   x - 15 + rootX,
  //   y + 30 + rootY
  // );
  return (
    <g>
      <rect
        x={x - 20 + rootX}
        y={y + 10 + rootY}
        width="50"
        dx={dx}
        dy={dy}
        height="27"
        rx="2"
        fill={colors.night}
        opacity="0.7"
      />
      <text
        x={x - 15 + rootX}
        y={y + 20 + rootY}
        fontSize="7"
        fontWeight="bold"
        fill={colors.fog}
      >
        {`${dateToString(datum.x, dateFormatTypes.TRUNCATEDNOSECONDS)}`}
      </text>
      <text
        x={x - 15 + rootX}
        y={y + 30 + rootY}
        fontSize="6"
        fill={colors.fog}
      >
        {`${datum.y}`}
      </text>
      <line x1={x} y1={28} x2={x} y2={155} stroke="#ddd" strokeWidth="1" />
      <circle cx={x} cy={y} r="2" fill={color} stroke="white" strokeWidth="1" />
    </g>
  );
};

export default FlyOut;
