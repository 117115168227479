import { FC, useMemo } from 'react';
import { DatabaseSeason, colors } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import HeroGroup from '../../../../components/puregui/HeroItem/HeroGroup';

type IProps = {
  seasonsList: FromFirebase<DatabaseSeason>[];
};

const SeasonsEntryInfo: FC<IProps> = ({ seasonsList }) => {
  const trayNumberActual = useMemo(() => {
    let count = 0;

    seasonsList.forEach((season) => {
      count +=
        season.trayNumberActual !== undefined ? season.trayNumberActual : 0;
    });
    return count;
  }, [seasonsList]);

  const trayNumberRejected = useMemo(() => {
    let count = 0;

    seasonsList.forEach((season) => {
      count +=
        season.trayNumberRejected !== undefined ? season.trayNumberRejected : 0;
    });
    return count;
  }, [seasonsList]);

  const trayNumberPaused = useMemo(() => {
    let count = 0;

    seasonsList.forEach((season) => {
      if (season.state === 'paused') {
        count +=
          season.trayNumberActual !== undefined ? season.trayNumberActual : 0;
      }
    });
    return count;
  }, [seasonsList]);

  const trayNumberStopped = useMemo(() => {
    let count = 0;

    seasonsList.forEach((season) => {
      if (season.state === 'stopped') {
        count +=
          season.trayNumberActual !== undefined ? season.trayNumberActual : 0;
      }
    });
    return count;
  }, [seasonsList]);

  const trayNumberProductive =
    trayNumberActual -
    trayNumberRejected -
    trayNumberPaused -
    trayNumberStopped;

  const heroItems = [
    {
      label: 'Total Trays',
      text: trayNumberActual.toString(),
    },
    {
      label: 'Rejected',
      text: trayNumberRejected.toString(),
      color: colors.earth3,
    },
    {
      label: 'Paused',
      text: trayNumberPaused.toString(),
      color: colors.sunlight,
    },
    {
      label: 'Stopped',
      text: trayNumberStopped.toString(),
      color: '#aaa',
    },
    {
      label: 'Productive',
      text: trayNumberProductive.toString(),
    },
  ];

  return (
    <PaperSection heading="Season Summary" headerSx={{ textAlign: 'center' }}>
      <HeroGroup items={heroItems} />
    </PaperSection>
  );
};

export default SeasonsEntryInfo;
