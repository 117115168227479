import { IrrigationDeviceState } from '@phyllome/common';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import FaultMap from '../../components/FaultMap/FaultMap';

const faultCodes = [
  'General',
  'E-Stop',
  'Valve Mode Conflict',
  'Clean Mode Conflict',
  'Tank High',
  'Tank Low',
  'Sump High',
  'Irrigation Pump',
  'Valve Timeout',
  'Supply Tank Low ',
  'Return Tank High',
  'Return Pressure High',
  'Ozone gas level High',
];

const LatestIrrigationFaults = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  return (
    <PaperSection
      mb={2}
      heading="Latest Irrigation Faults"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <FaultMap labels={faultCodes} faults={latest.faults} />
    </PaperSection>
  );
};

export default LatestIrrigationFaults;
