import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';

import { Box } from '@mui/material';
import { TransferDeviceState } from '@phyllome/common';
import CachedIcon from '@mui/icons-material/Cached';
import ExpandIcon from '@mui/icons-material/Expand';

const LatestTransferInfo = ({
  latest,
}: {
  latest: FromFirebase<TransferDeviceState>;
}) => {
  return (
    <PaperSection
      mb={2}
      heading="Latest Transfer Device Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Box mb={2}>
        <ChipBool
          data={latest.conveyors_infeed}
          label="Infeed Conveyor"
          color="primary"
          icon={<CachedIcon />}
        />
        <ChipBool
          data={latest.conveyors_outfeed}
          label="Outfeed Conveyor"
          color="primary"
          icon={<CachedIcon />}
        />
      </Box>
      <Box mb={2}>
        <ChipBool
          data={latest.actuators_sweeper_infeed}
          label="Sweeper Infeed"
          color="primary"
          icon={<ExpandIcon />}
        />
        <ChipBool
          data={latest.actuators_sweeper_outfeed}
          label="Sweeper Outfeed"
          color="primary"
          icon={<ExpandIcon />}
        />
        <ChipBool
          data={latest.actuators_roller_infeed}
          label="Roller Infeed"
          color="primary"
          icon={<ExpandIcon />}
        />
        <ChipBool
          data={latest.actuators_roller_outfeed}
          label="Roller Outfeed"
          color="primary"
          icon={<ExpandIcon />}
        />
        <ChipBool
          data={latest.actuators_door}
          label="Door Hatch"
          color="primary"
          icon={<ExpandIcon />}
        />
      </Box>
    </PaperSection>
  );
};

export default LatestTransferInfo;
