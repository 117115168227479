import { colors } from '@phyllome/common';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { rackBoxCSS } from './RackBox';

const flashStarted = {
  backgroundColor: colors.fog,
  boxShadow: '0 0 10px 5px rgba(255, 255, 255, 0.8)',
  border: '1px solid white',
};
const flashFaded = {
  backgroundColor: 'transparent',
  transition: 'all 1s ease-in-out',
  border: '1px solid transparent',
};

type IProps = {
  shelf: number;
  row: number;
  shelves: number;
  zIndex?: number;
  children?: React.ReactNode;
  onHoverStyle?: React.CSSProperties;
  track?: string;
} & HTMLAttributes<HTMLDivElement>;

const RackBoxFlash = ({
  shelf,
  row,
  shelves,
  children,
  zIndex = 10,
  style,
  onHoverStyle,
  onClick,
  track = undefined,

}: IProps) => {
  const [hover, setHover] = useState(false);
  const previousValueRef = useRef<false | string>(false);
  const [flash, setFlash] = useState<'OFF' | 'STARTED' | 'FADED'>('OFF');

  useEffect(() => {
    if (track === undefined) return;
    return;
    // Compare current value with previous value
    if (track !== previousValueRef.current && previousValueRef.current) {
      // Perform animation or other action here

      setFlash('STARTED');

      setTimeout(() => {
        setFlash('FADED');
      }, 100);

      setTimeout(() => {
        setFlash('OFF');
      }, 1200);
    }
    //previousValueRef.current = track;
    // Update previous value with current value
  }, [track, row, shelf]);

  const height = shelves * 16;

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      style={{
        ...rackBoxCSS,
        zIndex: `${zIndex}`,
        overflow: 'hidden',
        position: 'absolute',
        top: `${height - shelf * 16}px`,
        left: `${row * 56}px`,
        userSelect: 'none',
        ...style,
        ...(hover ? onHoverStyle : {}),
        ...(flash === 'OFF' && {}),
        ...(flash === 'STARTED' && flashStarted),
        ...(flash === 'FADED' && flashFaded),
      }}
    >
      {children}
    </div>
  );
};

export default RackBoxFlash;
