import { SeederDeviceState } from '@phyllome/common';

import { FC } from 'react';

import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';

import Stacker from '../../components/Stacker/Stacker';

import TimeSlider from '../../components/TimeSlider/TimeSlider';
import { FromFirebase } from '../../types/types';

//import ReChart from "../../components/Rechart/Rechart";
//import ReChartArea from "../../components/Rechart/RechartArea";

type IProps = {
  latest: FromFirebase<SeederDeviceState>;
};

const GraphSeeder: FC<IProps> = ({ latest }) => {
  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphSeeder;
