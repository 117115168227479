import { AirDeviceState, mqttDefinitionAirConfigGet } from '@phyllome/common';
import { FromFirebase } from '../../types/types';

import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import { formatBytes } from '../../helpers/formatBytes';
import FormAirConfigSetLabel from './forms/FormAirConfigSetLabel';
import FormAirConfigSetOffset from './forms/FormAirConfigSetOffset';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

const ConfigAir = ({ latest }: { latest: FromFirebase<AirDeviceState> }) => {
  return (
    <>
      <PaperSection heading="Air Monitor Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Label">{latest.label}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">
          {latest.wifi_ip} ({latest.wifi_rssi}dB)
        </LabelValue>
        <LabelValue label="Free memory">
          {formatBytes(latest.freememory)}
        </LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormAirConfigSetLabel state={latest} />
      <FormAirConfigSetOffset state={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionAirConfigGet}
      />
    </>
  );
};

export default ConfigAir;
