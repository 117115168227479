import { FC, useContext } from 'react';
import * as yup from 'yup';
import Phorm from '../../../../components/Form/Components/Phorm';
import PhormBlock from '../../../../components/Form/Components/PhormBlock';

import PhormSubmit from '../../../../components/Form/PhormSubmit/PhormSubmit';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import useSeasons from '../../../../data/hooks/collections/useSeasons';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import { PhormDropDownData } from '../../../../components/Form/types';
import PhormSelect from '../../../../components/Form/Components/PhormSelect';
import PhormErrors from '../../../../components/Form/Components/PhormErrors';

import PhormDateTime from '../../../../components/Form/Components/PhormDateTime';

import useNavSide from '../../../../data/hooks/useNavSide';

import { SelectedTrayItem } from './types';
import useAction from '../../../../data/hooks/useAction';

interface IProps {
  columnId: number;
  trays: SelectedTrayItem[];
}

const schema = yup.object({
  seasonId: yup.string().required(),
});

const SideBarRackUtilForms: FC<IProps> = ({ columnId, trays }) => {
  const farmData = useContext(FarmContext);
  const seasons = useSeasons({ brokerId: farmData.brokerId });
  const traySeasonAddTrays = useAction('traySeasonAddTrays');
  const sideNav = useNavSide();

  const ddData: PhormDropDownData[] = [
    ...seasons.map((season) => {
      return {
        label: season.name,
        value: season.id || '',
      };
    }),
  ];

  return (
    <Phorm
      defaultValues={{
        seasonId: '',
        timestampSeeded: new Date(),
      }}
      onSubmit={(values: any) => {
        traySeasonAddTrays
          .run({
            brokerId: farmData.brokerId,
            seasonId: values.seasonId,
            trays: trays.map((tray) => {
              return {
                timestampSeeded: values.timestampSeeded,
                locationColumn: Number(columnId),
                locationRow: tray.row,
                locationShelf: tray.shelf,
                trayId: `C${columnId}S${tray.shelf}R${tray.row}`,
              };
            }),
          })
          .then(() => {
            sideNav();
          });
      }}
      busy={traySeasonAddTrays.status === 'RUNNING'}
      schema={schema}
    >
      <PaperSection heading={`Add Trays to column: ${columnId}`} mb={2}>
        <PhormBlock>
          <PhormSelect
            name="seasonId"
            label="Assign trays to Season"
            options={ddData}
          />
        </PhormBlock>
        <PhormBlock>
          <PhormDateTime name="timestampSeeded" label="Time Seeded" />
        </PhormBlock>
        <PhormErrors />
        <PhormSubmit busy={traySeasonAddTrays.status === 'RUNNING'}>
          Submit
        </PhormSubmit>
      </PaperSection>
    </Phorm>
  );
};

export default SideBarRackUtilForms;
