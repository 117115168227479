import React, { FC } from 'react';
import LogoStyles from './LogoStyles';

interface IProps {
  width?: string;
  variant?: 'colored' | 'white';
  onClick?: () => void;
}

const variants = {
  colored: '/images/phyllome-logo-rgb.png',
  white: '/images/phyllome-logo-white-rgb.png',
};

const Logo: FC<IProps> = ({
  width = '100%',
  variant = 'colored',
  onClick = () => {},
}) => {
  return (
    <LogoStyles width={width}>
      <img src={variants[variant]} alt="phyllome" onClick={onClick} />
    </LogoStyles>
  );
};

export default Logo;
