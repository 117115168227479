import { Co2DeviceState } from '@phyllome/common';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { FromFirebase } from '../../types/types';
import { Box } from '@mui/material';
import { Stack } from '@mui/material';

import { mqttDefinitionCo2Clear } from '@phyllome/common';
import TankLevelWithControl from '../../components/TankLevel/TankLevelWithControl';
import FormCo2ConfigSetTanks from './forms/FormCo2ConfigSetTanks';
import useActivityLog from '../../data/hooks/useActivityLog';

const LatestCo2Levels = ({
  latest,
}: {
  latest: FromFirebase<Co2DeviceState>;
}) => {
  const level = (latest.tank / latest.tankCapacity) * 100;
  const consumed = (latest.tankCapacity - latest.tank).toLocaleString() + ' L';

  const activityLog = useActivityLog(`device/co2/${latest.deviceId}/notes`);

  const addActivityLog = (text: string) => {
    activityLog.add(text, 'OPERATION');
  };

  return (
    <>
      <PaperSection
        mb={2}
        heading="CO2 Gas tank levels"
        subheading={mqttDefinitionCo2Clear.topic}
      >
        <Box mb={4} sx={{ textAlign: 'center' }}>
          <Stack direction="row" spacing={8} justifyContent="center">
            <TankLevelWithControl
              label="CO2 Tank"
              level={level}
              subLabel={consumed}
              payload={{}}
              deviceId={latest.deviceId}
              mqttDefinitionDeviceClear={mqttDefinitionCo2Clear}
              addActivityLog={addActivityLog}
            />
          </Stack>
        </Box>
      </PaperSection>
      <FormCo2ConfigSetTanks latest={latest} />
    </>
  );
};

export default LatestCo2Levels;
