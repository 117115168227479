import { SwitchElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import { Stack } from '@mui/system';
import {
  mqttDefinitionTraybotBlower,
  TraybotDeviceState,
} from '@phyllome/common';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormTraybotControlFan = ({
  latest,
}: {
  latest: FromFirebase<TraybotDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionTraybotBlower,
    deviceId,
  );

  const activityLog = useActivityLog(`device/traybot/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTraybotBlower,
    {
      enable: latest?.rfid || false,
    },
  );

  return (
    <PaperSection
      heading="Traybot Fan Control"
      mb={2}
      subheading={mqttDefinitionTraybotBlower.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              enable: data.enable,
            },
            topicParams: {
              deviceId: latest?.deviceId,
            },
          });
          activityLog.add(`${data.enable ? 'Enabled' : 'Disabled'} fan`, 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({

        })}
      >
        <Stack spacing={2}>
          <SwitchElement
            label="Blower Fan"
            name="enable"
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTraybotControlFan;
