import { Chip } from '@mui/material';
import { colors } from '@phyllome/common';

import { FC } from 'react';

// const getAlertLevel = (level: number) => {
//   switch (Number(level)) {
//     case 0:
//       return {
//         color: colors.earth3,
//         text: 'Critical',
//       };
//     case 1:
//       return {
//         color: colors.earth2,
//         text: 'Error',
//       };
//     case 2:
//       return {
//         color: colors.earth1,
//         text: 'Warning',
//       };
//     case 3:
//       return {
//         color: colors.river,
//         text: 'Notice',
//       };

//     default:
//       return {
//         color: colors.fog,
//         text: 'Unknown',
//       };
//   }
// };

//Kirsty Pallette LOL
const getAlertLevel = (level: number) => {
  switch (Number(level)) {
    case 0:
      return {
        color: '#FF00FF',
        text: 'Critical',
      };
    case 1:
      return {
        color: '#FF69B4',
        text: 'Error',
      };
    case 2:
      return {
        color: '#F9629F',
        text: 'Warning',
      };
    case 3:
      return {
        color: '#FFC0CB',
        text: 'Notice',
      };

    default:
      return {
        color: colors.fog,
        text: 'Unknown',
      };
  }
};

const AlertLevel: FC<{ level: number }> = ({ level }) => {
  const alertLevel = getAlertLevel(level);

  return (
    <Chip
      size="small"
      label={alertLevel.text}
      sx={{ backgroundColor: alertLevel.color, color: 'white' }}
    />
  );
};

export default AlertLevel;
