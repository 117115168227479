import { SeederDeviceState } from '@phyllome/common';
import { FC } from 'react';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../components/ReactJson/ReactJson';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';

type IProps = {
  latest: FromFirebase<SeederDeviceState>;
};

const LatestSeeder: FC<IProps> = ({ latest }) => {
  return (
    <PaperSection
      heading="Latest Seeder Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ReactJson src={latest} />
    </PaperSection>
  );
};

export default LatestSeeder;
