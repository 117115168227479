import { useContext } from 'react';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import usePoller, { type UsePollerReturn } from '../../../data/hooks/usePoller';
import type {
  AnyObject,
  DeviceType,
  CloudActionGetDeviceById,
} from '@phyllome/common';

const useDeviceByIdPoller = <T extends AnyObject>({
  deviceType,
  deviceId,
}: {
  deviceType: DeviceType;
  deviceId: string;
}) => {
  const { brokerId } = useContext(FarmContext);

  const data = usePoller(
    {
      action: 'getDeviceById',
      payload: {
        deviceType,
        deviceId,
        brokerId,
      },
    },
    10,
  );

  return data as UsePollerReturn<CloudActionGetDeviceById<T>>;
};

export default useDeviceByIdPoller;
