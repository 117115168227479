import {
  FertigatorDeviceState,
  mqttDefinitionFertigatorConfigGet,
} from '@phyllome/common';
import { FromFirebase } from '../../types/types';

import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

import FormFertigatorCalibrateEC from './forms/FormFertigatorCalibrateEC';
import FormFertigatorCalibrateECProbe from './forms/FormFertigatorCalibrateECProbe';
import FormFertigatorCalibratePH from './forms/FormFertigatorCalibratePH';
import FormFertigatorCalibrateORP from './forms/FormFertigatorCalibrateORP';
import { Alert } from '@mui/material';
import FormFertigatorCalibrateTemperature from './forms/FormFertigatorCalibrateTemperature';
import FormFertigatorCalibrateDissolvedOxygen from './forms/FormFertigatorCalibrateDissolvedOxygen';

const CalibrateFertigator = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  if (!latest) return <Alert severity="info">No latest data</Alert>;
  return (
    <>
      <FormFertigatorCalibrateDissolvedOxygen latest={latest} />
      <FormFertigatorCalibrateECProbe latest={latest} />
      <FormFertigatorCalibrateEC latest={latest} />
      <FormFertigatorCalibratePH latest={latest} />
      <FormFertigatorCalibrateORP latest ={latest} />
      <FormFertigatorCalibrateTemperature latest ={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionFertigatorConfigGet}
      />
    </>
  );
};

export default CalibrateFertigator;
