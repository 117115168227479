import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavSide = () => {
  const navigation = useNavigate();
  const { pathname } = useLocation();

  const _nav = useCallback(
    (sidebarPath?: string) => {
      if (!sidebarPath) {
        // if you call this without a parameter, it removes all params
        // closing the sidebar
        navigation(pathname);
      } else {
        const path = pathname.endsWith('/')
          ? `${pathname.slice(0, -1)}/#/${sidebarPath}`
          : `${pathname}/#/${sidebarPath}`;

        navigation(path);
      }
    },
    [navigation, pathname],
  );

  return _nav;
};

export default useNavSide;
