import React, { useMemo, useRef } from 'react';
import {
  useForm,
  FormProvider,
  UseFormReturn,
  DefaultValues,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { AnyObject } from '@phyllome/common';

import { debounce, isEqual } from 'lodash';

interface IProps<T extends AnyObject> {
  defaultValues?: DefaultValues<T>;
  onSubmit?: (v: T) => void;
  onChange?: (v: T) => void;
  busy?: boolean;
  methodsRef?: React.MutableRefObject<UseFormReturn<T>>;
  schema?: ObjectSchema<any>;
  children?: React.ReactNode;
}

const Phorm = <T extends AnyObject>({
  methodsRef,
  onSubmit = () => null,
  onChange = () => null,
  defaultValues,
  children,
  busy = false,
  schema,
}: IProps<T>) => {

  const methods: UseFormReturn<T> = useForm({
    defaultValues,
    resolver: schema ? (yupResolver(schema) as any) : undefined,
  });

  React.useEffect(() => {
    if (methodsRef) {
      // eslint-disable-next-line react-compiler/react-compiler
      methodsRef.current = methods;
    }
  }, [methodsRef, methods]);

  // Memoize the debounced onChange handler
  const debouncedOnChange = useMemo(
    () =>
      debounce((values: T) => {
        onChange(values);
      }, 300), // Debounce delay in milliseconds
    [onChange],
  );

  const previousValuesRef = useRef<T>();

  React.useEffect(() => {
    const subscription = methods.watch((data) => {
      const currentData = data as T;
      const prevData = previousValuesRef.current;

      if (!isEqual(currentData, prevData)) {
        previousValuesRef.current = structuredClone(currentData);  // Deep clone to prevent reference issues
        debouncedOnChange(currentData);
      }
    });

    return () => subscription.unsubscribe();
  }, [methods, debouncedOnChange]);

  const _onSubmit = async (data: any) => {
    if (!schema) {
      onSubmit(data);
      return;
    }
    schema
      ?.validate(data, { abortEarly: false })
      .then((data: any) => {
        onSubmit(data);
      })
      .catch((fail) => {
        console.log(fail);
        fail.inner.forEach((msgItem: any) => {
          const { path, message } = msgItem;

          methods.setError(path, { type: 'manual', message });
        });
      });

    //}
  };
  const opacityStyle = busy ? { opacity: 0.5 } : { opacity: 1 };

  return (
    <FormProvider {...methods}>
      <form
        name="form"
        onSubmit={methods.handleSubmit(_onSubmit)}
        style={opacityStyle}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default Phorm;
