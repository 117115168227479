import { Skeleton } from '@mui/material';
import { Suspense } from 'react';
import { useImage } from 'react-image';

function ThisImageComponent({ url }: { url: string }) {
  const { src } = useImage({
    srcList: url,
  });

  return <img src={src} style={{ width: '100%' }} />;
}

const TrayImage = ({ src }: { src: string }) => {
  return (
    <Suspense
      fallback={
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={720}
          height={540}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#777',
          }}
        >
          Loading...
        </Skeleton>
      }
    >
      <ThisImageComponent url={src} />
    </Suspense>
  );
};

export default TrayImage;
