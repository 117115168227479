import { useContext } from 'react';

import FarmContext from '../../../../components/FarmProvider/FarmContext';

import OverlayTrayIrrigationSchedules from '../Overlays/OverlayTrayIrrigationSchedules';
import useTraySeasonsCurrentByColID from '../../../../data/hooks/collections/useTraySeasonsCurrentByColID';
import { TraysContext } from '../TraysContext';
import useNavSide from '../../../../data/hooks/useNavSide';
import useNDSLatestWithLookup from '../../../../devicespecific/NDS/hooks/useNDSLatestWithLookup';

const OverlayEntryPointIrrigationSchedules = ({
  column,
}: {
  column: number;
}) => {
  const { columnSpec, brokerId } = useContext(FarmContext);
  const data = useTraySeasonsCurrentByColID({ brokerId, columnId: column });
  const shelves = columnSpec[column - 1].shelves;
  const traysContext = useContext(TraysContext);
  const navSide = useNavSide();
  const ndsLookup = useNDSLatestWithLookup(brokerId, column);

  return (
    <>
      <OverlayTrayIrrigationSchedules
        data={data}
        ndsData={ndsLookup}
        shelves={shelves}
        onClick={(trayRowData) => {
          traysContext.dispatch({
            type: 'SET_SELECTED_TRAY',
            payload: trayRowData.id,
          });

          navSide(`tray/${trayRowData.id}`);
        }}
      />
    </>
  );
};

export default OverlayEntryPointIrrigationSchedules;
