const firebaseConfig = {
  apiKey: 'AIzaSyBFhPyqhiDnC0ilY9iO3BYmYnVrSOU0uHk',
  authDomain: 'phyllome-core.firebaseapp.com',
  projectId: 'phyllome-core',
  storageBucket: 'phyllome-core.appspot.com',
  messagingSenderId: '31153302324',
  appId: '1:31153302324:web:470e374f51c43e654f5361',
};

export default firebaseConfig;
