import {
  AirDeviceState,
  DatabaseDevices,
  FertigatorDeviceState,
  GuardDeviceState,
  HarvesterDeviceState,
  IrrigationDeviceState,
  LiftDeviceState,
  LightingDeviceState,
  TraybotDeviceState,
} from '@phyllome/common';
import { FromFirebase } from '../types/types';
export function getDeviceErrorState(
  deviceData: FromFirebase<DatabaseDevices>,
): boolean {
  switch (deviceData.deviceType) {
    case 'air': {
      const _deviceData = deviceData.properties as unknown as AirDeviceState;

      if (_deviceData.co2 === undefined) return false;
      return _deviceData.co2 > 1200;
    }

    case 'fertigator': {
      const _deviceData =
        deviceData.properties as unknown as FertigatorDeviceState;

      return _deviceData.sample_ec > 2.5 || _deviceData.sample_ph < 5;
    }

    case 'guard': {
      const _deviceData = deviceData.properties as unknown as GuardDeviceState;

      return _deviceData.alarm;
    }

    case 'lighting': {
      const _deviceData =
        deviceData.properties as unknown as LightingDeviceState;

      return _deviceData.faults > 0 || _deviceData.mode === 0;
    }

    case 'irrigation': {
      const _deviceData =
        deviceData.properties as unknown as IrrigationDeviceState;

      const faults = _deviceData.faults > 0;
      const levels_supply = _deviceData.levels_supply
        ? _deviceData.levels_supply <= _deviceData.supply_min
        : false;
      const pressures_return = _deviceData.pressures_return
        ? _deviceData.pressures_return > _deviceData.pressure_max
        : false;

      return faults || levels_supply || pressures_return;
    }

    case 'lift': {
      const _deviceData = deviceData.properties as unknown as LiftDeviceState;

      return _deviceData.inhibit || _deviceData.shelf === -1;
    }

    case 'traybot': {
      const _deviceData =
        deviceData.properties as unknown as TraybotDeviceState;

      return _deviceData.battery < 20 || _deviceData.inhibit === true;
    }

    case 'zone': {
      // const _deviceData = deviceData.properties as DatabaseZoneLatest;
      return false;
    }

    default:
      return false;
  }
}
export default getDeviceErrorState;
