import { Box } from '@mui/material';
import React, { FC } from 'react';

interface IProps {
  children: React.ReactNode;
}

const LayoutCenter: FC<IProps> = ({ children }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      {children}
    </Box>
  );
};

export default LayoutCenter;
