const parseNDSDeviceId = (
  deviceId: string,
): { column: string; shelf: string; row: string } => {
  const stuff = deviceId.split('.');

  return {
    column: String(stuff[0]),
    shelf: String(stuff[1]),
    row: String(stuff[2]),
  };
};

export default parseNDSDeviceId;
