import { SeederDeviceState } from '@phyllome/common';
import { FC } from 'react';

import { Box, IconButton } from '@mui/material';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import NatureIcon from '@mui/icons-material/Nature';
import useNavSide from '../../data/hooks/useNavSide';
import useSeed from '../../data/hooks/collections/useSeed';

type IProps = {
  latest: FromFirebase<SeederDeviceState>;
};

const LatestSeederInfo: FC<IProps> = ({ latest }) => {
  const heroItems = [
    {
      label: 'Count',
      text: latest.count === undefined ? '-' : latest.count.toString(),
    },
    {
      label: 'Target',
      text: latest.trays === undefined ? '-' : latest.trays.toString(),
    },
    {
      label: 'Density',
      text: latest.quantity === undefined ? '-' : latest.quantity.toString(),
    },
  ];

  const seed = useSeed(latest.seedId);
  const navSide = useNavSide();

  return (
    <PaperSection
      mb={2}
      heading="Latest Seeder Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Box>
        <HeroGroup items={heroItems} />
      </Box>
      <Box mb={2}>
        Variety:{' '}
        <strong>
          {seed?.name} {seed?.variety}
        </strong>
        <IconButton
          size="small"
          color="primary"
          aria-label="seed info"
          onClick={() => navSide(`seeds/${seed?.id}`)}
        >
          <NatureIcon fontSize="inherit" />
        </IconButton>
        <br />
        Batch: <strong>{latest.batch}</strong>
      </Box>
      <Box mb={2}>
        <ChipBool label="Drum" data={latest.motors_drum_enabled} />
        <ChipBool label="Conveyor" data={latest.motors_conveyor_enabled} />
        <ChipBool label="Tray Detected" data={latest.sensors_tray} />
        <ChipBool label="Seeds" data={latest.sensors_seed} color="primary" />
        <ChipBool label="Vacuum" data={latest.vacuum} />
      </Box>
    </PaperSection>
  );
};

export default LatestSeederInfo;
