import { useState, useEffect } from 'react';
import {
  query,
  collection,
  where,
  limit,
  orderBy,
  getFirestore,
  getDocs,
} from 'firebase/firestore';
import { DatabaseActivityLog } from '@phyllome/common';
import { FromFirebase } from '../../../../../types/types';

const useActivityLogs = (
  brokerId: string,
): { data: FromFirebase<DatabaseActivityLog>[] | undefined } => {
  const [activityLogs, setActivityLogs] = useState<
    FromFirebase<DatabaseActivityLog>[] | undefined
  >([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchActivityLogs = async () => {
      try {
        const db = getFirestore();
        const activityLogQuery = query(
          collection(db, 'activityLog'),
          where('brokerId', '==', brokerId),
          orderBy('timestamp', 'desc'),
          limit(1000),
        );

        const snapshot = await getDocs(activityLogQuery);
        const logsData: FromFirebase<DatabaseActivityLog>[] = snapshot.docs.map(
          (doc) => ({
            ...doc.data(),
            id: doc.id,
          }),
        ) as FromFirebase<DatabaseActivityLog>[];

        setActivityLogs(logsData);
      } catch (err) {
        setError(err as Error);
        console.error(err);
      }
    };

    if (brokerId) {
      fetchActivityLogs();
    }
  }, [brokerId]);

  if (error) {
    console.error(error.toString());
  }
  return { data: activityLogs };
};

export default useActivityLogs;
