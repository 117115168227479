import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import {
  mqttDefinitionSeederConfigSet,
  SeederDeviceState,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';

import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormSeederConfigSpeed = ({
  latest,
}: {
  latest: FromFirebase<SeederDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionSeederConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/seeder/${deviceId}/notes`);
  const defaultValues = formCreateDefaultValues(mqttDefinitionSeederConfigSet, {
    speed: latest?.speed || 0,
  });

  return (
    <PaperSection
      heading="Seeder conveyor speed"
      mb={2}
      subheading={mqttDefinitionSeederConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              speed: data.speed,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });

          activityLog.add(
            `Set seeder conveyor speed to: ${data.speed}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          speed: yup.number().min(5).max(45).required(),
        })}
      >
        <Stack spacing={2}>
          <p>
            Set the conveyor speed for the seeder device. The speed is in % of
            max speed. NOTE: in the future this will be dynamically calcualted
            based on the target density.
          </p>
          <Stack spacing={2} direction="row">
            <TextFieldElement label="Conveyor Speed" name="speed" />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormSeederConfigSpeed;
