import { Box } from '@mui/material';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';

import useCustomConsoleByID from '../../../components/Favourites/useCustomConsoleByID';
import Phorm from '../../../components/Form/Components/Phorm';
import PhormTextField from '../../../components/Form/Components/PhormTextField';
import PhormBlock from '../../../components/Form/Components/PhormBlock';
import PhormSubmit from '../../../components/Form/PhormSubmit/PhormSubmit';
import PhormErrors from '../../../components/Form/Components/PhormErrors';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import useNavSide from '../../../data/hooks/useNavSide';

const SidebarCustomConsoleGraph = ({ id }: { id: string }) => {
  const foo = useCustomConsoleByID({ id });
  const firestore = getFirestore();
  const sidenav = useNavSide();

  const handleSubmit = async (data: { heading: string }) => {
    try {
      const ref = doc(firestore, 'customConsole', id);

      await updateDoc(ref, {
        heading: data.heading,
      });
      sidenav('');
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  return (
    <>
      <AppDrawerTitle title="Edit Graph" />
      <Box p={2}>
        <PaperSection mb={2} heading="Edit">
          <Phorm
            defaultValues={{
              heading: foo?.heading || '',
            }}
            onSubmit={handleSubmit}
          >
            <PhormBlock>
              <PhormTextField name="heading" label="heading" fullWidth />
            </PhormBlock>
            <PhormBlock>
              <PhormSubmit busy={false}>Send</PhormSubmit>
            </PhormBlock>
            <PhormErrors />
          </Phorm>
        </PaperSection>
      </Box>
    </>
  );
};

export default SidebarCustomConsoleGraph;
