import { FC, useEffect, useRef, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PhormTextField from '../Form/Components/PhormTextField';
import Phorm from '../Form/Components/Phorm';
import PhormSubmit from '../Form/PhormSubmit/PhormSubmit';

// Types
type FieldConfig = {
  key: string;
  label: string;
  unit?: string;
};

type FormData = {
  [key: string]: string[];
};

type ScheduleElement = {
  [key: string]: number;
};

type Schedule = ScheduleElement[];

type ScheduleEditProps = {
  data: Schedule;
  fields: FieldConfig[];
  onSubmit: (data: Schedule) => void;
  isSaving: boolean;
  submitLabel?: string;
};

type ScheduleRowProps = {
  index: number;
  isNew?: boolean;
  onDelete: (index: number) => void;
  fields: FieldConfig[];
};

// Utility functions
const createEmptyFormData = (fields: FieldConfig[]): FormData => {
  return fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.key]: [],
    }),
    {},
  );
};

const formArrayOperations = {
  addEmptyRow: (fd: FormData | undefined, fields: FieldConfig[]): FormData => {
    if (!fd) return createEmptyFormData(fields);
    return fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]: [...(fd[field.key] || []), ''],
      }),
      {},
    );
  },

  deleteRow: (fd: FormData, index: number): FormData => {
    return Object.keys(fd).reduce(
      (acc, key) => ({
        ...acc,
        [key]: fd[key].filter((_, i) => i !== index),
      }),
      {},
    );
  },
};

const validation = {
  isEmpty: (value: string | number): boolean => {
    if (value === undefined || value === null) return true;
    return String(value).trim().length === 0;
  },

  isValidNumber: (value: string | number): boolean => {
    if (validation.isEmpty(value)) return false;
    return !isNaN(Number(String(value)));
  },

  isRowValid: (
    formData: FormData,
    index: number,
    fields: FieldConfig[],
  ): boolean => {
    return fields.every((field) =>
      validation.isValidNumber(formData[field.key][index]),
    );
  },

  ifRowEmpty: (
    formData: FormData,
    index: number,
    fields: FieldConfig[],
  ): boolean => {
    return fields.every((field) =>
      validation.isEmpty(formData[field.key][index]),
    );
  },

  isFormValid: (formData: FormData, fields: FieldConfig[]): boolean => {
    if (!formData) return false;

    const length = Object.values(formData)[0]?.length || 0;

    return Array.from({ length }).every((_, index) => {
      const isRowEmpty = fields.every((field) =>
        validation.isEmpty(formData[field.key][index]),
      );
      const isRowValid = fields.every((field) =>
        validation.isValidNumber(formData[field.key][index]),
      );

      return isRowValid || isRowEmpty;
    });
  },
};

const normalizeSchedule = (data: Schedule, fields: FieldConfig[]): FormData => {
  if (!data) return {};
  if (!data.length) {
    return formArrayOperations.addEmptyRow(undefined, fields);
  }
  return fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.key]: data.map((item) => String(item[field.key])),
    }),
    {},
  );
};

const denormalizeSchedule = (
  formData: FormData,
  fields: FieldConfig[],
): Schedule => {
  if (!formData || !Object.keys(formData).length) return [];

  const length = Object.values(formData)[0].length;

  return Array.from({ length })
    .map((_, index) => {
      const isEmpty = fields.some((field) =>
        validation.isEmpty(formData[field.key][index]),
      );

      if (isEmpty) return null;
      return fields.reduce(
        (acc, field) => ({
          ...acc,
          [field.key]: Number(formData[field.key][index]),
        }),
        {},
      );
    })
    .filter((item): item is ScheduleElement => item !== null);
};

// Components
const ScheduleRow: FC<ScheduleRowProps> = ({
  index,
  isNew = false,
  onDelete,
  fields,
}) => {
  return (
    <TableRow sx={{ background: isNew ? 'rgb(236 247 243)' : 'transparent' }}>
      {fields.map((field) => (
        <TableCell key={field.key}>
          <PhormTextField
            name={`${field.key}[${index}]`}
            type="text"
            noLabel
            InputProps={
              field.unit
                ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      {field.unit}
                    </InputAdornment>
                  ),
                }
                : undefined
            }
          />
        </TableCell>
      ))}
      <TableCell>
        {!isNew && index !== 0 && (
          <IconButton aria-label="delete" onClick={() => onDelete(index)}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const ScheduleEdit: FC<ScheduleEditProps> = ({
  data,
  fields,
  onSubmit,
  isSaving,
  submitLabel = 'Update Schedule',
}) => {
  const [isValid, setIsValid] = useState(false);
  const [formData, setFormData] = useState<FormData>();
  const ref = useRef<any>();

  useEffect(() => {
    // This only does it once.
    if (formData) return;
    setFormData(normalizeSchedule(data, fields));
  }, [data, fields, formData]);

  useEffect(() => {
    if (!formData) return;

    const formLength = Object.values(formData)[0]?.length || 0;

    const isFormValid = validation.isFormValid(formData, fields);

    setIsValid(isFormValid);

    if (!isFormValid) return;

    const allSatisfied = Array.from({ length: formLength }).every((_, index) =>
      validation.isRowValid(formData, index, fields),
    );

    const dissatisfiedCount = Array.from({ length: formLength }).filter(
      (_, index) => !validation.isRowValid(formData, index, fields),
    ).length;

    console.log({ allSatisfied, dissatisfiedCount, formLength });

    if ((allSatisfied && dissatisfiedCount === 0) || formLength === 0) {
      setFormData((fd) =>
        fd ? formArrayOperations.addEmptyRow(fd, fields) : fd,
      );
    } else if (dissatisfiedCount === 2) {
      setFormData((fd) =>
        fd ? formArrayOperations.deleteRow(fd, formLength - 1) : fd,
      );
    }

    ref.current?.reset(formData);
  }, [formData, fields]);
  return (
    <Phorm
      methodsRef={ref}
      onSubmit={(payload) => onSubmit(denormalizeSchedule(payload, fields))}
      onChange={(data) => {
        setFormData(data);
      }}
      defaultValues={formData}
    >
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {fields.map((field) => (
                <TableCell key={field.key}>{field.label}</TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({
              length: Object.values(formData || {})[0]?.length || 0,
            }).map((_, index) => (
              <ScheduleRow
                key={index}
                index={index}
                isNew={
                  index === (Object.values(formData || {})[0]?.length || 0) - 1
                }
                onDelete={(index) =>
                  setFormData((fd) =>
                    fd ? formArrayOperations.deleteRow(fd, index) : fd,
                  )
                }
                fields={fields}
              />
            ))}
          </TableBody>
        </Table>
        <PhormSubmit disabled={!isValid} busy={isSaving} sx={{ mt: 2 }}>
          {submitLabel}
        </PhormSubmit>
      </TableContainer>
    </Phorm>
  );
};

const LightingScheduleEdit: FC<{
  data: Schedule;
  onSubmit: (data: Schedule) => void;
  isSaving: boolean;
}> = (props) => {
  const fields: FieldConfig[] = [
    { key: 'day', label: 'Day' },
    { key: 'on', label: 'Duration', unit: 'min' },
    { key: 'period', label: 'Period', unit: 'min' },
  ];

  return (
    <ScheduleEdit
      {...props}
      fields={fields}
      submitLabel="Update Lighting Schedule"
    />
  );
};

const DosingScheduleEdit: FC<{
  data: Schedule;
  onSubmit: (data: Schedule) => void;
  isSaving: boolean;
}> = (props) => {
  const fields: FieldConfig[] = [
    { key: 'day', label: 'Day' },
    { key: 'dose', label: 'Dose', unit: 'ml' },
    { key: 'period', label: 'Period', unit: 'secs' },
  ];

  return (
    <ScheduleEdit
      {...props}
      fields={fields}
      submitLabel="Update Irrigation Schedule"
    />
  );
};

export { ScheduleEdit, LightingScheduleEdit, DosingScheduleEdit };
