import { useContext } from 'react';

import FarmContext from '../../../components/FarmProvider/FarmContext';

import AppDrawerTitle from '../../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';

import SeasonTrayEditor from './components/SeasonTrayEditor';

const SideBarSeasonTrayEditor = ({
  seasonId,
}: {
  seasonId: string;
  columnId: number;
  shelves: number;
  rows: number;
}) => {
  const { columnSpec, brokerId } = useContext(FarmContext);

  return (
    <>
      <AppDrawerTitle title="Assign Trays" fullscreenOnly />
      {columnSpec.map(({ shelves, rows }, key) => {
        return (
          <SeasonTrayEditor
            key={key}
            brokerId={brokerId}
            seasonId={seasonId}
            columnId={key + 1}
            shelves={shelves}
            rows={rows}
          />
        );
      })}
    </>
  );
};

export default SideBarSeasonTrayEditor;
