import { Box } from '@mui/material';
import { styled } from '@mui/system';

type IProps = {
  topLeftX: number;
  topLeftY: number;
  bottomRightX: number;
  bottomRightY: number;
  shelves: number;
  zIndex?: number;
};

const RackBoundingBox = styled(Box)(({
  topLeftX,
  topLeftY,
  bottomRightX,
  bottomRightY,
  shelves,
  zIndex = 5,
}: IProps) => {
  // please note that the vertical plane is in reverse

  const width = (bottomRightX - topLeftX + 1) * 56;
  const height = (bottomRightY - topLeftY + 1) * 16;
  const reversedTopLeftY = shelves - bottomRightY;

  return {
    width: `${width}px`,
    height: `${height}px`,
    zIndex: `${zIndex}`,

    position: 'absolute',
    top: `${reversedTopLeftY * 16}px`,
    left: `${topLeftX * 56}px`,
    userSelect: 'none',
    background: 'transparent',

    '&:hover': {},
  };
});

export default RackBoundingBox;
