import {
  TraybotDeviceState,
  MQTTPayloadTraybotState,
  colors,
} from '@phyllome/common';
import { FC, useContext } from 'react';
import FarmContext from '../../components/FarmProvider/FarmContext';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import HourglassTopSharpIcon from '@mui/icons-material/HourglassTopSharp';
import BatteryChargingFullSharpIcon from '@mui/icons-material/BatteryChargingFullSharp';
import ErrorIcon from '@mui/icons-material/Error';
import AirSharpIcon from '@mui/icons-material/AirSharp';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';

import { Box } from '@mui/material';
import TraybotBlockGroup from './components/TraybotBlockGroup';

type IProps = {
  latest: FromFirebase<TraybotDeviceState>;
};

const LatestTraybotInfo: FC<IProps> = ({ latest }) => {
  const farmConfig = useContext(FarmContext);

  const rows =
    latest.column !== undefined &&
    latest.column > 0 &&
    latest.column <= farmConfig.columnSpec.length
      ? Number(farmConfig.columnSpec[latest.column - 1].rows)
      : 0;

  const heroRowColor =
    Number(latest.location_row) > rows || Number(latest.location_row) < 0
      ? colors.earth3
      : 'black';

  const paperSectionColor =
    latest.inhibit !== undefined && latest.inhibit === true
      ? colors.earth3
      : colors.night;

  const heroItems = [
    {
      label: 'Column',
      text: (latest.location_column === undefined
        ? '-'
        : latest.location_column
      ).toString(),
    },
    {
      label: 'Shelf',
      text: (latest.location_shelf === undefined
        ? '-'
        : latest.location_shelf
      ).toString(),
    },
    {
      label: 'Row',
      text: (latest.location_row === undefined
        ? '-'
        : latest.location_row
      ).toString(),
      color: heroRowColor,
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Traybot Info"
      headerSx={{
        background: paperSectionColor,
      }}
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${
        latest.timestamp &&
        dateToString(latest.timestamp, dateFormatTypes.TRUNCATED)
      }`}
    >
      <HeroGroup items={heroItems} />
      <Box mb={2}>
        {rows > 0 && <TraybotBlockGroup latest={latest} rows={rows} />}
      </Box>
      <Box mb={2}>
        <ChipBool
          data={latest.busy}
          label="Busy"
          color="earth3"
          icon={<HourglassTopSharpIcon />}
        />
        <ChipBool
          data={Number(latest.state) === MQTTPayloadTraybotState.MOVING}
          label="Moving"
          color="primary"
        />
        <ChipBool
          data={Number(latest.state) === MQTTPayloadTraybotState.HOMING}
          label="Homing"
          color="sunlight"
        />
      </Box>
      <Box mb={2}>
        <ChipBool
          data={Number(latest.state) === MQTTPayloadTraybotState.TAKING_PHOTO}
          label="Photo"
          icon={<PhotoCameraIcon />}
        />
        <ChipBool data={latest.lift_raised} label="Raised" color="primary" />
        <ChipBool
          data={latest.charger}
          label="Charger"
          color="primary"
          icon={<BatteryChargingFullSharpIcon />}
        />
        <ChipBool data={!latest.edge} label="Edge" color="earth3" />
        <ChipBool
          data={latest.blower}
          label="Fan"
          color="primary"
          icon={<AirSharpIcon />}
        />
        <ChipBool data={latest.rfid} label="RFID" color="primary" icon={<LocationSearchingIcon />}/>
        <ChipBool
          data={latest.inhibit !== undefined && latest.inhibit === true}
          label="Inhibit"
          color="earth3"
          icon={<ErrorIcon />}
        />
        {/* <ChipBool
          data={
            Number(latest.location_row) > rows ||
            Number(latest.location_row) < 0
          }
          label="Location"
          color="earth3"
          icon={<ErrorIcon />}
        /> */}
      </Box>
    </PaperSection>
  );
};

export default LatestTraybotInfo;
