import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import { Alert, AlertTitle } from '@mui/material';
import { ZoneDeviceState } from '@phyllome/common';

const TaskZone = ({ latest }: { latest: FromFirebase<ZoneDeviceState> }) => {
  return (
    <PaperSection
      heading="Zone Tasks"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Alert severity="warning">
        <AlertTitle>Not yet Implemented</AlertTitle>This section requires a
        refactor of the useDevices to return the full data set rather than just
        the latest .properties. TODO: add Table of tasks (like alerts) with
        controls to remove
      </Alert>
    </PaperSection>
  );
};

export default TaskZone;
