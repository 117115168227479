import { FC } from 'react';

type ScaffoldLiftProps = {
  column: number;
  shelves: number;
};

const ScaffoldLift: FC<ScaffoldLiftProps> = ({ column, shelves }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '-1px',
        left: 0,
        height: `${shelves * 16 + 16}px`,
        background: '#e6e6e6',
        width: '55px',
        marginRight: '1px',
        //border: "1px solid #bbb"
      }}
    >
      <div
        style={{
          width: `${shelves * 16}px`,
          height: '55px',
          lineHeight: '55px',
          transform: 'rotate(-90deg) scale(1)',
          transformOrigin: 'top left',
          textAlign: 'center',
          top: `${shelves * 16}px`,
          position: 'absolute',
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#bbb',
        }}
      >
        COLUMN {column}
      </div>
    </div>
  );
};

export default ScaffoldLift;
