import { useContext } from 'react';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import Trays from './Trays';
import { TraysProvider } from './TraysContext';
import { useParams } from 'react-router-dom';

const TraysEntryPoint = () => {
  const { columnSpec } = useContext(FarmContext);
  const { seasonId } = useParams();

  return (
    <>
      <TraysProvider>
        <Trays columnSpec={columnSpec} seasonId={seasonId} />
      </TraysProvider>
    </>
  );
};

export default TraysEntryPoint;
