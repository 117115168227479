function identifyColorFormat(color: string): 'HEX' | 'RGB' | 'Unknown' {
  // Check if the color is in HEX format
  if (/^#(?:[0-9a-fA-F]{3}){1,2}$/.test(color)) {
    return 'HEX';
  }
  // Check if the color is in RGB format
  else if (/^rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)$/.test(color)) {
    return 'RGB';
  }
  // If neither, return "Unknown"
  return 'Unknown';
}

function rgbToHex(rgbString: string): string {
  // Modify the regex to allow spaces anywhere around RGB and within the parentheses
  const match = rgbString.match(
    /rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/i,
  );

  if (!match) {
    throw new Error('Invalid RGB format');
  }

  // Parse extracted values to integers
  const r = parseInt(match[1], 10);
  const g = parseInt(match[2], 10);
  const b = parseInt(match[3], 10);

  // Helper function to convert a single color component to a hex string
  const toHex = (colorValue: number): string => {
    const hex = colorValue.toString(16);

    return hex.length == 1 ? '0' + hex : hex;
  };

  // Convert each component and concatenate them into a hex color
  return '#' + toHex(r) + toHex(g) + toHex(b);
}

function makeColorSelected(hex: string): string {
  const cFormat = identifyColorFormat(hex);

  if (cFormat === 'Unknown') return hex;

  if (cFormat === 'RGB') {
    hex = rgbToHex(hex);
  }

  // Remove the "#" symbol if present
  hex = hex.replace('#', '');

  // Convert the hex value to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate the lighter RGB values
  const lighterR = Math.min(Math.round(r / 0.8), 255);
  const lighterG = Math.min(Math.round(g / 0.8), 255);
  const lighterB = Math.min(Math.round(b / 0.8), 255);

  // Convert the lighter RGB values back to hex
  const lighterHex = `#${lighterR.toString(16)}${lighterG.toString(
    16,
  )}${lighterB.toString(16)}`;

  return lighterHex;
}
export default makeColorSelected;
