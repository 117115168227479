import { HarvesterDeviceState } from '@phyllome/common';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import { Box } from '@mui/material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';

const LatestHarvesterInfo = ({
  latest,
}: {
  latest: FromFirebase<HarvesterDeviceState>;
}) => {
  const heroItems = [
    {
      label: 'Platform Height',
      text: latest.platform_height.toString(),
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Harvester Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <HeroGroup items={heroItems} />
      <Box mb={2}>
        <ChipBool
          data={latest.estop}
          label="Emergency Stop"
          color="earth3"
          icon={<DangerousIcon />}
          tooltip="State of emergency stop button, red if pressed"
        />
        <ChipBool
          data={latest.conveyors_tray}
          label="Conveyor Tray"
          color="primary"
        />
        <ChipBool
          data={latest.conveyors_load}
          label="Conveyor Load"
          color="primary"
        />
        <ChipBool
          data={latest.conveyors_produce}
          label="Conveyor Produce"
          color="primary"
        />
      </Box>
    </PaperSection>
  );
};

export default LatestHarvesterInfo;
