import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { FC, useContext, useState } from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';

import useSeasons from '../../../data/hooks/collections/useSeasons';
import { Box, Button, Tab, Tabs } from '@mui/material';

import FarmContext from '../../../components/FarmProvider/FarmContext';
import useNavSide from '../../../data/hooks/useNavSide';

import SeasonsTable from './SeasonsTable';
import SeasonsEntryInfo from './components/SeasonsEntryInfo';

const Seasons: FC<unknown> = () => {
  const { brokerId } = useContext(FarmContext);

  const [value, setValue] = useState(0);
  const navSide = useNavSide();

  const completedSeasons = useSeasons({
    brokerId,
    sort: 'desc',
    isComplete: true,
  });
  const incompleteSeasons = useSeasons({
    brokerId,
    sort: 'asc',
    isComplete: false,
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <ContainerPrimary title="Seasons" icon={<WbSunnyIcon />}>
      <Box mb={2}>
        <SeasonsEntryInfo seasonsList={incompleteSeasons} />
      </Box>
      <Box mb={2} textAlign="center">
        <Button variant="contained" onClick={() => navSide('seasons/create')}>
          Create Season for {brokerId}
        </Button>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Seasons Navigation"
        >
          <Tab label="Current Seasons" />
          <Tab label="Past Seasons" />
        </Tabs>
        <Box pt={2}>
          {value === 0 && <SeasonsTable seasonsList={incompleteSeasons} />}
          {value === 1 && <SeasonsTable seasonsList={completedSeasons} />}
        </Box>
      </Box>
    </ContainerPrimary>
  );
};

export default Seasons;
