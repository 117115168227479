import { DeviceType, DatabaseDevices } from '@phyllome/common';
import {
  getFirestore,
  query,
  collection,
  where,
  limit,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';

import { useState, useEffect } from 'react';
import { FromFirebase } from '../../../types/types';
import { AnyObject } from 'yup';

const useDevicesByType = <T = AnyObject>(
  brokerId: string,
  deviceType: DeviceType,
) => {
  const [devices, setDevices] = useState<FromFirebase<DatabaseDevices<T>>[]>(
    [],
  );

  useEffect(() => {
    const db = getFirestore();

    const devicesQuery = query(
      collection(db, 'devices'),
      where('deviceType', '==', deviceType),
      where('brokerId', '==', brokerId),
      orderBy('lastAccessed', 'desc'),
      limit(1000),
    );

    const unsubscribe = onSnapshot(
      devicesQuery,
      (snapshot) => {
        const devicesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as FromFirebase<DatabaseDevices<T>>[];

        // Sort by deviceId
        devicesList.sort((a, b) => (a.deviceId > b.deviceId ? 1 : -1));

        setDevices(devicesList);
      },
      (err) => {
        console.error('Error fetching devices:', err);
      },
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [brokerId, deviceType]); // Dependencies array
  return devices;
};

export default useDevicesByType;
