import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';

import Stacker from '../../components/Stacker/Stacker';

import TimeSlider from '../../components/TimeSlider/TimeSlider';
import { FromFirebase } from '../../types/types';
import Graph from '../../components/Favourites/Graph';
import { IrrigationDeviceState } from '@phyllome/common';

const GraphIrrigation = ({
  latest,
}: {
  latest: FromFirebase<IrrigationDeviceState>;
}) => {
  const { deviceId, brokerId } = latest;

  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker>
        {/* Only display a graph when there is recent data.
        (latest.X != undefined check)
        As the nursery and growroom have different datasets, this will
        nsure that only the graphs that are relevant to each controller are loaded */}

        {latest.pressures_supply !== undefined && (
          <Graph
            name="irrigPressuresSupply"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pressures_return !== undefined && (
          <Graph
            name="irrigPressuresReturn"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.levels_supply !== undefined && (
          <Graph
            name="irrigLevelsSupply"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.levels_sump !== undefined && (
          <Graph
            name="irrigLevelsSump"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.levels_return !== undefined && (
          <Graph
            name="irrigLevelsReturn"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.ozone_generator !== undefined && (
          <Graph
            name="irrigOzoneGenerator"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pumps_fertigation !== undefined && (
          <Graph
            name="irrigPumpsFertigation"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pumps_sump !== undefined && (
          <Graph
            name="irrigPumpsSump"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pumps_supply !== undefined && (
          <Graph
            name="irrigPumpsSupply"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.pumps_transfer !== undefined && (
          <Graph
            name="irrigPumpsTransfer"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves_ozone !== undefined && (
          <Graph
            name="irrigValvesOzone"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves_discharge !== undefined && (
          <Graph
            name="irrigValvesDischarge"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves_transfer !== undefined && (
          <Graph
            name="irrigValvesTransfer"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves_mains !== undefined && (
          <Graph
            name="irrigValvesMains"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves_return !== undefined && (
          <Graph
            name="irrigValvesReturn"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.valves_supply !== undefined && (
          <Graph
            name="irrigValvesSupply"
            brokerId={brokerId}
            deviceId={deviceId}
          />
        )}
        {latest.mode !== undefined && (
          <Graph name="irrigMode" brokerId={brokerId} deviceId={deviceId} />
        )}
      </Stacker>
    </ChartProvider>
  );
};

export default GraphIrrigation;
