import React, { Dispatch, FC, ReactNode, useReducer } from 'react';

type GraphCursorState = {
  cursorPosition: number | null;
};

const initialValues: GraphCursorState = {
  cursorPosition: null,
};

export const GraphCursorContext = React.createContext<{
  state: GraphCursorState;
  dispatch: Dispatch<any>;
}>({ state: initialValues, dispatch: () => null });

// TODO: these are not type safe
function graphCursorContextReducer(
  state: GraphCursorState,
  action: any,
): GraphCursorState {
  switch (action.type) {
    case 'SET_CURSOR_POSITION': {
      return { ...state, cursorPosition: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
export const GraphCursorProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    graphCursorContextReducer,
    initialValues,
  );

  return (
    <GraphCursorContext.Provider value={{ state, dispatch }}>
      <div>{children}</div>
    </GraphCursorContext.Provider>
  );
};
