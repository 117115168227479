import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import { mqttDefinitionNDSConfigSet } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';

import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import type { NDSDeviceFromAction } from '../../../types/types';
import parseNDSDeviceId from '../../../helpers/parseNDSDeviceId';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormNDSConfigSetWithDeviceNightFactor = ({
  deviceState,
}: {
  deviceState: NDSDeviceFromAction;
}) => {
  const controlLifecycle = useControlSend(
    mqttDefinitionNDSConfigSet,
    `${deviceState.deviceId}`,
  );

  const {
    nightOffFactor,
    dayStart_hour,
    dayStart_minute,
    dayEnd_hour,
    dayEnd_minute,
  } = deviceState.properties;

  const defaultValues = formCreateDefaultValues(mqttDefinitionNDSConfigSet, {
    nightOffFactor: nightOffFactor || 0,
    dayStart: {
      hour: dayStart_hour || 0,
      minute: dayStart_minute || 0,
    },
    dayEnd: {
      hour: dayEnd_hour || 0,
      minute: dayEnd_minute || 0,
    },
  });

  const { column, row, shelf } = parseNDSDeviceId(deviceState.deviceId);

  return (
    <PaperSection
      heading="NDS Night Factor Configuration"
      mb={2}
      subheading={mqttDefinitionNDSConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              nightOffFactor: data.nightOffFactor,
              dayStart: data.dayStart,
              dayEnd: data.dayEnd,
            },
            topicParams: {
              column,
              row,
              shelf,
            },
          });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          nightOffFactor: yup.number().min(0).max(10).required(),
          dayStartHour: yup.number().min(0).max(23).required(),
          dayStartMinute: yup.number().min(0).max(60).required(),
          dayEndHour: yup.number().min(0).max(23).required(),
          dayEndMinute: yup.number().min(0).max(60).required(),
        })}
      >
        <Stack spacing={2}>
          <p>
            During night time, the interval period between doses is multiplied
            by the nighttime interval factor
          </p>
          <TextFieldElement
            label="Nighttime interval factor"
            name="nightOffFactor"
            required
          />
          <p>
            Day time setting (in UMT), the night off factor applies{' '}
            <em>outside</em> of these hours
          </p>
          <Stack spacing={2} direction="row">
            <TextFieldElement
              label="Day Start Hour"
              name="dayStart.hour"
              required
            />
            <TextFieldElement
              label="Day Start Minute"
              name="dayStart.minute"
              required
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextFieldElement
              label="Day End Hour"
              name="dayEnd.hour"
              required
            />
            <TextFieldElement
              label="Day End Minute"
              name="dayEnd.minute"
              required
            />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormNDSConfigSetWithDeviceNightFactor;
