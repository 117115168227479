import type { CloudActionGetNDSByColumn } from '@phyllome/common';
import usePoller, { type UsePollerReturn } from '../../../data/hooks/usePoller';
export type UseNDSLatestReturn = UsePollerReturn<CloudActionGetNDSByColumn>;

const useNDSLatestByColumnPoller = (
  brokerId: string,
  column: number,
): UsePollerReturn<CloudActionGetNDSByColumn> => {
  // const [data, setData] = useState<PollerReturnType>();

  const ndsState = usePoller<CloudActionGetNDSByColumn>(
    {
      action: 'getNDSByColumn',
      payload: {
        brokerId,
        column: column,
      },
    },
    30,
    [],
  );

  return ndsState;
};

export default useNDSLatestByColumnPoller;
