import {
  faFile,
  faMicrochip,
  faSun,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { deviceTypeMap } from '../../../components/DeviceTypeToIcon/DeviceTypeToIcon';
import { PhyconFA } from '../../../components/puregui/Phycon/Phycon';

const stringToIconMap: { [key: string]: IconDefinition } = {
  ...deviceTypeMap,
  page: faFile,
  season: faSun,
};

const StringToIcon = ({ data }: { data: string }) => {
  if (stringToIconMap[data]) {
    return (
      <PhyconFA icon={stringToIconMap[data]} color="#000000" noMargin={true} />
    );
  } else {
    return <PhyconFA icon={faMicrochip} color="#000000" noMargin={true} />;
  }
};

export default StringToIcon;
