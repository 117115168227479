import { HarvesterDeviceState } from '@phyllome/common';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../components/ReactJson/ReactJson';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';

const LatestHarvester = ({
  latest,
}: {
  latest: FromFirebase<HarvesterDeviceState>;
}) => {
  return (
    <PaperSection
      heading="Latest Harvester Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ReactJson src={latest} />
    </PaperSection>
  );
};

export default LatestHarvester;
