import { ToggleButtonGroup, ToggleButton, styled } from '@mui/material';
import { useContext, useState } from 'react';
import { SprinklerContext } from './SprinkerContext';
import { colors } from '@phyllome/common';

const CustomToggle = styled(ToggleButton)({
  '&.Mui-selected': {
    background: colors.ripening,
    color: 'white',
  },
  '&.Mui-selected:hover': {
    background: colors.ripening,
    color: 'white',
  },
  '&.MuiToggleButton-root': {
    border: '1px solid #aaa',
  },
});

const Filters = () => {
  const [formats, setFormats] = useState(() => ['SIMPLE']);
  const [secondaryFormats, setSecondaryFormats] = useState(() => []);
  const sContext = useContext(SprinklerContext);

  const handlePrimaryFormat = (event: any, newFormats: any) => {
    setFormats(newFormats);
    sContext.dispatch({ type: 'SET_PRIMARY_LAYER', payload: newFormats });
  };

  const handleSecondaryFormat = (event: any, newFormats: any) => {
    setSecondaryFormats(newFormats);

    sContext.dispatch({ type: 'SET_SECONDARY_LAYER', payload: newFormats });
  };

  return (
    <>
      <ToggleButtonGroup
        exclusive
        value={formats}
        onChange={handlePrimaryFormat}
        aria-label="text formatting"
        color="primary"
        size="small"
        sx={{ marginRight: '4px' }}
      >
        <CustomToggle value="SIMPLE" aria-label="bold">
          Standard
        </CustomToggle>
        <CustomToggle value="FIRMWARE" aria-label="italic">
          Firmware
        </CustomToggle>
        <CustomToggle value="WATERRATE" aria-label="italic">
          Water Rates
        </CustomToggle>
        <CustomToggle value="NIGHTOFFFACTOR" aria-label="italic">
          Night Off Factor
        </CustomToggle>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        value={secondaryFormats}
        onChange={handleSecondaryFormat}
        aria-label="text formatting"
        color="primary"
        size="small"
      >
        <CustomToggle value="VALVES" aria-label="bold">
          Valves
        </CustomToggle>
        <CustomToggle value="ENABLED" aria-label="italic">
          Enabled
        </CustomToggle>
      </ToggleButtonGroup>
    </>
  );
};

export default Filters;
