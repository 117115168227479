import { FertigatorDeviceState } from '@phyllome/common';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import HeroGroup, {
  HeroItemType,
} from '../../components/puregui/HeroItem/HeroGroup';
import { Box } from '@mui/material';

const LatestFertigatorState = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const heroItems: HeroItemType[] = [
    {
      label: 'EC',
      text: (Math.round(Number(latest.sample_ec * 10)) / 10).toString(),
      sup: 'mS/cm',
    },
    {
      label: 'Ph',
      text: (Math.round(Number(latest.sample_ph * 10)) / 10).toString(),
      sup: ' ', // space required to keep vertical alignment
    },
    {
      label: 'Temperature',
      text: (
        Math.round(Number(latest.sample_temperature * 10)) / 10
      ).toString(),
      sup: '\u00b0C',
    },
    {
      label: 'Oxygen',
      text: (Math.round(Number(latest.sample_do * 10)) / 10).toString(),
      sup: 'mg/L',
    },
    {
      label: 'ORP',
      text: (Math.round(Number(latest.sample_orp))).toString(),
      sup: 'mV',
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Fertigator Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Box
        mb={4}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center',
        }}
      >
        <HeroGroup items={heroItems} />
      </Box>
      <Box mb="2">
        <ChipBool data={latest.ec_enable} label="EC enabled" color="primary" />
        <ChipBool data={latest.acid_enable} label="Acid enabled" color="primary" />
        <ChipBool
          data={latest.peroxide_enable}
          label="Peroxide enabled"
          color="primary"
        /><ChipBool
          data={latest.pump_enable}
          label="Pump enabled"
          color="primary"
        />
      </Box>
    </PaperSection>
  );
};

export default LatestFertigatorState;
