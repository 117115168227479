import { Chip, CircularProgress, Tooltip } from '@mui/material';

import { FC } from 'react';
import { FromFirebase } from '../../../types/types';
import { DatabaseSeason, colors } from '@phyllome/common';
import Pulse from '../../Pulse/Pulse';
import { Stack } from '@mui/system';

const getChipColor = (state: FromFirebase<DatabaseSeason>['state']) => {
  switch (state) {
    case 'seeded':
      return { background: colors.earth1, text: 'white' };
    case 'growing':
      return { background: colors.budding, text: 'white' };
    case 'completed':
      return { background: colors.fog, text: 'black' };
    case 'paused':
      return { background: colors.sunlight, text: 'black' };
    case 'stopped':
      return { background: colors.earth3, text: 'white' };
    default:
      return { background: colors.fog, text: 'black' };
  }
};

const isStateUpdating = (state: FromFirebase<DatabaseSeason>['state']) => {
  return (
    state === 'pausing' ||
    state === 'resuming' ||
    state === 'stopping' ||
    state === 'completing'
  );
};

type ISeasonStateProps = {
  state: FromFirebase<DatabaseSeason>['state'];
  desiredState: FromFirebase<DatabaseSeason>['desiredState'];
  totalDevices: FromFirebase<DatabaseSeason>['totalDevices'];
  totalDevicesSatisfied: FromFirebase<DatabaseSeason>['totalDevicesSatisfied'];
};

const SeasonState: FC<ISeasonStateProps> = ({
  state,
  totalDevices,
  totalDevicesSatisfied,
}) => {
  const colors = getChipColor(state);

  const label =
    totalDevicesSatisfied !== totalDevices
      ? `${state} (${totalDevicesSatisfied}/${totalDevices})`
      : null;

  return (
    <>
      <Pulse disabled={!isStateUpdating(state)}>
        <Tooltip title={label} arrow placement="right">
          <Stack direction="row" spacing={1}>
            <Chip
              size="small"
              label={state}
              sx={{ backgroundColor: colors.background, color: colors.text }}
            />
            {totalDevicesSatisfied !== totalDevices && (
              <CircularProgress
                variant="determinate"
                size={22}
                value={(totalDevicesSatisfied / totalDevices) * 100}
              />
            )}
          </Stack>
        </Tooltip>
      </Pulse>
      {/* {desiredState && desiredState !== state && desiredState !== "created" && (
        <Chip
          size="small"
          label={`desired: ${desiredState}`}
          sx={{ backgroundColor: color2.background, color: color2.text }}
        />
      )} */}
    </>
  );
};

export default SeasonState;
