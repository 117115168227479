import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import type { FromFirebase } from '../../types/types';
import ChipBool from '../../components/puregui/ChipBool/ChipBool';
import HeroGroup from '../../components/puregui/HeroItem/HeroGroup';
import { Box } from '@mui/material';
import { ZoneDeviceState } from '@phyllome/common';

const LatestZoneInfo = ({
  latest,
}: {
  latest: FromFirebase<ZoneDeviceState>;
}) => {
  const heroItems = [
    {
      label: 'Estimated Energy Consumption',
      text: (latest?.energy || 0).toFixed(0).toString(),
      sup: 'Wh',
    },
  ];

  return (
    <PaperSection
      mb={2}
      heading="Latest Zone Info"
      sx={{ textAlign: 'center' }}
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <Box mb={2}>
        <HeroGroup items={heroItems} />
      </Box>
      <Box mb={2}>
        <ChipBool data={latest.enable} label="Enabled" color="primary" />
        <ChipBool data={latest.active} label="Active" color="primary" />
      </Box>
    </PaperSection>
  );
};

export default LatestZoneInfo;
