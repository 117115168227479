import { SentryDeviceState } from '@phyllome/common';

import { FC } from 'react';

import { ChartProvider } from '../../components/Rechart/ChartContext/ChartContext';

import Stacker from '../../components/Stacker/Stacker';

import TimeSlider from '../../components/TimeSlider/TimeSlider';
import { FromFirebase } from '../../types/types';

type IProps = {
  latest: FromFirebase<SentryDeviceState>;
};

const GraphSentry: FC<IProps> = ({ latest }) => {
  //freememory, mode, control, ppm
  return (
    <ChartProvider>
      <TimeSlider />
      <Stacker></Stacker>
    </ChartProvider>
  );
};

export default GraphSentry;
