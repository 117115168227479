import { styled } from '@mui/system';

type IProps = {
  shelves: number;
  rows: number;
  zIndex?: number;
};

const ScaffoldRackContainer = styled('div')(
  ({ shelves, rows, zIndex = 0 }: IProps) => ({
    position: 'relative',
    height: `${16 * shelves + 16}px`,
    width: `${56 * (rows + 1)}px`,
    zIndex: zIndex,
  }),
);

export default ScaffoldRackContainer;
