import RackBox from '../../../../components/factoryui/components/RackBox';
import type { NDSDeviceFromAction } from '../../../../types/types';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  data: NDSDeviceFromAction[];
  shelves: number;
  selectedTray?: string;
  selectedSeason?: string;
};

const OverlaySprinklerEnabled = ({ shelves, data }: OverlayTraySimpleProps) => {
  return (
    <>
      {data.map((row) => {
        const { enable, location_row, location_shelf } = row.properties;

        return (
          <RackBox
            key={row.deviceId}
            zIndex={11}
            row={location_row}
            shelf={location_shelf}
            shelves={shelves}
            style={{
              background: enable
                ? 'rgba(255,255,255,0)'
                : 'rgba(255,255,255,0.6)',
            }}
          />
        );
      })}
    </>
  );
};

export default OverlaySprinklerEnabled;
