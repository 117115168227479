import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';

import * as yup from 'yup';

import {
  FertigatorDeviceState,
  mqttDefinitionFertigatorConfigSet,
} from '@phyllome/common';

import PhormContainer from '../../../forms/PhormContainer';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import { Typography } from '@mui/material';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormFertigatorConfigSetDosing = ({
  latest,
}: {
  latest: FromFirebase<FertigatorDeviceState>;
}) => {
  const deviceId = latest.deviceId;

  const controlLifecycle = useControlSend(
    mqttDefinitionFertigatorConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/fertigator/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionFertigatorConfigSet,
    {
      dosers: {
        ec: {
          a: latest.dosers_ec_a || 0,
          b: latest.dosers_ec_b || 0,
          c: latest.dosers_ec_c || 0,
        },
        acid: {
          a: latest.dosers_acid_a || 0,
          b: latest.dosers_acid_b || 0,
          c: latest.dosers_acid_c || 0,
        },
        base: {
          a: latest.dosers_base_a || 0,
          b: latest.dosers_base_b || 0,
          c: latest.dosers_base_c || 0,
        },
      },
    },
  );

  return (
    <PaperSection
      heading="Dosing quadratic"
      mb={2}
      subheading={mqttDefinitionFertigatorConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              dose: {
                ec: {
                  a: data?.dosers.ec?.a || 0,
                  b: data?.dosers.ec?.b || 0,
                  c: data?.dosers.ec?.c || 0,
                },
                acid: {
                  a: data?.dosers.acid?.a || 0,
                  b: data?.dosers.acid?.b || 0,
                  c: data?.dosers.acid?.c || 0,
                },
                base: {
                  a: data?.dosers.base?.a || 0,
                  b: data?.dosers.base?.b || 0,
                  c: data?.dosers.base?.c || 0,
                },
              },
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            'Updated device dosing quadratic configuration',
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          dosers: yup.object({
            ec: yup.object({
              a: yup.number().required(),
              b: yup.number().required(),
              c: yup.number().required(),
            }),
            acid: yup.object({
              a: yup.number().required(),
              b: yup.number().required(),
              c: yup.number().required(),
            }),
            base: yup.object({
              a: yup.number().required(),
              b: yup.number().required(),
              c: yup.number().required(),
            }),
          }),
        })}
      >
        <Stack spacing={2}>
          <Typography variant="h6">Dosing EC quadratic</Typography>
          <Typography variant="body1">ax2 + bx + c</Typography>
          <Stack spacing={2} direction="row">
            <TextFieldElement label="Dose EC A" name="dosers.ec.a" />
            <TextFieldElement label="Dose EC B" name="dosers.ec.b" />
            <TextFieldElement label="Dose EC C" name="dosers.ec.c" />
          </Stack>
          <Typography variant="h6">Dosing Acid quadratic</Typography>
          <Typography variant="body1">ax2 + bx + c</Typography>
          <Stack spacing={2} direction="row">
            <TextFieldElement label="Dose Acid A" name="dosers.acid.a" />
            <TextFieldElement label="Dose Acid B" name="dosers.acid.b" />
            <TextFieldElement label="Dose Acid C" name="dosers.acid.c" />
          </Stack>
          <Typography variant="h6">Dosing Base quadratic</Typography>
          <Typography variant="body1">ax2 + bx + c</Typography>
          <Stack spacing={2} direction="row">
            <TextFieldElement label="Dose Base A" name="dosers.base.a" />
            <TextFieldElement label="Dose Base B" name="dosers.base.b" />
            <TextFieldElement label="Dose Base C" name="dosers.base.c" />
          </Stack>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormFertigatorConfigSetDosing;
