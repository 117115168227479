import { Box, Modal } from '@mui/material';
import { colors } from '@phyllome/common';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#ffffff',
  boxShadow: 24,
};

type ModalAddSeasonProps = {
  children: React.ReactNode;
  isOpened: boolean;
  title: string;
  onClose?: () => void;
};

const ModalCustom = ({
  children,
  isOpened,
  onClose = () => undefined,
  title = 'Undefined',
}: ModalAddSeasonProps) => {
  return (
    <Modal open={isOpened}>
      <>
        <Box sx={style}>
          <Box
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: '7px',
              left: '4px',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            <CloseIcon />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 1,
              background: colors.earth3,
              color: 'white',
            }}
          >
            {title}
          </Box>
          <Box p={2}>{children}</Box>
        </Box>
      </>
    </Modal>
  );
};

export default ModalCustom;
