import {
  LightingDeviceState,
  mqttDefinitionLightingConfigGet,
} from '@phyllome/common';
import { FromFirebase } from '../../types/types';
import FormLightingConfigSet from './forms/FormLightingConfigSet';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import FormAnyDeviceConfigGet from '../../components/FormAnyDeviceConfigGet/FormAnyDeviceConfigGet';

const ConfigLighting = ({
  latest,
}: {
  latest: FromFirebase<LightingDeviceState>;
}) => {
  return (
    <>
      <PaperSection heading="Lighting Controller Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Firmware">{latest.firmware}</LabelValue>
        <LabelValue label="Build date">{latest.compiled}</LabelValue>
        <LabelValue label="Network IP">{latest.network_ip}</LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
      <FormLightingConfigSet latest={latest} />
      <FormAnyDeviceConfigGet
        deviceId={latest.deviceId}
        definition={mqttDefinitionLightingConfigGet}
      />
    </>
  );
};

export default ConfigLighting;
