import { DatabaseTraySeason } from '@phyllome/common';
import { FromFirebase } from '../types/types';

const createHashedListFromTraySeason = (
  traySeasons: FromFirebase<DatabaseTraySeason>[],
) => {
  return traySeasons.reduce<Record<string, boolean>>((obj, tray) => {
    const key = `${tray.locationShelf}.${tray.locationRow}`;

    obj[key] = true;
    return obj;
  }, {});
};

export default createHashedListFromTraySeason;
