import PhormContainer from '../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../data/hooks/useControlSend';
import { Location, PubSubDefinitionAny } from '@phyllome/common';
import SubmitControl from '../../forms/SubmitControl';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { Box } from '@mui/material';
import React from 'react';

type IFormAnyDeviceConfigGetProps = {
  definition: PubSubDefinitionAny;
  location: Location;
};

const FormNDSDeviceConfigGet = ({
  location,
  definition,
}: IFormAnyDeviceConfigGetProps) => {

  const controlLifecycle = useControlSend(definition, JSON.stringify(location));

  return (
    <Box>
      <PhormContainer
        defaultValues={{}}
        onSubmit={() => {
          controlLifecycle.run({
            payload: {},
            topicParams: {
              column: location.column,
              shelf: location.shelf,
              row: location.row,
            },
          });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({})}
      >
        <SubmitControl
          controlHook={controlLifecycle}
          variant="outlined"
          icon={<BrowserUpdatedIcon />}
        >
          Get Latest Config
        </SubmitControl>
      </PhormContainer>
    </Box>
  );
};

export default FormNDSDeviceConfigGet;
