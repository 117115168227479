import { styled } from '@mui/system';

type IProps = {
  shelf: number;
  row: number;
  shelves: number;
};

const RackPosition = styled('div')(({ shelf, row, shelves }: IProps) => {
  const height = shelves * 16;

  return {
    position: 'absolute',
    top: `${height - shelf * 16}px`,
    left: `${row * 56}px`,
    userSelect: 'none',
  };
});

export default RackPosition;
