import { FC, useState } from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';

import {
  Paper,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from '@mui/material';

import useNavSide from '../../../data/hooks/useNavSide';
import NatureIcon from '@mui/icons-material/Nature';
import useSeeds from '../../../data/hooks/collections/useSeeds';
import StyledTableCell from '../../../components/Tables/StyledTableCell';
import { RadioButtonUnchecked } from '@mui/icons-material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { colors } from '@phyllome/common';

const Seeds: FC<unknown> = () => {
  const seedList = useSeeds();
  const [selectedRow, setSelectedRow] = useState('');
  const navSide = useNavSide();

  seedList.sort((a, b) => {
    if (a.name === b.name) {
      return a.variety.localeCompare(b.variety);
    }
    return a.name.localeCompare(b.name);
  });
  return (
    <ContainerPrimary title="Seeds" icon={<NatureIcon />}>
      <Box pb={2}>
        <Button variant="contained" onClick={() => navSide('seeds/create')}>
          Create new seed definition
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="seasons" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Variety</StyledTableCell>
              <StyledTableCell>Density</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>
              <StyledTableCell>Duration (days)</StyledTableCell>
              <StyledTableCell>Irrig.Sched</StyledTableCell>
              <StyledTableCell>Light.Sched</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {seedList.map((seed) => (
              <TableRow
                hover
                key={seed.id}
                onClick={() => {
                  navSide(`seeds/${seed.id}`);
                  setSelectedRow(seed.id);
                }}
                sx={{ cursor: 'pointer' }}
                selected={selectedRow === seed.id}
              >
                <TableCell
                  sx={{
                    cursor: 'pointer',
                    boxSizing: 'border-box',
                    boxShadow:
                      selectedRow === seed.id
                        ? `inset 3px 0px 0px 0px ${colors.budding}`
                        : 'inset 3px 0px 0px 0px transparent',
                  }}
                >
                  {seed.name}
                </TableCell>
                <TableCell>{seed.variety}</TableCell>
                <TableCell>{seed.defaultSeedAmountPerTray}</TableCell>
                <TableCell>{seed.pricePerMillion}</TableCell>
                <TableCell>{seed.durationDays}</TableCell>
                <TableCell>
                  {seed.defaultIrrigationSchedule.length ? (
                    <TaskAltIcon color="primary" />
                  ) : (
                    <RadioButtonUnchecked color="earth3" />
                  )}
                </TableCell>
                <TableCell>
                  {seed.defaultLightingSchedule.length ? (
                    <TaskAltIcon color="primary" />
                  ) : (
                    <RadioButtonUnchecked color="earth3" />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ContainerPrimary>
  );
};

export default Seeds;
