import { colors } from '@phyllome/common';
import { FavouriteDefinition } from './types';

const fertGraph: FavouriteDefinition = {
  fertSampleEC: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample_ec`,
    color: colors.earth1,
    field: 'sample_ec',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
      max: 3,
    },
  }),
  fertSamplePH: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample_ph`,
    color: colors.earth1,
    field: 'sample_ph',
    brokerId,
    deviceId,
    constraints: {
      min: 3,
      max: 8,
    },
  }),

  fertSampleORP: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample_orp`,
    color: colors.earth1,
    field: 'sample_orp',
    brokerId,
    deviceId,
  }),

  fertSampleTemperature: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample_temperature`,
    color: colors.earth1,
    field: 'sample_temperature',
    brokerId,
    deviceId,
    constraints: {
      min: 0,
      max: 40,
    },
  }),

  fertSampleDO: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/sample_do`,
    color: colors.earth1,
    field: 'sample_do',
    brokerId,
    deviceId,
  }),

  fertDispensedNutrientA: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed_nutrientA`,
    color: colors.earth1,
    field: 'dispensed_nutrientA',
    brokerId,
    deviceId,
  }),
  fertDispensedNutrientB: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed_nutrientB`,
    color: colors.earth1,
    field: 'dispensed_nutrientB',
    brokerId,
    deviceId,
  }),
  fertDispensedAcid: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed_acid`,
    color: colors.earth1,
    field: 'dispensed_acid',
    brokerId,
    deviceId,
  }),
  fertDispensedBase: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed_base`,
    color: colors.earth1,
    field: 'dispensed_base',
    brokerId,
    deviceId,
  }),
  fertDispensedPeroxide: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed_peroxide`,
    color: colors.earth1,
    field: 'dispensed_peroxide',
    brokerId,
    deviceId,
  }),
  fertDispensedPump: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'fertigator',
    subType: 'cumulative',
    showHeading: ({ deviceId }) => `[FERT] ${deviceId}/dispensed_pump`,
    color: colors.earth1,
    field: 'dispensed_pump',
    brokerId,
    deviceId,
  }),
};

export default fertGraph;
