import GenericPing from '../Generic/control/GenericPing';
import { FromFirebase } from '../../types/types';
import { TransferDeviceState } from '@phyllome/common';

const ControlAir = ({
  latest,
}: {
  latest: FromFirebase<TransferDeviceState>;
}) => {
  return (
    <div>
      <GenericPing
        deviceId={latest.deviceId}
        brokerId={latest.brokerId}
        deviceType="transfer"
      />
    </div>
  );
};

export default ControlAir;
