import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import { Alert, AlertTitle } from '@mui/material';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import { LiftDeviceState, mqttDefinitionLiftConfigSet } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormLiftConfigSetColumn = ({
  latest,
}: {
  latest: FromFirebase<LiftDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionLiftConfigSet,
    deviceId,
  );

  const activityLog = useActivityLog(`device/lift/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionLiftConfigSet, {
    firstColumn: latest?.firstColumn || 0,
  });

  return (
    <PaperSection
      heading="Lift Column Configuration"
      subheading={mqttDefinitionLiftConfigSet.topic}
      mb={2}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              firstColumn: data.firstColumn,
            },
            topicParams: {
              deviceId,
            },
          });
          activityLog.add(
            `Set lift ${deviceId} first column to ${data.firstColumn}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          firstColumn: yup.number().min(1).max(100).required(),
        })}
      >
        <Stack spacing={2}>
          <Alert severity="warning">
            <AlertTitle>Warning! Proceed with caution</AlertTitle>
            Changing column alias will require a hardware reset and will affect
            data aliasing. There are no checks to ensure that the column alias
            is unique.
          </Alert>
          <TextFieldElement label="First Column" name="firstColumn" required />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormLiftConfigSetColumn;
