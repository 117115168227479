import { FC, useState } from 'react';
import { Stack } from '@mui/system';
import dateToString, { dateFormatTypes } from '../../helpers/dateToString';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import useAction from '../../data/hooks/useAction';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatabaseActivityLog, colors } from '@phyllome/common';
import HighlightedText from '../HighlightedActionText/HighlightedActionText';
import { FromFirebase } from '../../types/types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import useNavSide from '../../data/hooks/useNavSide';
import { Timestamp } from 'firebase/firestore';

// const actvityLogColorMap: {
//   [key in DatabaseActivityLog["origin"]["type"]]: string;
// } = {
//   USER: colors.budding,
//   SYSTEM: colors.vegetative,
//   OPERATION: colors.fog,
//   PHOTO: colors.sunlight,
// };

type IActivityLogChildProps = {
  log: FromFirebase<DatabaseActivityLog>;
  showOpenParent?: boolean;
};
export const ActivityLogChild: FC<IActivityLogChildProps> = ({
  log,
  showOpenParent = false,
}) => {
  const navSide = useNavSide();
  const deleteEntry = useAction('activityLogDelete');
  const [highlightActivity, setHighlightActivity] = useState<false | string>(
    false,
  );

  return (
    <Box
      onMouseEnter={() => setHighlightActivity(log.id)}
      onMouseLeave={() => setHighlightActivity(false)}
      key={log.id}
    >
      <Stack direction="row" spacing={1} sx={{ minWidth: 0 }} mb={2}>
        <Box minWidth={50} textAlign="right">
          <Tooltip
            title={dateToString(
              log.timestamp,
              dateFormatTypes.DAYMONTHHOURSSECONDS,
            )}
            arrow
          >
            <Typography variant="caption" noWrap color="clouds.main">
              {isWithinLast12Hours(log.timestamp)
                ? dateToString(log.timestamp, dateFormatTypes.TIMEONLY)
                : dateToString(log.timestamp, dateFormatTypes.DAYMONTH)}
            </Typography>
          </Tooltip>
        </Box>
        <CircleIcon
          color={
            highlightActivity === log.id && log.origin.type === 'USER'
              ? 'primary'
              : log.origin.type === 'USER'
                ? 'vegetative'
                : log.origin.type === 'SYSTEM'
                  ? 'budding'
                  : log.origin.type === 'OPERATION'
                    ? 'fog'
                    : log.origin.type === 'PHOTO'
                      ? 'sunlight'
                      : 'fog'
          }
        />
        <Stack direction="column" spacing={1}>
          <Typography
            variant="caption"
            paddingTop="2px"
            color={log.origin.type === 'USER' ? 'text.primary' : 'clouds.main'}
          >
            {log.origin.ident}
          </Typography>
          <Box
            sx={{
              borderLeft: '2px solid',
              borderLeftColor: colors.fog,
              paddingLeft: '19px',
              position: 'relative',
              left: '-20px',
            }}
          >
            <Typography
              variant={log.origin.type === 'USER' ? 'body1' : 'body2'}
              color={
                log.origin.type === 'USER' ? 'text.primary' : 'clouds.main'
              }
            >
              {log.origin.type === 'USER' ? (
                log.description
              ) : (
                <HighlightedText text={log.description} />
              )}
            </Typography>
          </Box>
        </Stack>
        <Box
          style={{
            marginLeft: 'auto',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          justifyContent="flex-end"
        >
          <IconButton
            aria-label="delete"
            onClick={() => deleteEntry.run({ id: log.id })}
            style={{
              visibility:
                highlightActivity === log.id && log.origin.type === 'USER'
                  ? 'inherit'
                  : 'hidden',
            }}
          >
            <DeleteIcon />
          </IconButton>
          {showOpenParent && (
            <IconButton
              aria-label="open parent"
              onClick={() => {
                navSide(returnSideNavPathFromActivityLogIdentifier(log.parent));
              }}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
export default ActivityLogChild;

const returnSideNavPathFromActivityLogIdentifier = (
  identifier: string,
): string => {
  const chunks = identifier.split('/');

  switch (chunks[0]) {
    case 'device': {
      const deviceType = chunks[1] === 'nds' ? 'sprinklers' : chunks[1]; // special case for nds->sprinklers
      const deviceId = chunks[2];

      return `${deviceType}/${deviceId}`;
    }
    case 'season': {
      const seasonId = chunks[1];

      return `season/${seasonId}`;
    }
    case 'traySeason': {
      const traySeasonId = chunks[1];

      return `tray/${traySeasonId}`;
    }
    default:
      return '';
  }
};

const isWithinLast12Hours = (
  timestamp: Timestamp | null | undefined,
): boolean => {
  if (!timestamp) return false;

  const twelveHoursAgo = Timestamp.fromMillis(Date.now() - 12 * 60 * 60 * 1000);

  return timestamp.toMillis() >= twelveHoursAgo.toMillis();
};
