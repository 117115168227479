import React, { FC } from 'react';
import useTrayMap from '../../../data/hooks/collections/useTrayMap';

import Shelf from './components/Shelf/Shelf';

import { howManyDaysAgo } from '../../../helpers/FStimeAgo';

import { Grid } from '@mui/material';

interface IProps {
  colId: number;
  rows: number;
  shelves: number;
}

const Rack: FC<IProps> = ({ colId, rows, shelves }) => {
  const rackMap = useTrayMap(colId);
  const arrayOfShelves = Array.from(Array(rows).keys());
  const arrayOfRows = Array.from(Array(shelves).keys()).reverse();

  const getAgeOfTray = (shelf: number, row: number): number => {
    const k = `${shelf}.${row}`;

    return rackMap.value[k] ? howManyDaysAgo(rackMap.value[k].loaded) : 0;
  };

  return (
    <>
      <h2>Rack {colId}</h2>
      <Grid container spacing={1}>
        {arrayOfShelves.map((rowIdMinusOne, key) => {
          return (
            <Grid item xs key={key}>
              {arrayOfRows.map((shelfIdMinusOne, _key) => {
                const currentShelf = shelfIdMinusOne + 1;
                const currentRow = rowIdMinusOne + 1;
                const k = `${currentShelf}.${currentRow}`;

                return (
                  <Shelf
                    key={_key}
                    data={{
                      state: rackMap.value[k] ? 'TRAY' : 'NORMAL',
                      age: getAgeOfTray(currentShelf, currentRow),
                    }}
                    location={{
                      shelf: currentShelf,
                      row: currentRow,
                      column: colId,
                    }}
                  />
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Rack;
