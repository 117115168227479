import { useContext } from 'react';
import useLatestLiftByAlias from '../../../../devicespecific/Lift/hooks/useLatestLiftByAlias';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useNavSide from '../../../../data/hooks/useNavSide';
import { Block } from '@mui/icons-material';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { colors } from '@phyllome/common';

type OverlayTraySimpleProps = {
  columnId: number;
};

const OverlayLift = ({ columnId }: OverlayTraySimpleProps) => {
  const { columnSpec, brokerId } = useContext(FarmContext);
  const liftData = useLatestLiftByAlias(brokerId, columnId);
  const shelves = columnSpec[columnId - 1].shelves;
  const navSide = useNavSide();

  const shelf: number = liftData?.shelf || 0;
  const destination: number = liftData?.destination || 0;

  // This could be a BUG. As I'm unsure what no traynot looks like. - mick
  const hasTraybot = liftData?.traybot !== undefined;

  return (
    <>
      {Array.from({ length: shelves + 1 }, (_, index) => (
        <LiftSprite
          key={index}
          shelves={shelves}
          shelf={index}
          onClick={() => navSide(`lift/${liftData?.deviceId}`)}
          hasTraybot={hasTraybot}
          isDestination={index === destination}
          isLocation={index === shelf}
          isBlocked={liftData?.lift_blocked && index === shelf}
        >
          {index < destination && index > shelf && (
            <ArrowDropUpIcon color="seedling" />
          )}
          {index < shelf && index > destination && (
            <ArrowDropDownIcon color="seedling" />
          )}
        </LiftSprite>
      ))}
    </>
  );
};

const LiftSprite = ({
  shelves,
  shelf,
  onClick,
  isDestination = false,
  isLocation = false,
  isBlocked = false,
  children = null,
}: {
  shelves: number;
  shelf: number;
  onClick: () => void;
  hasTraybot: boolean;
  isDestination: boolean;
  isLocation: boolean;
  isBlocked: boolean;
  children?: React.ReactNode;
}) => {
  const height = shelves * 16;

  return (
    <div
      onClick={onClick}
      style={{
        zIndex: 10,
        padding: '0 0 0 0',
        margin: '0 0 0 0',
        borderWidth: '2px',
        borderRadius: '3px',
        borderColor: isLocation
          ? colors.ocean
          : isDestination
            ? colors.budding
            : 'transparent',
        borderStyle: isLocation ? 'solid' : isDestination ? 'dashed' : 'none',
        position: 'absolute',
        cursor: 'pointer',
        background: isBlocked ? colors.sunlight : colors.fog,
        width: '55px',
        height: '16px',
        top: `${height - shelf * 16}px`,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '-5px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {children}
        {isBlocked && <Block sx={{ fontSize: 12 }} />}
      </div>
    </div>
  );
};

export default OverlayLift;
