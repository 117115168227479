import { Box, Typography } from '@mui/material';
import { FC } from 'react';

type IProps = {
  children?: React.ReactNode;
  value: number;
  index: number;
};

const TabPanel: FC<IProps> = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
