import { httpsCallableFromURL } from 'firebase/functions';
import { useState } from 'react';
import { useFunctions } from 'reactfire';

type Status = 'IDLE' | 'RUNNING' | 'DONE' | 'ERROR';

const createEndpoint = (name: string) => {
  return process.env.NODE_ENV === 'production'
    ? `https://${name}-hbtwsngtia-ts.a.run.app`
    : 'http://127.0.0.1:5001/phyllome-core/us-central1';
};

const useEndpointDelete = <CloudDeleteType>(name: string) => {
  const [status, setStatus] = useState<Status>('IDLE');
  const functions = useFunctions();

  const func = httpsCallableFromURL<CloudDeleteType, any>(
    functions,
    `${createEndpoint(name)}/${name}`,
  );

  return {
    run: async (data: CloudDeleteType) => {
      setStatus('RUNNING');
      return func(data)
        .then((payload) => {
          setStatus('DONE');
          return payload;
        })
        .catch((e) => {
          setStatus('ERROR');
          throw e;
        });
    },
    status,
  };
};

export default useEndpointDelete;
