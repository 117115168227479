import { Co2DeviceState } from '@phyllome/common';

import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../components/ReactJson/ReactJson';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';

const LatestCo2 = ({ latest }: { latest: FromFirebase<Co2DeviceState> }) => {
  return (
    <PaperSection
      heading="Latest Co2 Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ReactJson src={latest} />
    </PaperSection>
  );
};

export default LatestCo2;
