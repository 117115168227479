import { Box, LinearProgress } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

interface IProps {
  text?: string | JSX.Element;
}

const DefaultLoading = () => {
  return (
    <Box m={0}>
      <LinearProgress />
    </Box>
  );
};

const Sus: FC<PropsWithChildren<IProps>> = ({
  children,
  text = <DefaultLoading />,
}) => {
  return (
    <React.Suspense fallback={<div>{text}</div>}>
      <>{children}</>
    </React.Suspense>
  );
};

export default Sus;
