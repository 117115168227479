import { FC } from 'react';

import { LightingSchedule } from '@phyllome/common';
import ScheduleView, { StepType } from './ScheduleView';

type ContextEnum = 'seed' | 'season' | 'zone';

// replace with CompareLightingSchedules from @phyllome/common once it is available
//import {compareLightingSchedules} from @phyllome/common;
import _ from 'lodash';
const compareLightingSchedules = (array1: any[], array2: any[]): boolean => {
  return _.isEqual(array1, array2);
};
// end replace

interface IScheduleViewLightingProps {
  context?: ContextEnum;
  seedSchedule?: LightingSchedule;
  seasonSchedule?: LightingSchedule;
  zoneSchedule?: LightingSchedule;
}

const ScheduleViewLighting: FC<IScheduleViewLightingProps> = ({
  seedSchedule = [],
  seasonSchedule = [],
  zoneSchedule = [],
  context = 'seed',
}) => {
  const seedEqualsSeason = compareLightingSchedules(
    seedSchedule,
    seasonSchedule,
  );
  const seedEqualsZone = compareLightingSchedules(seedSchedule, zoneSchedule);
  const seasonEqualsZone = compareLightingSchedules(
    seasonSchedule,
    zoneSchedule,
  );

  const steps: Array<StepType> = [
    {
      label: 'Seed',
      selected: context === 'seed',
      error: seedSchedule?.length === 0,
      linkedToPrevious: false, // first element, always false
      completed: seedEqualsSeason || (seedEqualsZone && context === 'zone'),
      schedule: seedSchedule,
      showCard: context !== 'seed',
    },
    {
      label: 'Season',
      selected: context === 'season',
      error: seasonSchedule?.length === 0 && context !== 'seed',
      linkedToPrevious: seedEqualsSeason,
      completed: (seedEqualsSeason || seasonEqualsZone) && context !== 'seed',
      schedule: seasonSchedule,
      showCard: context !== 'seed',
    },
    {
      label: 'Zone',
      selected: context === 'zone',
      error: zoneSchedule?.length === 0 && context !== 'seed',
      linkedToPrevious: seasonEqualsZone && context === 'zone',
      completed: seasonEqualsZone && context === 'zone',
      schedule: zoneSchedule,
      showCard: context === 'zone',
    },
  ];

  return <ScheduleView steps={steps} type="lighting" />;
};

export default ScheduleViewLighting;
