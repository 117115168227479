import React, { FC } from 'react';
import ContainerPrimary from '../../../components/containers/ContainerPrimary/ContainerPrimary';

import useCurrentBrokerConfig from '../../../data/hooks/useCurrentBrokerConfig';
import Rack from './Rack';
import { MenuOpen } from '@mui/icons-material';
interface IProps {}

const Racking: FC<IProps> = () => {
  const brokerConfig = useCurrentBrokerConfig('null');
  const { racks, rows, shelves } = brokerConfig.racking;
  const arrayOfRacks = Array.from(Array(racks).keys());

  return (
    <ContainerPrimary title="Columns" icon={<MenuOpen />}>
      {arrayOfRacks.map((colIdMinusOne, key) => {
        return (
          <Rack
            key={key}
            colId={colIdMinusOne + 1}
            rows={rows}
            shelves={shelves}
          />
        );
      })}
    </ContainerPrimary>
  );
};

export default Racking;
