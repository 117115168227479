import { DatabaseSeason } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';

import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import FormSetTimeBased from './FormSetTimeBased';
import { Alert, AlertTitle, Box } from '@mui/material';

const SideBarSeasonEditGrowthTimeline = ({
  seasonData,
}: {
  seasonData?: FromFirebase<DatabaseSeason>;
}) => {
  if (!seasonData) return null;
  return (
    <PaperSection
      mt={2}
      heading="Edit Growth Timeline"
      hidden={seasonData?.state === 'completed'}
      collapsable={true}
    >
      <Box mb={2}>
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>Editing the growth timeline will
          affect all trays in this season. This may have unintended consequences
          with allocation of consumables, only do this as a last resort
        </Alert>
      </Box>
      <Box mb={2}>
        <FormSetTimeBased
          seasonData={seasonData}
          label="Seeded Start/End"
          fieldNameSeason="traySeedEarliest"
          fieldNameTraySeason="timestampSeeded"
        />
        <FormSetTimeBased
          seasonData={seasonData}
          label="Growth Start"
          fieldNameSeason="growthStarted"
          fieldNameTraySeason="timestampGrowthStart"
        />
        <FormSetTimeBased
          seasonData={seasonData}
          label="Growth End"
          fieldNameSeason="growthEnded"
          fieldNameTraySeason="timestampGrowthEnd"
        />
        <FormSetTimeBased
          seasonData={seasonData}
          label="Harvested Start/End"
          fieldNameSeason="trayHarvestEarliest"
          fieldNameTraySeason="timestampHarvested"
        />
      </Box>
    </PaperSection>
  );
};

export default SideBarSeasonEditGrowthTimeline;
