import { colors, DeviceType } from '@phyllome/common';
import useAlerts from '../../pages/Broker/Logs/Alerts/hooks/useAlerts';
import { useState, Fragment } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Alert,
  AlertTitle,
} from '@mui/material';
import AlertLevel from '../puregui/AlertLevel/AlertLevel';
import { howLongAgoInEnglish } from '../../helpers/FStimeAgo';
import StyledTableCell from '../Tables/StyledTableCell';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReactJson from '../ReactJson/ReactJson';

function AlertDeviceRow(props: { alert: any }) {
  const { alert } = props;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow
        hover
        key={alert.id}
        style={{ cursor: 'pointer' }}
        onClick={() => setOpen(!open)}
        selected={open}
      >
        <TableCell
          sx={{
            cursor: 'pointer',
            boxSizing: 'border-box',
            boxShadow: open
              ? `inset 3px 0px 0px 0px ${colors.budding}`
              : 'inset 3px 0px 0px 0px transparent',
          }}
        >
          <AlertLevel level={alert.level} />
        </TableCell>
        <TableCell component="th" scope="alert">
          {howLongAgoInEnglish(alert.timestamp)} ago
        </TableCell>
        <TableCell>{alert.message}</TableCell>{' '}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ReactJson src={alert} expandAll={true} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

type AlertDeviceProps = {
  brokerId: string;
  deviceType: DeviceType;
  deviceId: string;
  count?: number;
};

const AlertsDevice = ({
  brokerId,
  deviceType,
  deviceId,
  count = 100,
}: AlertDeviceProps) => {
  const alerts = useAlerts({ brokerId, deviceType, deviceId, count });

  if (alerts.length === 0)
    return (
      <Alert severity="info">
        <AlertTitle>No Alerts</AlertTitle>
        No alerts recorded for device{' '}
        <strong>
          {deviceType}/{deviceId}
        </strong>
      </Alert>
    );
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="alerts" size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>Level</StyledTableCell>
            <StyledTableCell>Timestamp</StyledTableCell>
            <StyledTableCell>Message</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alerts.map((alert) => (
            <AlertDeviceRow key={alert.id} alert={alert} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AlertsDevice;
