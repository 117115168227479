import { TextFieldElement, SelectElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import * as yup from 'yup';
import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';

import { Stack } from '@mui/system';
import {
  mqttDefinitionTraybotConfigSet,
  TraybotDeviceState,
} from '@phyllome/common';
import { FromFirebase } from '../../../types/types';
import useActivityLog from '../../../data/hooks/useActivityLog';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import { InputAdornment } from '@mui/material';

const FormTraybotConfigSetDrive = ({
  latest,
}: {
  latest: FromFirebase<TraybotDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionTraybotConfigSet,
    deviceId,
  );
  const activityLog = useActivityLog(`device/traybot/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(
    mqttDefinitionTraybotConfigSet,
    {
      drive: {
        wheelCircumference: latest?.drive_wheelCircumference || 0,
        driveGearRatio: latest?.drive_driveGearRatio || 0,
        forwardDirection: latest?.drive_forwardDirection || 0,
      },
    },
  );

  return (
    <PaperSection
      heading="Traybot Drive Config"
      mb={2}
      subheading={mqttDefinitionTraybotConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              drive: {
                wheelCircumference: data?.drive?.wheelCircumference,
                driveGearRatio: data?.drive?.driveGearRatio,
                forwardDirection: data?.drive?.forwardDirection,
              },
            },
            topicParams: {
              deviceId: latest?.deviceId,
            },
          });

          activityLog.add('Updated traybot drive config}', 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          drive: yup.object({
            wheelCircumference: yup.number().min(0).max(300).required(),
            driveGearRatio: yup.number().min(0).max(10).required(),
            forwardDirection: yup.number().required(),
          }),
        })}
      >
        <Stack spacing={2}>
          <TextFieldElement
            label="Wheel Circumference"
            name="drive.wheelCircumference"
            required
            InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
          />
          <TextFieldElement
            label="Drive Gear Ratio"
            name="drive.driveGearRatio"
            required
          />
          <SelectElement
            label="Forward Direction"
            name="drive.forwardDirection"
            options={[
              {
                id: '1',
                label: 'Forward',
              },
              {
                id: '-1',
                label: 'Reverse',
              },
            ]}
          />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormTraybotConfigSetDrive;
