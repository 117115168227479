import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../components/ReactJson/ReactJson';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import { ZoneDeviceState } from '@phyllome/common';

const LatestZone = ({ latest }: { latest: FromFirebase<ZoneDeviceState> }) => {
  return (
    <PaperSection
      heading="Latest Zone Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ReactJson src={latest} />
    </PaperSection>
  );
};

export default LatestZone;
