import { Co2DeviceState } from '@phyllome/common';
import useDeviceState from '../../../data/hooks/useDeviceState';

const useLatestCo2 = (brokerId: string, deviceId: string) => {
  const data = useDeviceState<Co2DeviceState>({
    brokerId,
    deviceId,
    deviceType: 'co2',
  });

  return data;
};

export default useLatestCo2;
