import { FC } from 'react';
import SideBarEntryAir from '../devicespecific/Air/SideBarEntryAir';
import SideBarEntryIrrigation from '../devicespecific/Irrigation/SideBarEntryIrrigation';
import SideBarEntryLighting from '../devicespecific/Lighting/SideBarEntryLighting';
import SideBarEntryNDS from '../devicespecific/NDS/SideBarEntryNDS';
import SideBarEntryAlert from '../pages/Broker/Logs/Alerts/SideBarEntryAlert';
import SeasonsSidebar from '../pages/Broker/Seasons/SeasonsSidebar';
import SideBarEntryRackUtil from '../pages/Broker/Trays/Sidebar/SideBarEntryRackUtil';
import SeasonsSidebarDelete from '../pages/Broker/Seasons/SeasonsSidebarDelete';
import SeedsSidebar from '../pages/Broker/Seeds/SeedsSidebar';
import SideBarSeasonTrayEditor from '../pages/Broker/Seasons/SideBarSeasonTrayEditor';
import SideBarSeasonEditor from '../pages/Broker/Seasons/SideBarSeasonEditor';
import SideBarEntryTraySeasonEntryPoint from '../pages/Broker/Trays/Sidebar/SideBarEntryTraySeasonEntryPoint';
import SideBarEntryCo2 from '../devicespecific/Co2/SideBarEntryCo2';
import SideBarEntryZone from '../devicespecific/Zone/SideBarEntryZone';
import SeedsSidebarEdit from '../pages/Broker/Seeds/SeedsSidebarEdit';
import SideBarEntryFertigator from '../devicespecific/Fertigator/SideBarEntryFertigator';
import SideBarEntryTraybot from '../devicespecific/Traybot/SideBarEntryTraybot';
import SidebarCustomConsoleGraph from '../pages/Broker/CustomConsole/SidebarCustomConsoleGraph';
import SideBarEntryLift from '../devicespecific/Lift/SideBarEntryLift';
import SidebarEntryCamera from '../devicespecific/Camera/SideBarEntryCamera';
import SideBarEntryHarvester from '../devicespecific/Harvester/SideBarEntryHarvester';
import SideBarEntrySentry from '../devicespecific/Sentry/SideBarEntrySentry';
import SideBarEntrySeeder from '../devicespecific/Seeder/SideBarEntrySeeder';
import SideBarEntryPackager from '../devicespecific/Packager/SideBarEntryPackager';
import SideBarEntryGuard from '../devicespecific/Guard/SideBarEntryGuard';
import SideBarEntryTask from '../pages/Broker/Logs/Tasks/SideBarEntryTask';
import SideBarEntryTransfer from '../devicespecific/Transfer/SideBarEntryTransfer';

const routes: { path: string; component: FC<any>; fullscreen?: boolean }[] = [
  {
    path: '/air/:deviceId',
    component: SideBarEntryAir,
  },
  {
    path: '/fertigator/:deviceId',
    component: SideBarEntryFertigator,
  },
  {
    path: '/sprinklers/:locationString',
    component: SideBarEntryNDS,
  },
  {
    path: '/nds/:locationString',
    component: SideBarEntryNDS,
  },
  {
    path: '/irrigation/:deviceId',
    component: SideBarEntryIrrigation,
  },
  {
    path: '/irrigation/:deviceId',
    component: SideBarEntryLighting,
  },
  {
    path: '/alert/:alertId',
    component: SideBarEntryAlert,
  },
  {
    path: '/task/:taskId',
    component: SideBarEntryTask,
  },
  {
    path: '/seasons/create',
    component: SeasonsSidebar,
  },
  {
    path: '/rackutil/:columnId/:refresh',
    component: SideBarEntryRackUtil,
  },
  {
    path: '/season/delete/:id',
    component: SeasonsSidebarDelete,
  },
  {
    path: '/seeds/create',
    component: SeedsSidebar,
  },
  {
    path: '/seeds/:seedId',
    component: SeedsSidebarEdit,
  },
  {
    path: '/season/trays/:seasonId',
    component: SideBarSeasonTrayEditor,
    fullscreen: true,
  },
  {
    path: '/season/:seasonId',
    component: SideBarSeasonEditor,
  },
  {
    path: '/tray/:traySeasonId',
    component: SideBarEntryTraySeasonEntryPoint,
  },
  {
    path: '/co2/:deviceId',
    component: SideBarEntryCo2,
  },
  {
    path: '/zone/:deviceId',
    component: SideBarEntryZone,
  },
  {
    path: '/lighting/:deviceId',
    component: SideBarEntryLighting,
  },
  {
    path: '/traybot/:deviceId',
    component: SideBarEntryTraybot,
  },
  {
    path: '/lift/:deviceId',
    component: SideBarEntryLift,
  },
  {
    path: '/camera/:deviceId',
    component: SidebarEntryCamera,
  },
  {
    path: '/harvester/:deviceId',
    component: SideBarEntryHarvester,
  },
  {
    path: '/sentry/:deviceId',
    component: SideBarEntrySentry,
  },
  {
    path: '/seeder/:deviceId',
    component: SideBarEntrySeeder,
  },
  {
    path: '/packager/:deviceId',
    component: SideBarEntryPackager,
  },
  {
    path: '/transfer/:deviceId',
    component: SideBarEntryTransfer,
  },
  {
    path: '/guard/:deviceId',
    component: SideBarEntryGuard,
  },
  {
    path: '/dashboard/graph/edit/:id',
    component: SidebarCustomConsoleGraph,
  },
];

export default routes;
