import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import ReactJson from '../../components/ReactJson/ReactJson';
import { dateFormatTypes } from '../../helpers/dateToString';
import dateToString from '../../helpers/dateToString';
import { FromFirebase } from '../../types/types';
import { TransferDeviceState } from '@phyllome/common';
import unflatten from '../../helpers/unflatten';

const LatestTransfer = ({
  latest,
}: {
  latest: FromFirebase<TransferDeviceState>;
}) => {
  const unflattened = unflatten(latest);

  return (
    <PaperSection
      heading="Latest Device Data"
      subheading={`Last Updated: ${dateToString(
        latest.timestamp,
        dateFormatTypes.TRUNCATED,
      )}`}
    >
      <ReactJson src={unflattened} expandAll />
    </PaperSection>
  );
};

export default LatestTransfer;
