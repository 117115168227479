import { createTheme  } from '@mui/material';
import { colors } from '@phyllome/common';

// Type for our custom colors
type CustomColor = {
  main: string;
  contrastText?: string;
};

// Define all our custom colors with their properties
const customColors = {
  sunlight: { main: colors.sunlight, contrastText: '#000000' },
  ripening: { main: colors.ripening, contrastText: '#FFFFFF' },
  budding: { main: colors.budding, contrastText: '#000000' },
  vegetative: { main: colors.vegetative, contrastText: '#000000' },
  seedling: { main: colors.seedling, contrastText: '#000000' },
  night: { main: colors.night, contrastText: '#FFFFFF' },
  earth3: { main: colors.earth3, contrastText: '#FFFFFF' },
  earth2: { main: colors.earth2, contrastText: '#FFFFFF' },
  earth1: { main: colors.earth1, contrastText: '#FFFFFF' },
  fog: { main: colors.fog, contrastText: '#000000' },
  ocean: { main: colors.ocean, contrastText: '#FFFFFF' },
  river: { main: colors.river, contrastText: '#FFFFFF' },
  clouds: { main: '#aaa', contrastText: '#000000' },
  storm: { main: '#333', contrastText: '#FFFFFF' },
} as const;

type CustomColorName = keyof typeof customColors;

// Helper to create palette overrides
const createPaletteOverrides = (colors: typeof customColors) => {
  return Object.entries(colors).reduce((acc, [key, value]) => {
    acc[key as CustomColorName] = value;
    return acc;
  }, {} as Record<CustomColorName, CustomColor>);
};

// Helper to create badge variants
const createBadgeVariants = (colors: typeof customColors) => {
  return Object.entries(colors).map(([colorName, colorValue]) => ({
    props: { color: colorName as CustomColorName },
    style: {
      '& .MuiBadge-badge': {
        backgroundColor: colorValue.main,
        color: colorValue.contrastText,
      },
    },
  }));
};

// Add custom colors to MUI theme types
declare module '@mui/material/styles' {
  interface Palette {
    sunlight: Palette['primary'];
    ripening: Palette['primary'];
    budding: Palette['primary'];
    vegetative: Palette['primary'];
    seedling: Palette['primary'];
    night: Palette['primary'];
    earth3: Palette['primary'];
    earth2: Palette['primary'];
    earth1: Palette['primary'];
    fog: Palette['primary'];
    ocean: Palette['primary'];
    river: Palette['primary'];
    clouds: Palette['primary'];
    storm: Palette['primary'];
  }

  interface PaletteOptions {
    sunlight: PaletteOptions['primary'];
    ripening: PaletteOptions['primary'];
    budding: PaletteOptions['primary'];
    vegetative: PaletteOptions['primary'];
    seedling: PaletteOptions['primary'];
    night: PaletteOptions['primary'];
    earth3: PaletteOptions['primary'];
    earth2: PaletteOptions['primary'];
    earth1: PaletteOptions['primary'];
    fog: PaletteOptions['primary'];
    ocean: PaletteOptions['primary'];
    river: PaletteOptions['primary'];
    clouds: PaletteOptions['primary'];
    storm: PaletteOptions['primary'];
  }
}

// Add custom colors to specific components
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    sunlight: true;
    ripening: true;
    budding: true;
    vegetative: true;
    seedling: true;
    night: true;
    earth3: true;
    earth2: true;
    earth1: true;
    fog: true;
    ocean: true;
    river: true;
    clouds: true;
    storm: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    sunlight: true;
    ripening: true;
    budding: true;
    vegetative: true;
    seedling: true;
    night: true;
    earth3: true;
    earth2: true;
    earth1: true;
    fog: true;
    ocean: true;
    river: true;
    clouds: true;
    storm: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    sunlight: true;
    ripening: true;
    budding: true;
    vegetative: true;
    seedling: true;
    night: true;
    earth3: true;
    earth2: true;
    earth1: true;
    fog: true;
    ocean: true;
    river: true;
    clouds: true;
    storm: true;
  }
}

declare module '@mui/material/Icon' {
  interface IconPropsColorOverrides {
    sunlight: true;
    ripening: true;
    budding: true;
    vegetative: true;
    seedling: true;
    night: true;
    earth3: true;
    earth2: true;
    earth1: true;
    fog: true;
    ocean: true;
    river: true;
    clouds: true;
    storm: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    sunlight: true;
    ripening: true;
    budding: true;
    vegetative: true;
    seedling: true;
    night: true;
    earth3: true;
    earth2: true;
    earth1: true;
    fog: true;
    ocean: true;
    river: true;
    clouds: true;
    storm: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    sunlight: true;
    ripening: true;
    budding: true;
    vegetative: true;
    seedling: true;
    night: true;
    earth3: true;
    earth2: true;
    earth1: true;
    fog: true;
    ocean: true;
    river: true;
    clouds: true;
    storm: true;
  }
}

// Create the theme
const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.ripening,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: colors.earth3,
    },
    warning: {
      main: '#b91111',
    },
    // Add all custom colors to palette
    ...createPaletteOverrides(customColors),
  },
  components: {
    // Badge variants for custom colors
    MuiBadge: {
      variants: createBadgeVariants(customColors),
    },
    // Button customizations
    MuiButton: {
      variants: Object.entries(customColors).map(([colorName, colorValue]) => ({
        props: { color: colorName as CustomColorName },
        style: {
          '&:hover': {
            backgroundColor: colorValue.main + '99', // Add transparency for hover
          },
        },
      })),
    },
  },
});

export default muiTheme;
export type { CustomColorName };