import { Typography, Stack } from '@mui/material';
import { TextFieldElement, SelectElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import { mqttDefinitionAirConfigSet, AirDeviceState } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormAirConfigSetLabel = ({
  state,
}: {
  state: FromFirebase<AirDeviceState>;
}) => {
  const controlLifecycle = useControlSend(
    mqttDefinitionAirConfigSet,
    state.deviceId,
  );

  const defaultValues = formCreateDefaultValues(mqttDefinitionAirConfigSet, {
    label: state.label,
    area: state.area,
  });

  return (
    <PaperSection
      mb={2}
      heading="Area and Label"
      subheading={mqttDefinitionAirConfigSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              area: data.area,
              label: data.label,
            },
            topicParams: {
              deviceId: state.deviceId,
            },
          });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          label: yup
            .string()
            .test(
              'len',
              '20 characters max',
              (val) => (val?.length || 0) <= 20,
            ),
          area: yup.number().required(),
        })}
      >
        <Stack spacing={2}>
          <Typography variant="body1">
            The relevant farm CO2 controller subscribes to all airmonitors where
            area is set to Growroom. Other airmonitor data is ignored.
          </Typography>
          <SelectElement
            label="Area"
            name="area"
            options={[
              {
                id: '0',
                label: 'Default (location not set)',
              },
              {
                id: '1',
                label: 'Growroom (used by co2 system)',
              },
              {
                id: '2',
                label: 'External (outside growroom)',
              },
            ]}
          />
          <Typography variant="body1">
            Label is used to provide human readable context to the device
          </Typography>
          <TextFieldElement label="Label" name="label" type="text" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormAirConfigSetLabel;
