import { FC } from 'react';
import { Grid } from '@mui/material';
import convertToBinaryArray from '../../helpers/convertToBinaryArray';
import ChipBool from '../puregui/ChipBool/ChipBool';
import ReportIcon from '@mui/icons-material/Report';

interface IProps {
  labels: string[];
  faults: number;
}

const FaultMap: FC<IProps> = ({ labels, faults }) => {
  const faultArray = convertToBinaryArray(faults);

  return (
    <Grid container spacing={1}>
      {labels.map((fault, index) => (
        <Grid item key={index}>
          <ChipBool
            data={faultArray[index]}
            label={fault}
            color="earth3"
            icon={<ReportIcon />}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default FaultMap;
