import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import { Co2DeviceState, mqttDefinitionCo2Set } from '@phyllome/common';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormCo2SetSetpoint = ({
  latest,
}: {
  latest: FromFirebase<Co2DeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionCo2Set, deviceId);
  const activityLog = useActivityLog(`device/co2/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionCo2Set, {
    setpoint: latest.setpoint || 0,
  });

  return (
    <PaperSection
      heading="CO2 Setpoint"
      mb={2}
      subheading={mqttDefinitionCo2Set.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              setpoint: data.setpoint,
            },
            topicParams: {
              deviceId: deviceId,
            },
          });

          activityLog.add(`Set CO2 setpoint to ${data.setpoint}`, 'OPERATION');
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          setpoint: yup.number().min(400).max(2000).required(),
        })}
      >
        <Stack spacing={2}>
          <TextFieldElement label="Setpoint" name="setpoint" required />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCo2SetSetpoint;
