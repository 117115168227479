import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import SubmitControl from '../../../forms/SubmitControl';
import { Co2DeviceState, mqttDefinitionCo2Set } from '@phyllome/common';
import { Alert } from '@mui/material';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';

const FormCo2SetEnableTime = ({
  latest,
}: {
  latest: FromFirebase<Co2DeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionCo2Set, deviceId);
  const activityLog = useActivityLog(`device/co2/${deviceId}/notes`);

  const defaultValues = formCreateDefaultValues(mqttDefinitionCo2Set, {
    start: {
      hour: latest.start_hour,
      minute: latest.start_minute,
    },
    end: {
      hour: latest.end_hour,
      minute: latest.end_minute,
    },
  });

  return (
    <PaperSection
      heading="CO2 Dosing window"
      mb={2}
      subheading={mqttDefinitionCo2Set.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle
            .run({
              payload: {
                start: { hour: data.start.hour, minute: data.start.minute },
                end: { hour: data.end.hour, minute: data.end.minute },
              },
              topicParams: {
                deviceId,
              },
            })
            .then(() => {
              activityLog.add(
                `Set CO2 dosing window to ${data.start.hour}:${data.start.minute} - ${data.end.hour}:${data.end.minute}`,
                'OPERATION',
              );
            });
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          start: yup
            .object({
              hour: yup.number().required(),
              minute: yup.number().required(),
            })
            .required(),
          end: yup
            .object({
              hour: yup.number().required(),
              minute: yup.number().required(),
            })
            .required(),
        })}
      >
        <Stack spacing={2}>
          <Alert severity="info">Note times are GMT</Alert>
          <Stack direction="row" spacing={2}>
            <TextFieldElement label="Start Hour" name="start.hour" required />
            <TextFieldElement
              label="Start Minute"
              name="start.minute"
              required
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextFieldElement label="End Hour" name="end.hour" required />
            <TextFieldElement label="End Minute" name="end.minute" required />
          </Stack>
          <p>
            When enabled, the CO2 controller will dispense CO2 gas between these
            times.
          </p>
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormCo2SetEnableTime;
