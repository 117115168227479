import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { drawActions, useAppDrawer } from './AppDrawer/useAppDrawer';

const useSideBar = () => {
  const { dispatch } = useAppDrawer();
  const currentLocation = useLocation();
  const hash = currentLocation.hash;

  useEffect(() => {
    if (hash) {
      dispatch({
        type: drawActions.OPEN,
      });
    } else {
      dispatch({
        type: drawActions.CLOSE,
      });
    }
  }, [dispatch, hash]);
};

export default useSideBar;
