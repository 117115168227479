import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useControlSend from '../../../data/hooks/useControlSend';
import {
  SentryDeviceState,
  mqttDefinitionSentryConfigSet,
} from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import useActivityLog from '../../../data/hooks/useActivityLog';
import { FromFirebase } from '../../../types/types';

const FormSentryConfigSetLabel = ({
  latest,
}: {
  latest: FromFirebase<SentryDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(
    mqttDefinitionSentryConfigSet,
    deviceId,
  );

  const activityLog = useActivityLog(`device/sentry/${deviceId}/notes`);

  return (
    <PaperSection
      heading="Config Label"
      subheading={mqttDefinitionSentryConfigSet.topic}
      mb={2}
    >
      <PhormContainer
        defaultValues={{ label: latest?.label?.toString() }}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              label: data.label,
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Updated sentry label to ${data.label}}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          label: yup.string(),
        })}
      >
        <Stack spacing={2}>
          <TextFieldElement label="Label" name="label" type="text" />
          <SubmitControl controlHook={controlLifecycle}>
            Send to device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormSentryConfigSetLabel;
