import React, { FC, ReactElement, ReactNode } from 'react';
import { Tooltip } from '@mui/material';

const ToolTipWrapper: FC<{ tooltip?: ReactNode; children: ReactElement }> = ({
  tooltip,
  children,
}) => {
  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow>
        {children}
      </Tooltip>
    );
  }
  return children;
};

export default ToolTipWrapper;
