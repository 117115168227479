import { useContext } from 'react';
import FarmContext from '../../../components/FarmProvider/FarmContext';
import { DatabaseDevices, DeviceType, WithId } from '@phyllome/common';
import { doc, collection } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData, ObservableStatus } from 'reactfire';
import { FromFirebase } from '../../../types/types';

const useDeviceById = <T>({
  deviceId,
  deviceType,
}: {
  deviceId: string;
  deviceType: DeviceType;
}) => {
  const { brokerId } = useContext(FarmContext);

  const key = `${brokerId}.${deviceType}.${deviceId}`;

  const documentReference = doc(collection(useFirestore(), 'devices'), key);

  const data = useFirestoreDocData(documentReference) as ObservableStatus<
    FromFirebase<DatabaseDevices<WithId<T>>>
  >;

  const props = data?.data?.properties;

  if (props) return props;
  return undefined;
};

export default useDeviceById;
