import { ObservableStatus, useFirestore, useFirestoreDocData } from 'reactfire';
import { useAppState } from '../../AppState/useAppState';
import { ICollectionTrayMap } from '../../../types/types';

import { collection, doc } from 'firebase/firestore';

const useTrayMap = (rackId: number): ICollectionTrayMap => {
  const { state: appState } = useAppState();
  const key = `${appState.brokerId}.${rackId}`;

  const documentReference = doc(collection(useFirestore(), 'traymap'), key);

  const data = useFirestoreDocData(
    documentReference,
  ) as ObservableStatus<ICollectionTrayMap>;

  return data?.data;
};

export default useTrayMap;
