import { DeviceType } from '@phyllome/common';
import { FC, useContext } from 'react';
import PaperSection from '../puregui/PaperSection/PaperSection';

import RechartVictoryBool from './RechartVictoryBool';
import FavGraph, { FavGraphWrap } from '../FavGraph/FavGraph';
import { FavouriteName } from '../Favourites/collectionFavourites';
import useTimeSeriesPoller from './useTimeSeriesPoller';
import { ChartContext } from './ChartContext/ChartContext';
import { Alert, AlertTitle, Box, Skeleton } from '@mui/material';
export type RechartVictoryHeadingProps = {
  brokerId: string;
  deviceId: string;
  deviceType: DeviceType;
  field: string;
  color: string;
  heading: string;
  name: FavouriteName;
};

const ReChartVictoryBoolHeading: FC<RechartVictoryHeadingProps> = ({
  brokerId,
  deviceId,
  deviceType,
  field,
  color,
  heading,
  name,
}) => {
  const {
    state: { beginDate, endDate },
  } = useContext(ChartContext);

  const timeSeries = useTimeSeriesPoller({
    brokerId,
    deviceId,
    deviceType,
    origin: field,
    type: 'bool',
  });

  if (timeSeries.isInitiating) {
    return (
      <PaperSection heading={heading}>
        <Skeleton variant="rectangular" height={300} animation="wave" />
      </PaperSection>
    );
  }

  if (timeSeries.isUnsufficientData) {
    <PaperSection heading={heading}>
      <Box sx={{ height: 300 }}>
        <Alert severity="error">
          <AlertTitle>Unable to load chart</AlertTitle>Insufficient data
          available in timeSeries.
        </Alert>
      </Box>
    </PaperSection>;
  }
  return (
    <PaperSection heading={heading}>
      <FavGraphWrap>
        <FavGraph deviceId={deviceId} brokerId={brokerId} name={name} />
      </FavGraphWrap>
      <RechartVictoryBool
        beginDate={beginDate}
        endDate={endDate}
        color={color}
        timeSeries={timeSeries}
      />
    </PaperSection>
  );
};

export default ReChartVictoryBoolHeading;
