import { differenceInMinutes } from 'date-fns';
import { dateFormatTypes } from '../../../helpers/dateToString';
import { ChartWindowType } from './chartContextTypes';
export const getChartConfigs = (
  beginTime: Date,
  endTime: Date,
): {
  formatType: dateFormatTypes;
  tickInterval: number;
  windowType: ChartWindowType;
} => {
  const minutesDifference = differenceInMinutes(endTime, beginTime);
  let window: ChartWindowType;

  /*
    hour = 60
    3 hours = 180
    6 hours = 360
    1 day = 1440
    3 days = 4320
    1 week = 10080
    2 weeks = 20160
  3 weeks = 30240
  4 weeks = 40320
  */

  if (minutesDifference <= 60) window = 'raw';
  else if (minutesDifference <= 180) window = 'raw';
  else if (minutesDifference <= 360) window = 'raw';
  else if (minutesDifference <= 1440) window = 'fifteen';
  else if (minutesDifference <= 4320) window = 'fifteen';
  else if (minutesDifference <= 10080) window = 'hour';
  else if (minutesDifference <= 20160) window = 'hour';
  else if (minutesDifference <= 30240) window = 'hour';
  else if (minutesDifference <= 40320) window = 'hour';
  else window = 'hour';

  if (minutesDifference <= 60) {
    return {
      formatType: dateFormatTypes.TIMEONLY,
      tickInterval: 0,
      windowType: window,
    };
  } else if (minutesDifference <= 180) {
    return {
      formatType: dateFormatTypes.TIMEONLY,
      tickInterval: 0,
      windowType: window,
    };
  } else if (minutesDifference <= 360) {
    return {
      formatType: dateFormatTypes.TIMEONLY,
      tickInterval: 3,
      windowType: window,
    };
  } else if (minutesDifference <= 1440) {
    return {
      formatType: dateFormatTypes.TIMEONLY,
      tickInterval: 5,
      windowType: window,
    };
  } else if (minutesDifference <= 4320) {
    return {
      formatType: dateFormatTypes.TRUNCATEDNOSECONDS,
      tickInterval: 23,
      windowType: window,
    };
  } else if (minutesDifference <= 10080) {
    return {
      formatType: dateFormatTypes.DAY,
      tickInterval: 24,
      windowType: window,
    };
  } else if (minutesDifference <= 20160) {
    return {
      formatType: dateFormatTypes.DAY,
      tickInterval: 24,
      windowType: window,
    };
  } else if (minutesDifference <= 30240) {
    return {
      formatType: dateFormatTypes.DAY,
      tickInterval: 23,
      windowType: window,
    };
  } else if (minutesDifference <= 43800) {
    return {
      formatType: dateFormatTypes.DAY,
      tickInterval: 2,
      windowType: window,
    };
  } else {
    return {
      formatType: dateFormatTypes.DAY,
      tickInterval: 256,
      windowType: window,
    };
  }
};
