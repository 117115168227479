import { Stack } from '@mui/system';
import { TextFieldElement } from 'react-hook-form-mui';
import PhormContainer from '../../../forms/PhormContainer';

import * as yup from 'yup';

import useSeeds from '../../../data/hooks/collections/useSeeds';
import useControlSend from '../../../data/hooks/useControlSend';
import { mqttDefinitionSeederSet, SeederDeviceState } from '@phyllome/common';
import SubmitControl from '../../../forms/SubmitControl';
import PaperSection from '../../../components/puregui/PaperSection/PaperSection';
import PhormSelect from '../../../components/Form/Components/PhormSelect';
import { PhormDropDownData } from '../../../components/Form/types';
import { FromFirebase } from '../../../types/types';
import formCreateDefaultValues from '../../../helpers/formCreateDefaultValues';
import useActivityLog from '../../../data/hooks/useActivityLog';

const FormSeederSet = ({
  latest,
}: {
  latest: FromFirebase<SeederDeviceState>;
}) => {
  const deviceId = latest.deviceId;
  const controlLifecycle = useControlSend(mqttDefinitionSeederSet, deviceId);
  const activityLog = useActivityLog(`device/seeder/${deviceId}/notes`);

  const seeds = useSeeds({ validatedOnly: true });
  const seedVarietyData: PhormDropDownData[] = [
    ...seeds.map((seed) => {
      return {
        label: `${seed.name}/${seed.variety}`,
        value: seed.id || '',
      };
    }),
  ];

  const defaultValues = formCreateDefaultValues(mqttDefinitionSeederSet, {
    seedId: latest?.seedId,
    quantity: latest?.quantity || 0,
    trays: latest.trays || 0,
    batch: String(latest.batch) || '0',
  });

  return (
    <PaperSection
      heading="Start new seeder session"
      mb={2}
      subheading={mqttDefinitionSeederSet.topic}
    >
      <PhormContainer
        defaultValues={defaultValues}
        onSubmit={(data) => {
          controlLifecycle.run({
            payload: {
              seedId: data.seedId,
              batch: data.batch,
              quantity: data.quantity,
              trays: data.trays,
            },
            topicParams: {
              deviceId,
            },
          });

          activityLog.add(
            `Set seeder to sow ${data.quantity}x ${getSeedNameById(seeds, data.seedId)} seeds, ${data.trays} trays, batch: ${data.batch}`,
            'OPERATION',
          );
        }}
        onError={(error) => console.log(error)}
        schema={yup.object({
          seedId: yup.string().required(),
          batch: yup
            .string()
            .required()
            .test(
              'len',
              'Must be less than 30 characters',
              (val) => val.length < 30,
            ),
          quantity: yup.number().min(0).required(),
          trays: yup.number().min(0).required(),
        })}
      >
        <Stack spacing={2}>
          <p>
            Start a new seeder session. The seed variety will be recorded
            against each tray RFID. The seeder will beep when the target tray
            count has been reached
          </p>
          <PhormSelect
            name="seedId"
            label="Seed Variety"
            options={seedVarietyData}
          />
          <TextFieldElement label="Bach Code" name="batch" required />
          <TextFieldElement label="Seed density" name="quantity" required />
          <TextFieldElement label="Target tray count" name="trays" required />
          <SubmitControl controlHook={controlLifecycle}>
            Send to Device
          </SubmitControl>
        </Stack>
      </PhormContainer>
    </PaperSection>
  );
};

export default FormSeederSet;

const getSeedNameById = (
  seeds: ReturnType<typeof useSeeds>,
  seedId: string,
): string | undefined => {
  const seed = seeds.find((seed) => seed.id === seedId);

  return seed ? seed.name : undefined;
};
