import { FC } from 'react';
import { Card, Box, Tooltip, Typography } from '@mui/material';
import { colors } from '@phyllome/common';
import { ArrowLeft } from '@mui/icons-material';
import { ArrowRight } from '@mui/icons-material';

type ITankLevelProps = {
  level: number;
  label?: string;
  color?: string;
  upperLimit?: number;
  lowerLimit?: number;
  size?: 'small' | 'medium' | 'large';
};

const TankLevel: FC<ITankLevelProps> = ({
  level,
  label,
  color = colors.river,
  upperLimit = 100,
  lowerLimit = 0,
  size = 'medium',
}) => {
  const constrainedLevel = level < 0 ? 0 : level > 100 ? 100 : level;
  const inverseLevel = 100 - constrainedLevel;
  const width = size === 'small' ? 40 : size === 'medium' ? 80 : 120;

  const levelColor =
    level > upperLimit
      ? colors.earth1
      : level <= lowerLimit
        ? colors.sunlight
        : color;

  return (
    <Box textAlign="center">
      <Box mb={1}>
        <Typography variant="body1" color="textSecondary">
          {label || ' '}
        </Typography>
      </Box>
      <Box>
        <Box
          position="relative"
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: width,
          }}
        >
          <div
            style={{
              textAlign: 'right',
              position: 'absolute',
              right: width + 10,
              top: `calc(${inverseLevel}% - 10px)`,
              fontSize: 'small',
              color: colors.ocean,
            }}
          >
            {level.toFixed(0)}%
          </div>
          <ArrowRight
            style={{
              position: 'absolute',
              left: -18,
              top: `calc(${inverseLevel}% - 12px)`,
              color: colors.ocean,
            }}
          />
          {lowerLimit > 0 && (
            <Tooltip
              title={`Lower limit: ${lowerLimit}%`}
              arrow
              placement="right"
            >
              <ArrowLeft
                style={{
                  position: 'absolute',
                  left: width - 5,
                  color: colors.earth3,
                  top: `calc(${100 - lowerLimit}% - 10px)`,
                }}
              />
            </Tooltip>
          )}
          {upperLimit < 100 && (
            <Tooltip
              title={`Upper limit: ${upperLimit}%`}
              arrow
              placement="right"
            >
              <ArrowLeft
                style={{
                  position: 'absolute',
                  left: width - 5,
                  color: colors.earth3,
                  top: `calc(${100 - upperLimit}% - 10px)`,
                }}
              />
            </Tooltip>
          )}
          <Card
            style={{
              height: '80px',
              width: width,
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                height: constrainedLevel + '%',
                width: '100%',
                backgroundColor: levelColor,
              }}
            ></div>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default TankLevel;
