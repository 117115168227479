import { CameraDeviceState } from '@phyllome/common';

import { Button } from '@mui/material';
import { FromFirebase } from '../../types/types';
import LabelValue from '../../components/LabelValue/LabelValue';
import PaperSection from '../../components/puregui/PaperSection/PaperSection';
import { formatSeconds } from '../../helpers/formatSeconds';
import useNavSide from '../../data/hooks/useNavSide';

const ConfigCamera = ({
  latest,
}: {
  latest: FromFirebase<CameraDeviceState>;
}) => {
  const navSide = useNavSide();

  return (
    <>
      <PaperSection heading="Camera Device Configuration" mb={2}>
        <LabelValue label="Device ID">{latest.deviceId}</LabelValue>
        <LabelValue label="Linked Traybot">
          {latest.traybot}
          <Button
            variant="text"
            size="small"
            onClick={() => {
              navSide(`traybot/${latest.traybot}`);
            }}
          >
            open
          </Button>
        </LabelValue>
        <LabelValue label="Network IP">
          {latest.network_ip} ({latest.network_rssi}dB)
        </LabelValue>
        <LabelValue label="Uptime">{formatSeconds(latest.uptime)}</LabelValue>
      </PaperSection>
    </>
  );
};

export default ConfigCamera;
