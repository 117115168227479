import { useState, useEffect, useCallback } from 'react';
import { doc, onSnapshot, getFirestore, deleteDoc, setDoc } from 'firebase/firestore';

interface UseFirestoreFavoriteProps {
  brokerId: string;
  deviceId: string;
  deviceType: string;
  field: string;
  uid: string;
}

interface FavoriteData {
  name: string;
  type: string;
  brokerId: string;
  location: string;
  deviceId: string;
  deviceType: string;
  subType: string;
  field: string;
  uid: string;
  order: number;
}

const useUserFavourite = ({
  brokerId,
  deviceId,
  deviceType,
  field,
  uid,
}: UseFirestoreFavoriteProps) => {
  const [data, setData] = useState<FavoriteData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const firestore = getFirestore();

  // Memoize the document reference creation
  const getDocRef = useCallback(() => {
    const id = `${brokerId}.${deviceType}.${deviceId}.${field}.${uid}`;

    return doc(firestore, 'customConsole', id);
  }, [brokerId, deviceType, deviceId, field, uid, firestore]);

  // Delete function
  const deleteFavorite = useCallback(async () => {
    try {
      const docRef = getDocRef();

      await deleteDoc(docRef);
      return true;
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to delete favorite'));
      return false;
    }
  }, [getDocRef]);

  // Optional: Add function if you need it
  const addFavorite = useCallback(async (favoriteData: Omit<FavoriteData, 'uid'>) => {
    try {
      const docRef = getDocRef();

      await setDoc(docRef, {
        ...favoriteData,
        uid,
      });
      return true;
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to add favorite'));
      return false;
    }
  }, [getDocRef, uid]);

  useEffect(() => {
    const docRef = getDocRef();

    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        setData(snapshot.data() as FavoriteData | null);
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [getDocRef]);
  return {
    data,
    loading,
    error,
    deleteFavorite,
    addFavorite, // Optional: include if you need it
    docId: getDocRef().id, // Expose the document ID if needed
  };
};

export default useUserFavourite;