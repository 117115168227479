import { useRef } from 'react';
import { Stack } from '@mui/system';
import PhormContainer from '../../forms/PhormContainer';
import useActivityLog from '../../data/hooks/useActivityLog';
import * as yup from 'yup';
import PhormSubmit from '../Form/PhormSubmit/PhormSubmit';
import PhormTextField from '../Form/Components/PhormTextField';
export const ActivityLogForm = ({
  activityLog,
}: {
  activityLog: ReturnType<typeof useActivityLog>;
}) => {
  const formRef = useRef<any>();

  return (
    <PhormContainer
      ref={formRef}
      defaultValues={{
        description: '',
      }}
      onSubmit={({ description }) => {
        activityLog.add(description);
        if (formRef.current) {
          formRef.current.reset();
        }
      }}
      onError={(error) => console.log(error)}
      schema={yup.object({
        description: yup.string().required(),
      })}
    >
      <Stack spacing={2}>
        <PhormTextField
          name="description"
          label="Note"
          placeholder="Add an Entry"
          multiline={true}
        />
        <PhormSubmit>Add To Log</PhormSubmit>
      </Stack>
    </PhormContainer>
  );
};
export default ActivityLogForm;
