import { WbSunnyTwoTone } from '@mui/icons-material';
import { Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { FC, useContext, useState, useEffect } from 'react';
import ContainerPrimary from '../../../../components/containers/ContainerPrimary/ContainerPrimary';
import FarmContext from '../../../../components/FarmProvider/FarmContext';
import useSeasons from '../../../../data/hooks/collections/useSeasons';
import useAction from '../../../../data/hooks/useAction';
import dateToString from '../../../../helpers/dateToString';

enum SPCurrentState {
  UNPROCESSED = 'UNPROCESSED',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
}

interface SPStateEntry {
  state: SPCurrentState;
  returnPayload: null | { count: number, needsUpdating: number };
}

type SPState = Record<string, SPStateEntry>;

const Processing: FC = () => {
  const { brokerId } = useContext(FarmContext);
  const [spState, setSpState] = useState<SPState>({});

  const processSeasonAction = useAction('seasonProcess');
  const incompleteSeasons = useSeasons({
    brokerId,
    sort: 'asc',
    isComplete: false,
  });

  useEffect(() => {
    if (Object.keys(spState).length > 0) return;
    const completionMap = incompleteSeasons.length > 0
      ? Object.fromEntries(
        incompleteSeasons.map(season => [
          season.id,
          {
            state: SPCurrentState.UNPROCESSED,
            returnPayload: null,
          },
        ]),
      )
      : {};

    setSpState(completionMap);
  }, [incompleteSeasons, spState]);

  const processAllSeasons = async () => {
    const seasonsToProcess = incompleteSeasons.filter(season =>
      spState[season.id]?.state !== SPCurrentState.PROCESSING,
    );

    for (const seasonToProcess of seasonsToProcess) {
      console.log('Processing season:', seasonToProcess.id);

      // Set to processing state
      setSpState(prevState => ({
        ...prevState,
        [seasonToProcess.id]: {
          state: SPCurrentState.PROCESSING,
          returnPayload: null,
        },
      }));

      try {
        const result = await processSeasonAction.run({
          seasonId: seasonToProcess.id,
        });

        // Wait for state update to complete using a promise
        await new Promise<void>(resolve => {
          setSpState(prevState => {
            const newState = {
              ...prevState,
              [seasonToProcess.id]: {
                state: SPCurrentState.COMPLETE,
                returnPayload: result.msg.devices,
              },
            };

            resolve();
            return newState;
          });
        });

        // Optional delay between processing each season
        await new Promise(resolve => setTimeout(resolve, 1000));

      } catch (error) {
        setSpState(prevState => ({
          ...prevState,
          [seasonToProcess.id]: {
            state: SPCurrentState.UNPROCESSED,
            returnPayload: null,
          },
        }));
        console.error('Failed to process season:', error);
        // Optional: break the loop on error
        // break;
      }
    }
  };

  return (
    <ContainerPrimary title="Season Processing" icon={<WbSunnyTwoTone />}>
      <Button variant="contained" onClick={() => processAllSeasons()}>
        Process All Seasons
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Trays</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Devices</TableCell>
            <TableCell>Req. Change</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incompleteSeasons.map((season) => (
            <TableRow key={season.id}>
              <TableCell>{dateToString(season?.traySeedEarliest?.toDate())}</TableCell>
              <TableCell>{season.name}</TableCell>
              <TableCell>{season.trayNumberActual}</TableCell>
              <TableCell>
                {spState[season.id]?.state === SPCurrentState.PROCESSING &&
                  <span>...</span>
                }
                {spState[season.id]?.state === SPCurrentState.COMPLETE &&
                  <span>Done</span>
                }
              </TableCell>
              <TableCell>
                {spState[season.id]?.returnPayload?.count ?? ''}
              </TableCell>
              <TableCell>
                {spState[season.id]?.returnPayload?.needsUpdating ?? ''}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ContainerPrimary>
  );
};

export default Processing;