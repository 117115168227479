import RackBox from '../../../../components/factoryui/components/RackBox';
import type { NDSDeviceFromAction } from '../../../../types/types';

import getUIInfoBasedOnAge from '../helpers/getUIInfoBasedOnAge';

type OverlayTraySimpleProps = {
  brokerId: string;
  column: number;
  data: NDSDeviceFromAction[];
  shelves: number;
};

const OverlaySpinklerSimple = ({ shelves, data }: OverlayTraySimpleProps) => {
  const now = new Date();

  return (
    <>
      {data.map((row) => {
        const { timestamp, location_column, location_row, location_shelf } =
          row.properties;
        const { color } = getUIInfoBasedOnAge(now, new Date(timestamp));

        return (
          <RackBox
            key={row.deviceId}
            zIndex={10}
            row={location_row}
            shelf={location_shelf}
            shelves={shelves}
            style={{
              textAlign: 'center',
              backgroundColor: color,
              color: 'white',
            }}
          >
            {location_column}/{location_shelf}/{location_row}
          </RackBox>
        );
      })}
    </>
  );
};

export default OverlaySpinklerSimple;
