import { DatabaseSeason } from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';
import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ConsumablesTable, {
  ConsumableDataType,
} from '../../../../components/ConsumablesTable/ConsumablesTable';
import co2LitresToKilos from '../../../../helpers/co2LitresToKilos';
import ConsumableDefaultCosts from '../../../../components/ConsumablesTable/ConsumableDefaultCosts';
import useSeed from '../../../../data/hooks/collections/useSeed';

const SideBarSeasonConsumables = ({
  seasonData,
}: {
  seasonData?: FromFirebase<DatabaseSeason>;
}) => {
  const seedData = useSeed(seasonData?.seedId);
  const seedCost =
    (seedData?.pricePerMillion || ConsumableDefaultCosts.seeds) / 1000000;

  const ConsumableData: ConsumableDataType = {
    energy: Number(seasonData?.totalConsumedEnergy) / 1000, // conver to kW
    water: Number(seasonData?.totalConsumedWater),
    nutrientA: Number(seasonData?.totalConsumedNutrientA),
    nutrientB: Number(seasonData?.totalConsumedNutrientB),
    co2: co2LitresToKilos(Number(seasonData?.totalConsumedCO2) / 1000),
    seeds: Number(seasonData?.totalConsumedSeedQuantity),
    nutrientPeroxide: Number(seasonData?.totalConsumedPeroxide),
    nutrientAcid: Number(seasonData?.totalConsumedAcid),
    nutrientBase: Number(seasonData?.totalConsumedBase),
  };

  const ConsumableCosts: ConsumableDataType = {
    nutrientA: ConsumableDefaultCosts.nutrientA,
    nutrientB: ConsumableDefaultCosts.nutrientB,
    nutrientPeroxide: ConsumableDefaultCosts.nutrientPeroxide,
    nutrientAcid: ConsumableDefaultCosts.nutrientAcid,
    nutrientBase: ConsumableDefaultCosts.nutrientBase,
    energy: ConsumableDefaultCosts.energy,
    water: ConsumableDefaultCosts.water,
    co2: ConsumableDefaultCosts.co2,
    seeds: seedCost,
  };

  const productiveTrays =
    (seasonData?.trayNumberActual || 0) -
    (seasonData?.trayNumberRejected || 0) -
    (seasonData?.trayNumberFlagged || 0);

  return (
    <PaperSection heading="Season Consumables" mb={2}>
      <ConsumablesTable
        consumableData={ConsumableData}
        totalTrays={productiveTrays}
        consumableCosts={ConsumableCosts}
      />
    </PaperSection>
  );
};

export default SideBarSeasonConsumables;
