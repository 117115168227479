import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import { collection, limit, query } from 'firebase/firestore';

import type { Farm } from '@phyllome/common';
import useIsDeveloper from '../../../components/IsDeveloper/useIsDeveloper';

const useFarms = (): Farm[] => {

  const isDeveloper = useIsDeveloper();

  const documentReference = query(
    collection(useFirestore(), 'farms'),
    limit(1000),
  );

  const data = useFirestoreCollectionData(documentReference, {
    idField: 'id',
    initialData: [],
  });

  if (data?.error) {
    console.error(data?.error?.toString());
  }

  const ret = data.data as Farm[];

  if (!isDeveloper) {
    return ret.filter((farm) => {
      return farm.brokerId !== 'mcp-mick' && farm.brokerId !== 'mcp-test';
    });
  } else {
    return ret.filter((farm) => {
      return farm.brokerId !== 'mcp-mick' && farm.brokerId !== 'mcp-test';
    });

  }
};

export default useFarms;
