import { Tabs, Tab, Box, Alert } from '@mui/material';
import { colors } from '@phyllome/common';
import React, { useContext } from 'react';
import { FC } from 'react';
import AppDrawerTitle from '../../sidebar/AppDrawer/AppDrawerTitle/AppDrawerTitle';

import TabPanel from '../../components/TabPanel/TabPanel';

import FarmContext from '../../components/FarmProvider/FarmContext';
import ControlSentry from './ControlSentry';
import GraphSentry from './GraphSentry';
import LatestSentry from './LatestSentry';
import ConfigSentry from './ConfigSentry';
import useLatestSentry from './hooks/useLatestSentry';
import LatestSentryInfo from './LatestSentryInfo';
import AlertsDevice from '../../components/AlertsDevice/AlertsDevice';
import ActivityLog from '../../components/ActivityLog/ActivityLog';

type IProps = {
  deviceId: string;
};

const SideBarEntrySentry: FC<IProps> = ({ deviceId }) => {
  const { brokerId } = useContext(FarmContext);

  const [value, setValue] = React.useState<any>(0);
  const latestDeviceInfo = useLatestSentry(brokerId, deviceId);
  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  if (!latestDeviceInfo) return null;

  const title = latestDeviceInfo.label
    ? `sentry/${latestDeviceInfo.deviceId} (${latestDeviceInfo.label})`
    : `sentry/${latestDeviceInfo.deviceId}`;

  if (!latestDeviceInfo?.timestamp)
    return (
      <Box p={2}>
        <Alert severity="info">No Latest Data for sentry/{deviceId}!</Alert>
      </Box>
    );
  return (
    <>
      <AppDrawerTitle title={title} />
      <Tabs
        sx={{
          background: colors.ripening,
          color: colors.fog,
        }}
        textColor="inherit"
        variant="scrollable"
        value={value || 0}
        onChange={handleChange}
      >
        <Tab label="Latest" value={0} />
        <Tab label="Graphs" value={1} />
        <Tab label="Control" value={2} />
        <Tab label="Config" value={3} />
        <Tab label="Activity" value={4} />
        <Tab label="Alerts" value={4} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <LatestSentryInfo latest={latestDeviceInfo} />
        <LatestSentry latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GraphSentry latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ControlSentry latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ConfigSentry latest={latestDeviceInfo} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ActivityLog
          identifier={`device/sentry/${deviceId}/notes`}
          heading="Activity Log"
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <AlertsDevice
          brokerId={brokerId}
          deviceId={deviceId}
          deviceType="sentry"
        />
      </TabPanel>
    </>
  );
};

export default SideBarEntrySentry;
