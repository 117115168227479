import {
  DatabaseSeason,
  DatabaseSeed,
  ZoneDeviceState,
} from '@phyllome/common';
import { FromFirebase } from '../../../../types/types';

import PaperSection from '../../../../components/puregui/PaperSection/PaperSection';
import ScheduleViewLighting from '../../../../components/ScheduleView/ScheduleViewLighting';
import { DeviceStateFirestore } from '../../../../devicespecific/deviceTypes';
import ScheduleTableLighting from '../../../../components/ScheduleTable/ScheduleTableLighting';

const SideBarTraySeasonLightingSchedule = ({
  seasonData,
  seedData,
  zoneData,
}: {
  seasonData: FromFirebase<DatabaseSeason>;
  seedData: FromFirebase<DatabaseSeed>;
  zoneData: DeviceStateFirestore<ZoneDeviceState>;
}) => {
  return (
    <PaperSection
      heading="Lighting Schedule"
      mb={2}
      hidden={seasonData?.state === 'completed'}
    >
      <ScheduleViewLighting
        seedSchedule={seedData?.defaultLightingSchedule}
        seasonSchedule={seasonData?.defaultLightingSchedule}
        zoneSchedule={zoneData?.schedule}
        context="zone"
      />
      <ScheduleTableLighting scheduleDataDB={zoneData?.schedule || []} />
    </PaperSection>
  );
};

export default SideBarTraySeasonLightingSchedule;
