import { DeviceType } from '@phyllome/common';
import { FC } from 'react';
import PaperSection from '../puregui/PaperSection/PaperSection';

import ReChartVictoryLine from './RechartVictory';
import FavGraph, { FavGraphWrap } from '../FavGraph/FavGraph';
import { FavouriteName } from '../Favourites/collectionFavourites';
import { Alert, AlertTitle, Box, Skeleton } from '@mui/material';
import useTimeSeriesPoller from './useTimeSeriesPoller';
export type ReChartVictoryCumulativeProps = {
  brokerId: string;
  deviceId: string;
  deviceType: DeviceType;
  field: string;
  color: string;
  heading: string;
  name?: FavouriteName;
  height?: number;
};

const ReChartVictoryCumulative: FC<ReChartVictoryCumulativeProps> = ({
  brokerId,
  deviceId,
  deviceType,
  field,
  color,
  heading,
  name,
  height,
}) => {
  const timeSeries = useTimeSeriesPoller({
    brokerId,
    deviceId,
    deviceType,
    origin: field,
    type: 'cumulative',
  });

  if (timeSeries.isInitiating) {
    return (
      <PaperSection heading={heading}>
        <Skeleton
          variant="rectangular"
          height={height || 300}
          animation="wave"
        />
      </PaperSection>
    );
  }

  if (timeSeries.isUnsufficientData) {
    <PaperSection heading={heading}>
      <Box sx={{ height: height || 300 }}>
        <Alert severity="error">
          <AlertTitle>Unable to load chart</AlertTitle>Insufficient data
          available in timeSeries.
        </Alert>
      </Box>
    </PaperSection>;
  }
  return (
    <PaperSection heading={heading}>
      {name && (
        <FavGraphWrap isUpdating={timeSeries.isUpdating}>
          <FavGraph deviceId={deviceId} brokerId={brokerId} name={name} />
        </FavGraphWrap>
      )}
      <ReChartVictoryLine
        color={color}
        timeSeries={timeSeries}
        height={height}
      />
    </PaperSection>
  );
};

export default ReChartVictoryCumulative;
