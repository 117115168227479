import {
  ObservableStatus,
  useFirestore,
  useFirestoreDocDataOnce,
} from 'reactfire';

import { collection, doc } from 'firebase/firestore';
import { ICollectionBroker } from '../../types/types';

const useCurrentBrokerConfig = (brokerId: string): ICollectionBroker => {
  const documentReference = doc(
    collection(useFirestore(), 'brokers'),
    brokerId,
  );

  const data = useFirestoreDocDataOnce(
    documentReference,
  ) as ObservableStatus<ICollectionBroker>;

  if (data?.error) {
    console.error(data?.error?.toString());
  }
  return data.data;
};

export default useCurrentBrokerConfig;
