import { colors } from '@phyllome/common';
import { FavouriteDefinition } from './types';

const traybotGraph: FavouriteDefinition = {
  traybotBattery: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'traybot',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[TRAYBOT] ${deviceId}/battery`,
    color: colors.earth1,
    field: 'battery',
    brokerId,
    deviceId,
    constraints: {
      min: 10,
      max: 30,
    },
  }),
  traybotNetwork: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'traybot',
    subType: 'linear',
    showHeading: ({ deviceId }) => `[TRAYBOT] ${deviceId}/network_rssi`,
    color: colors.earth1,
    field: 'network_rssi',
    brokerId,
    deviceId,
    constraints: {
      min: -90,
      max: -30,
    },
  }),
  traybotLiftRaised: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'traybot',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[TRAYBOT] ${deviceId}/lift_raised`,
    color: colors.earth1,
    field: 'lift_raised',
    brokerId,
    deviceId,
  }),
  traybotBlower: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'traybot',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[TRAYBOT] ${deviceId}/blower`,
    color: colors.earth1,
    field: 'blower',
    brokerId,
    deviceId,
  }),
  traybotCharger: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'traybot',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[TRAYBOT] ${deviceId}/charger`,
    color: colors.earth1,
    field: 'charger',
    brokerId,
    deviceId,
  }),
  traybotEdge: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'traybot',
    subType: 'bool',
    showHeading: ({ deviceId }) => `[TRAYBOT] ${deviceId}/edge`,
    color: colors.earth1,
    field: 'edge',
    brokerId,
    deviceId,
  }),
  traybotState: ({ brokerId, deviceId }) => ({
    type: 'graph',
    deviceType: 'traybot',
    subType: 'enum',
    showHeading: ({ deviceId }) => `[TRAYBOT] ${deviceId}/state`,
    color: colors.earth1,
    field: 'state',
    brokerId,
    deviceId,
    mapping: {
      '0': 'Idle',
      '1': 'Moving',
      '2': 'Raising',
      '3': 'Lowering',
      '4': 'Photo',
      '5': 'Charging',
      '6': 'Homing',
      '7': 'Error',
    },
  }),
};

export default traybotGraph;
